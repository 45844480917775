import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ExcelService } from 'src/app/base/services/excel/excel.service';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { LaboratoriosService } from '../../services/laboratorios/laboratorios.service';

@Component({
  selector: 'app-resultado-laboratorio',
  templateUrl: './resultado-laboratorio.component.html',
  styleUrls: ['./resultado-laboratorio.component.css']
})
export class ResultadoLaboratorioComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  resultado_prueba:any={};
  resultados_pruebas:any[]=[];
  clasificaciones:any[]=[];
  producto:any;

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public excelService:ExcelService,
    public modalService: NgbModal,
    private toastr: ToastrService,
    private laboratoriosService:LaboratoriosService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.obtenerClasificacionesPruebas();
    this.obtenerPruebaResultadoProducto();
  }

  obtenerPruebaResultadoProducto(){
    this.blockUI.start();
    this.laboratoriosService.obtenerPruebasResultadoProducto(this.producto.id).subscribe((res:any) => {
      this.resultados_pruebas=res;
      this.blockUI.stop();
    });
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  async obtenerClasificacionesPruebas(){
		this.clasificaciones=[];
		let datos:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_PRUEBA_CLASIFICACIONES).toPromise();
		this.clasificaciones=this.clasificaciones.concat(datos.clases);
	}

  agregarResultadoPrueba(){
    if(this.resultado_prueba.resultado_etiqueta){
      this.resultados_pruebas.push(this.resultado_prueba);
      this.iniciarAdhseionResultado();
    }
  }

  iniciarAdhseionResultado(){
    this.resultado_prueba={};
  }

  eliminarResultadoPrueba(resultado_prueba){
    resultado_prueba.eliminado=true;
  }

  guardarResultadosPrueba(){
    if(this.resultados_pruebas.length>0){
      this.blockUI.start();
        this.laboratoriosService.guardarResultadoLaboratorio({id_producto:this.producto.id,resultados:this.resultados_pruebas}).subscribe((res:any) => {
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
    }else{
      this.toastr.error("Debe agregar al menos un resultado de prueba!");
    }
  }

}
