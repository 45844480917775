import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { ClientesService } from 'src/app/snapquick/services/clientes/clientes.service';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import { Util } from 'src/app/utils/utils';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

declare const PDFDocument:any;
declare const blobStream:any;
declare const qr:any
declare const $:any;

@Component({
  selector: 'app-tarjetas-jugador',
  templateUrl: './tarjetas-jugador.component.html',
  styleUrls: ['./tarjetas-jugador.component.css']
})
export class TarjetasJugadorComponent extends BaseComponent implements OnInit {

  equipo:any;
  impresion_tarjetas_jugador:any={lista_jugadores:[],jugador:{}};
  searching = false;
  searchFailed = false;
  es_desde_notificacion:boolean=false;
  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  tabla_equipo_general:any={con_carnet:false};

  constructor(
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public campeonatosService:CampeonatosService,
    public clientesService:ClientesService,
    public rolesPartidosService:RolesPartidosService,
    private toastr: ToastrService) { 
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    if(this.es_desde_notificacion){
      this.blockUI.start();
      this.campeonatosService.obtenerListaJugadoresSinCarnet(this.usuario.id_empresa).subscribe((res)=>{
        this.impresion_tarjetas_jugador.lista_jugadores=res;
        this.blockUI.stop();
      });
    }
  }

  async generarTarjetaIdentificacion(jugadores_seleccionados){
    var doc = new PDFDocument({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
    var stream = doc.pipe(blobStream());
    let canvas:any=document.getElementById('qr-code');
    // draw some text
    doc.fontSize(8);
    var yCard=20,items=0,page=1,carnets_por_hoja=8,x_c=45;
    let fecha_inicio=new Date();
    let fecha_fin=new Date();
    fecha_fin.setDate(fecha_inicio.getDate()+365-1);
    for(var i=0;i<jugadores_seleccionados.length;i++){
      doc.switchToPage(page-1);
      items++;
      let campeonato=jugadores_seleccionados[i].equipo.campeonato;
      //Carnet Cara Principal
      doc.rect(x_c, yCard, 227, 142).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      let logo=await Util.convertirUrlToBase64Image(this.rest_server+campeonato.logo);
      doc.image(logo,x_c+170,yCard,{width: 50});
      doc.fillColor('white');
      doc.font('Helvetica-Bold',11);
      if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
        doc.text(this.usuario.empresa.nombre,x_c+10,yCard+10,{width:150});
      }else{
        doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
        doc.text(campeonato.nombre.toUpperCase(),x_c+10,yCard+10,{width:150});
      }
      doc.font('Helvetica-Bold',10);

      doc.fillColor(campeonato.configuracion_tarjeta_jugador.titulo_color_jugador);
      doc.text("TARJETA DE JUGADOR",x_c+30,yCard+42);
      doc.fontSize(8);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text("NOMBRE:",x_c+80,yCard+55);
      doc.text("EQUIPO:",x_c+80,yCard+75);
      doc.text("CATEGORIA:",x_c+80,yCard+115);
      doc.text("DISCIPLINA:",x_c+80,yCard+125);
      doc.text("TORNEO:",x_c+80,yCard+95);
      doc.fontSize(8);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.datos_color);
      let imagen_persona=await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+jugadores_seleccionados[i].persona.imagen);
      doc.image(imagen_persona,x_c+9,(yCard+60),{width: 70,height:70});
      doc.text(jugadores_seleccionados[i].persona.nombre_completo.toUpperCase(),x_c+118,yCard+55,{width:100});
      doc.text((this.equipo?this.equipo.nombre:jugadores_seleccionados[i].equipo.nombre),x_c+118,yCard+75,{width:180});
      doc.text((this.equipo?this.equipo.categoria.nombre:jugadores_seleccionados[i].equipo.categoria.nombre),x_c+130,yCard+115,{width:100});
      doc.text((this.equipo?this.equipo.disciplina.nombre:jugadores_seleccionados[i].equipo.disciplina.nombre),x_c+130,yCard+125,{width:100});
      doc.text(campeonato.nombre.toUpperCase(),x_c+118,yCard+95,{width:100});
      doc.rect(x_c+9, yCard+125, 70,5).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.fontSize(7);
      let texto_carnet=jugadores_seleccionados[i].persona.ci?jugadores_seleccionados[i].persona.ci:"";
      let fecha_nacimiento:any=jugadores_seleccionados[i].persona.fecha_nacimiento?new Date(jugadores_seleccionados[i].persona.fecha_nacimiento):null;
      texto_carnet=texto_carnet+(fecha_nacimiento?" "+(fecha_nacimiento.getDate()+"/"+(fecha_nacimiento.getMonth()+1)+"/"+fecha_nacimiento.getFullYear()):"");
      doc.text(texto_carnet,x_c+9,yCard+126,{width:70,align:'center'});
      
      //Carnet Cara trasera
      doc.rect(x_c+228, yCard, 227, 142).fillAndStroke("#ffffff","#000000");
      qr.canvas({
        canvas: canvas,
        value: jugadores_seleccionados[i].codigo
      },function(){console.log("created");});
      var qrImage=canvas.toDataURL('image/png');
      doc.image(qrImage,x_c+360,(yCard+5),{width: 70});	
      //doc.rect(295, yCard+16, 155,85).fillAndStroke("yellow", "#900");
      doc.fillColor('black');
      doc.fontSize(9);
      doc.text("¡Cuanto más dificil es la victoria, mayor es la felicidad de ganar!",x_c+240,yCard+10,{width:125});
      doc.fontSize(6);
      doc.text("-------------------------------------------",x_c+240,yCard+110);
      doc.text("JUGADOR",x_c+240,yCard+120,{width:80,align:"center"});
      doc.text("-------------------------------------------",x_c+340,yCard+110);
      if(campeonato.configuracion_tarjeta_jugador.campeonato_propio){
        doc.text("L.D.C.M. ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
      }else{
        doc.text("ORGANIZACIÓN",x_c+340,yCard+120,{width:80,align:"center"});
      }

      if(items%2==0){
        yCard=yCard+143;
        x_c=45;
      }else{
        yCard=yCard;
        x_c=x_c+228*2;
      }
      
      if(items==carnets_por_hoja){
        items=0;
        yCard=20;
        doc.addPage({layout:'landscape',margin:0,size:GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA});
        page++;
      }
    }
    
    doc.end();

    stream.on('finish', function () {
      var fileURL = stream.toBlobURL('application/pdf');
      var w = window.open(fileURL, '_blank', 'location=no');
      setTimeout(function () {
        w.print();
      }, 500);
    });
  }

  establecerJugador(event){
    let jugador=(event.id)?event:event.item;
    this.impresion_tarjetas_jugador.jugador_texto=jugador.persona.nombre_completo;
    this.impresion_tarjetas_jugador.jugador=jugador;
  }

  reiniciarJugador(){
    this.impresion_tarjetas_jugador.jugador={};
    this.impresion_tarjetas_jugador.jugador_texto=null;
    $("#jugador_texto").val("");
  }

  agregarJugadorImpresion(){
    this.impresion_tarjetas_jugador.lista_jugadores.push(this.impresion_tarjetas_jugador.jugador);
    this.reiniciarJugador();
  }

  eliminarJugadorImpresion(jugador){
    this.impresion_tarjetas_jugador.lista_jugadores.splice(this.impresion_tarjetas_jugador.lista_jugadores.indexOf(jugador),1);
  }

  buscarJugadorEmpresa = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (term) =>  this.campeonatosService.buscarJugadorEmpresa(this.usuario.id_empresa,term)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)

  formatterJugador = (x: any) => (x.id?(x.persona.nombre_completo?x.persona.nombre_completo:""):"");

  cerrarComponente(){
    this.alTerminar.emit();
  }

  actualizarAtributoJugador(jugador,atributo,valor){
		this.blockUI.start();
		let datos:any={
			atributo:atributo,
			valor:valor
		};
		if(jugador){
			datos.jugador=jugador;
		}else{
			datos.id_empresa=this.usuario.id_empresa;
		}
		this.campeonatosService.actualizarAtributoJugador(datos).subscribe((res:any) => {
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}

}
