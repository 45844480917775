<div class="navbar-inner">
  <div class="navbar-intro justify-content-xl-between">

    <button type="button" class="btn burger-arrowed static collapsed ml-2 d-flex d-xl-none btn-h-lighter-purple btn-burger" data-toggle-mobile="sidebar" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
      <span class="bars bgc-dark-tp3"></span>
    </button><!-- mobile sidebar toggler button -->

    <a class="navbar-brand text-white" routerLink="/inicio">
      <img class="pull-left" src="{{rest_server+'img/'+datos_app.logo}}" width="30" height="30" />
      <span> {{datos_app.nombre}}</span>
    </a><!-- /.navbar-brand -->

    <button type="button" class="btn burger-dots static collapsed ml-2 d-flex d-xl-none btn-burger" data-toggle-mobile="sidebar" data-target="#sidebar2" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
      <span class="bars text-dark-tp4"></span>
    </button><!-- mobile sidebar toggler button -->

    <button type="button" class="btn mr-2 d-none d-xl-flex btn-h-lighter-purple btn-burger collapsed" data-toggle="sidebar" data-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle sidebar">
      <span class="bars bgc-dark-tp3"></span>
    </button><!-- sidebar toggler button -->

  </div>

  <div class="navbar-content d-none d-lg-block d-md-block">
    <a class="navbar-brand text-white" style="vertical-align:text-bottom;" *ngIf="usuario.empresa" routerLink="/inicio"><img class="pull-left" src="{{(usuario.id_empresa?usuario.empresa.imagen:rest_server+'img/icon-empresa-default.png')}}" width="45" height="45" /></a>
    <a *ngIf="usuario.empresa" class="navbar-brand text-white" routerLink="/inicio">
      <i style="display:block; font-size: 1.25rem;">
        {{usuario.empresa.nombre}}
      </i>
      <small style="display:block; font-size: 1.00rem;">
        {{usuario.persona.nombre_completo}}
      </small>
    </a>
    <!--<button class="navbar-toggler py-2" type="button" data-toggle="collapse" data-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle navbar search">
      <i class="fa fa-search text-white text-90 py-1"></i>
    </button>

    <div class="navbar-content-section collapse navbar-collapse navbar-backdrop" id="navbarSearch">
      <div class="d-flex align-items-center ml-lg-3">
        <i class="fa fa-search text-white mr-n1 d-none d-lg-block"></i>
        <input type="text" class="navbar-search-input px-1 pl-lg-4 ml-lg-n3 w-100 autofocus" placeholder=" Buscar ..." aria-label="Search" />
      </div>
    </div>-->

  </div><!-- .navbar-content -->


  <!-- mobile #navbarMenu toggler button -->
  <button class="navbar-toggler ml-1 mr-2 px-1" type="button" data-toggle="collapse" data-target="#navbarMenu" aria-controls="navbarMenu" aria-expanded="false" aria-label="Toggle navbar menu">
    <span class="pos-rel">
          <img class="border-2 brc-white-tp1 radius-round" width="36" src="{{rest_server+usuario.persona.imagen}}" alt="Jason's Photo">
          <span class="bgc-warning radius-round border-2 brc-white p-1 position-tr mr-1px mt-1px"></span>
    </span>
  </button>

  <div class="navbar-menu collapse navbar-collapse navbar-backdrop" id="navbarMenu">

    <div class="navbar-nav">
      <ul class="nav">
        <li class="nav-item dropdown order-first" *ngIf="usuario.empresa && (usuario.empresa.usar_medicina_consultorio)">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <span class="d-none d-lg-block d-xl-none correlativo-pedido badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':ficha_actual_sucursal.ficha_actual>1,'badge-success':ficha_actual_sucursal.ficha_actual==1}">{{ficha_actual_sucursal.ficha_actual}}</span>
            <span class="d-block d-lg-none d-xl-block">
              <span class="text-90" id="id-user-welcome">{{ficha_actual_sucursal.sucursal.nombre}}: Ficha</span>
              <span class="correlativo-pedido d-block badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':ficha_actual_sucursal.ficha_actual>1,'badge-success':ficha_actual_sucursal.ficha_actual==1}">{{ficha_actual_sucursal.ficha_actual}}</span>
            </span>
            <i class="caret fa fa-angle-down d-none d-xl-block"></i>
            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
          </a>
          <div class="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3">
            <div class="d-none d-lg-block d-xl-none">
              <div class="dropdown-header">
                {{ficha_actual_sucursal.sucursal.nombre}}: Ficha
              </div>
              <div class="dropdown-divider"></div>
            </div>

            <ng-container *ngFor="let sucursal_usuario of usuario.sucursalesUsuario">
              <a class="dropdown-item btn btn-outline-grey btn-h-lighter-primary btn-a-lighter-primary" href="javascript:void(0)" (click)="obtenerNumeroFichaActual(sucursal_usuario)">
                <i class="fa fa-power-off text-110 mr-lg-1 " (click)="abrirPopupConfirmacionReinicializacionFicha({sucursal_usuario:sucursal_usuario,ficha_correlativo:1,ficha_tratamiento_correlativo:sucursal_usuario.sucursal.ficha_tratamiento_correlativo})"></i>
                {{sucursal_usuario.sucursal.nombre}} - Citas Medicas
                <i class="fas fa-sort-numeric-down text-primary-m1 text-105 mr-1"></i>
                <span class="badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':sucursal_usuario.sucursal.ficha_correlativo>1,'badge-success':sucursal_usuario.sucursal.ficha_correlativo==1}">{{sucursal_usuario.sucursal.ficha_correlativo}}</span>
              </a>
              <a class="dropdown-item btn btn-outline-grey btn-h-lighter-primary btn-a-lighter-primary" href="javascript:void(0)" (click)="obtenerNumeroFichaActual(sucursal_usuario)">
                <i class="fa fa-power-off text-110 mr-lg-1 " (click)="abrirPopupConfirmacionReinicializacionFicha({sucursal_usuario:sucursal_usuario,ficha_tratamiento_correlativo:1,ficha_correlativo:sucursal_usuario.sucursal.ficha_correlativo})"></i>
                {{sucursal_usuario.sucursal.nombre}} - Tratamientos
                <i class="fas fa-sort-numeric-down text-primary-m1 text-105 mr-1"></i>
                <span class="badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':sucursal_usuario.sucursal.ficha_tratamiento_correlativo>1,'badge-success':sucursal_usuario.sucursal.ficha_tratamiento_correlativo==1}">{{sucursal_usuario.sucursal.ficha_tratamiento_correlativo}}</span>
              </a>
            </ng-container>
          </div>
        </li>
        <li class="nav-item dropdown order-first" *ngIf="usuario.empresa && (usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_pedidos_en_linea || usuario.empresa.usar_pedidos_restaurante)">
          <!--<select (change)="obtenerNumeroPedidoActual(pedido_actual_sucursal)" class="badge badge-primary" [compareWith]="compararObjectos" name="numero_pedido" class="form-control" [(ngModel)]="pedido_actual_sucursal">
            <option [ngValue]="sucursal_usuario" *ngFor="let sucursal_usuario of usuario.sucursalesUsuario">{{sucursal_usuario.sucursal.nombre}}</option>
          </select>
          <a class="navbar-brand text-white" href="javascript:void(0)">
            <small>
              Pedido: {{pedido_actual_sucursal.pedido_actual}}
            </small>
          </a>-->
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <span class="d-none d-lg-block d-xl-none correlativo-pedido badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':pedido_actual_sucursal.pedido_actual>1,'badge-success':pedido_actual_sucursal.pedido_actual==1}">{{pedido_actual_sucursal.pedido_actual}}</span>
            <span class="d-block d-lg-none d-xl-block">
              <span class="text-90" id="id-user-welcome">{{pedido_actual_sucursal.sucursal.nombre}}: Pedido</span>
              <span class="correlativo-pedido d-block badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':pedido_actual_sucursal.pedido_actual>1,'badge-success':pedido_actual_sucursal.pedido_actual==1}">{{pedido_actual_sucursal.pedido_actual}}</span>
            </span>
            <i class="caret fa fa-angle-down d-none d-xl-block"></i>
            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
          </a>
          <div class="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3">
            <div class="d-none d-lg-block d-xl-none">
              <div class="dropdown-header">
                {{pedido_actual_sucursal.sucursal.nombre}}: Pedido
              </div>
              <div class="dropdown-divider"></div>
            </div>

            <a *ngFor="let sucursal_usuario of usuario.sucursalesUsuario" class="dropdown-item btn btn-outline-grey btn-h-lighter-primary btn-a-lighter-primary" href="javascript:void(0)" (click)="obtenerNumeroPedidoActual(sucursal_usuario)">
              <i class="fa fa-power-off text-110 mr-lg-1 " (click)="abrirPopupConfirmacionReinicializacion(sucursal_usuario)"></i>
              {{sucursal_usuario.sucursal.nombre}} - 
              <i class="fas fa-sort-numeric-down text-primary-m1 text-105 mr-1"></i>
              <span class="badge badge-sm radius-round text-120 border-1 brc-white-tp5" [ngClass]="{'badge-warning':sucursal_usuario.sucursal.pedido_correlativo>1,'badge-success':sucursal_usuario.sucursal.pedido_correlativo==1}">{{sucursal_usuario.sucursal.pedido_correlativo}}</span>
            </a>
          </div>
        </li>
        <li class="nav-item dropdown dropdown-mega" *ngIf="usuario.empresa && (usuario.empresa.usar_mensajeria_redes_sociales)">
          <a title="Mensajeria Redes Sociales" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-comment text-110 icon-animated-vertical mr-lg-1"></i>
            <i class="caret fas fa-comment d-block d-lg-none"></i>
            <div class="dropdown-caret brc-warning-l2"></div>
          </a>
          <div class="p-0 dropdown-menu dropdown-animated bgc-light-m1 brc-primary-m2 border-t-0 border-x-1 border-b-2 ace-scrollbar">
            <div class="row mx-0">
              <div class="bgc-white col-lg-8 col-12 p-4">
                <div class="row">
                  <div class="col-12">
                    <h5 class="text-dark-m2">
                      <i class="fas fa-cog mr-1 text-primary-m1" (click)="verPlantillasMensajesEmpresa()"></i>
                      Plataformas
                    </h5>

                    <div class="mt-3">
                      <div class="row">
                        <div class="col-6 col-md-8">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-success-m2">
                            <div class="bgc-success radius-1 mr-3 p-3">
                              <i class="fab fa-whatsapp text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">Whatsapp</div>
                              Visualiza las conversaciones de whatsapp en Snapquick
                            </div>
                          </div>
                        </div>
                        <div class="col-6 col-md-4">
                          <div class="row">
                            <div class="col-12">
                              <a *ngIf="!usuario.empresa.conexion_redes_sociales" title="Whatsapp" (click)="abrirConfiguracionWhatsapp()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                                <i class="ace-icon fas fa-qrcode text-180"></i>
                              </a>
                              <!--<a *ngIf="sucursal_whatsapp.sucursal.conexion_redes_sociales" title="Reconexion" (click)="reconectarClienteWhatsapp(sucursal_whatsapp.sucursal)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                                <i class="fas fa-plug text-180"></i>
                              </a>-->
                              <a *ngIf="usuario.empresa.conexion_redes_sociales" title="Whatsapp" (click)="abrirConversacionWhatsapp()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                                <i class="ace-icon fas fa-comment text-180"></i>
                              </a>
                              <a *ngIf="usuario.empresa.conexion_redes_sociales" title="Whatsapp" (click)="abrirPopupConfirmacionDesconexionClienteWhatsapp()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                                <i class="ace-icon fas fa-times text-180"></i>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div class="row">
                        <div class="col-10">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-primary-m2">
                            <div class="bgc-primary radius-1 mr-3 p-3">
                              <i class="fab fa-facebook-messenger text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">Facebook Messenger</div>
                              Envia mensajes en Facebook Messenger desde Snapquick
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <a title="Messenger" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                            <i class="ace-icon fas fa-edit text-180"></i>
                          </a>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-10">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-danger-m2">
                            <div class="bgc-danger radius-1 mr-3 p-3">
                              <i class="fab fa-instagram text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">Instagram</div>
                              Envia mensajes en Instagram Messenger desde Snapquick
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <a title="Instagram" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                            <i class="ace-icon fas fa-edit text-180"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-lg-4 col-12 p-4 dropdown-clickable">
                <h5 class="text-blue-m1">
                  <i class="fa fa-envelope mr-1 text-grey-m2"></i>
                  Escribenos
                </h5>

                <form class="my-3">
                  <div class="  mb-2">
                    <input placeholder="Nombre" type="text" class="form-control border-l-2" />
                  </div>

                  <div class="  mb-2">
                    <input placeholder="Correo Electrónico" type="text" class="form-control border-l-2" />
                  </div>

                  <div class="  mb-4">
                    <textarea class="form-control brc-primary-m2 border-l-2 text-grey-d1" rows="3" placeholder="Tu mensaje..."></textarea>
                  </div>

                  <div class="text-center">
                    <button type="reset" class="btn btn-smd btn-wide btn-secondary text-110">Cancelar</button>
                    <button type="button" class="btn btn-smd btn-wide btn-primary text-110">Enviar</button>
                  </div>
                </form>
              </div>

            </div>
          </div>
        </li>
        <li class="nav-item" *ngIf="usuario.empresa && (usuario.empresa.usar_biometrico_asistencia)">
          <a title="Tickear Asistencia" (click)="abrirRegistroAsistencia()" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-fingerprint text-110 icon-animated-vertical mr-lg-1"></i>
          </a>
        </li>
        <li class="nav-item">
          <a title="Pagar Servicios" *ngIf="usuario.empresa && usuario.empresa.usar_pagos_servicios_empresas" (click)="abrirPagosServiciosBasicos()" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-money-check-alt text-110 icon-animated-vertical mr-lg-1"></i>
          </a>
        </li>
        <li class="nav-item dropdown dropdown-mega">
          <a title="Ver Sucursales" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="javascript:void(0)" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-home text-110 icon-animated-vertical mr-lg-1"></i>
            <i class="caret fas fa-home d-block d-lg-none"></i>
            <div class="dropdown-caret brc-warning-l2"></div>
          </a>
          <div class="p-0 dropdown-menu dropdown-animated bgc-light-m1 brc-primary-m2 border-t-0 border-x-1 border-b-2 ace-scrollbar">
            <div class="row mx-0">

              <!--<div class="col-lg-4 col-12 p-2 p-lg-3 p-xl-4 d-flex align-items-center">
                <div class="list-group mt-3 mx-auto px-0">
                  <a href="#" class="list-group-item list-group-item-action">
                    <i class="fab fa-facebook text-blue-m1 text-110 mr-2"></i>
                    Cras justo odio
                  </a>
                  <a href="#" class="list-group-item list-group-item-action text-primary">
                    <i class="fa fa-user text-success-m2 text-110 mr-2"></i>
                    Dapibus ac facilisis in
                  </a>
                  <a href="#" class="list-group-item list-group-item-action">
                    <i class="fa fa-clock text-purple-m2 text-110 mr-2"></i>
                    Morbi leo risus
                  </a>
                  <a href="#" class="list-group-item list-group-item-action bgc-success-l1 bgc-h-success-l2">
                    <i class="fa fa-check text-orange text-110 mr-2"></i>
                    Porta ac consectetur
                    <span class="ml-2 badge badge-primary badge-pill badge-lg">14</span>
                  </a>
                  <a href="#" class="list-group-item list-group-item-action disabled">Vestibulum at eros</a>
                </div>
              </div>-->

              <div class="bgc-white col-lg-8 col-12 p-4">
                <div class="row">
                  <div class="col-12">
                    <h5 class="text-dark-m2">
                      <i class="fas fa-user mr-1 text-primary-m1"></i>
                      Mis Datos
                    </h5>

                    <div class="mt-3">
                      <div class="row">
                        <div class="col-10">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-purple-m2">
                            <div class="bgc-purple radius-1 mr-3 p-3">
                              <i class="fa fa-user text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">Datos Personales</div>
                              Cambia tus datos personales
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <a title="Datos Personales" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-purple btn-a-outline-purple btn-text-purple" href="javascript:void(0)">
                            <i class="ace-icon fas fa-edit text-180"></i>
                          </a>
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="col-10">
                          <div class="media mt-2 px-3 pt-1 border-l-2 brc-warning-m2">
                            <div class="bgc-warning radius-1 mr-3 p-3">
                              <i class="fa fa-key text-white text-150"></i>
                            </div>
                            <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                              <div class="text-grey-d2 font-bolder">Cuenta</div>
                              Puedes cambiar tu contraseña para mayor privacidad...
                            </div>
                          </div>
                        </div>
                        <div class="col-2">
                          <a (click)="abrirActualizacionClave()" title="Mi Cuenta" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                            <i class="ace-icon fas fa-edit text-180"></i>
                          </a>
                        </div>
                      </div>

                      <ng-container *ngIf="usuario.empresa && usuario.empresa.usar_registro_pedagogico">
                        <hr />                     
                        <div class="row">
                          <div class="col-10">
                            <div  class="media mt-2 px-3 pt-1 border-l-2 brc-success-m2">
                              <div class="bgc-success radius-1 mr-3 p-3">
                                <i class="fas fa-chalkboard-teacher text-white text-150"></i>
                              </div>
                              <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                                <div class="text-grey-d2 font-bolder">Mis Cursos y Materias</div>
                                Puedes agregar tus registros de cursos y materias...
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <a id="registroprofesor" title="Mis Registros" (click)="modificarDatosCursosMaterias()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                              <i class="ace-icon fas fa-chalkboard-teacher text-180"></i>
                            </a>
                            <a id="estudiantes" title="Lista de Estudiantes" (click)="modificarDatosCursos()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                              <i class="ace-icon fas fa-users text-180"></i>
                            </a>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="usuario.empresa && es_usuario_administrador && usuario.empresa.usar_estudiantes_colegio">
                        <hr />                     
                        <div class="row">
                          <div class="col-10">
                            <div  class="media mt-2 px-3 pt-1 border-l-2 brc-success-m2">
                              <div class="bgc-success radius-1 mr-3 p-3">
                                <i class="fas fa-chalkboard-teacher text-white text-150"></i>
                              </div>
                              <div class="media-body pb-0 mb-0 text-90 text-grey-m1">
                                <div class="text-grey-d2 font-bolder">Cursos y Materias</div>
                                Puedes agregar registros de cursos y materias...
                              </div>
                            </div>
                          </div>
                          <div class="col-2">
                            <a id="registrocursos" title="Registro de Cursos" (click)="verListaCursosColegio()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                              <i class="ace-icon fas fa-chalkboard-teacher text-180"></i>
                            </a>
                            <a id="registromaterias" title="Registro de Materias" (click)="verListaMateriasColegio()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                              <i class="ace-icon fas fa-clipboard-list text-180"></i>
                            </a>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="usuario.id_empresa && datos_app.nombre=='SnapQuick'">
                  <hr />
                  <div class="row">
                    <div class="col-md-6 col-12 col-sm-12 col-lg-6">
                      <h5 class="text-blue-m1">
                        <i class="fas fa-home mr-1 text-grey-m2"></i>
                        Empresa
                      </h5>
                      <hr />
                      <div>
                        <div class="row">
                          <div class="col-2">
                            <div class="media-body pb-0 mb-0  text-90 text-muted">
                              <span class="bgc-success-tp2 radius-1 mr-4 p-3"><i class="fas fa-building text-white text-150"></i></span>
                            </div>
                          </div>
                          <div class="col-6">
                              <div class="media-body pb-0 mb-0  text-90 text-muted">
                                <div class="text-grey-d2 font-bolder">{{empresa.nombre}}</div>
                                {{empresa.direccion}}
                              </div>
                          </div>
                          <div class="col-4">
                            <a title="Modificar" *ngIf="aplicacion_empresa.puede_modificar" (click)="modificarDatosSucursal(sucursal)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                              <i class="ace-icon fa fa-edit text-180"></i>
                            </a>
                            <a title="Ver comunicacion" *ngIf="usuario.empresa.usar_facturacion_computarizada_en_linea" (click)="verificarComunicacionSin()" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                              <i class="ace-icon fas fa-retweet text-180"></i>
                            </a>
                            <a *ngIf="aplicacion_empresa.puede_modificar && usuario.empresa.usar_facturacion_computarizada_en_linea && !usuario.empresa.usar_facturacion_computarizada_en_linea_por_sucursal_empresa" (click)="modificarDatosFacturacionEmpresa(empresa)" title="Facturación" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                              <i class="ace-icon fas fa-file-invoice-dollar text-180"></i>
                            </a>
                          </div>
                        </div>

                        <hr />
                      </div>
                    </div>
                    <div class="col-md-6 col-12 col-sm-12 col-lg-6">
                      <h5 class="text-blue-m1">
                        <i class="fas fa-home mr-1 text-grey-m2"></i>
                        Sucursales
                      </h5>
                      <hr />
                      <div>
                        <ng-container *ngFor="let sucursal of sucursales">
                          <div class="row">
                            <div class="col-2">
                              <div class="media-body pb-0 mb-0  text-90 text-muted">
                                <span class="bgc-purple-tp2 radius-1 mr-4 p-3"><i class="fas fa-store text-white text-150"></i></span>
                              </div>
                            </div>
                            <div class="col-6">
                                <div class="media-body pb-0 mb-0  text-90 text-muted">
                                  <div class="text-grey-d2 font-bolder">{{sucursal.nombre}}</div>
                                  {{sucursal.direccion}}
                                </div>
                            </div>
                            <div class="col-4">
                              <a title="Modificar" *ngIf="aplicacion_empresa.puede_modificar" (click)="modificarDatosSucursal(sucursal)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)">
                                <i class="ace-icon fa fa-edit text-180"></i>
                              </a>
                              <a *ngIf="(usuario.empresa.usar_facturacion || usuario.empresa.usar_facturacion_computarizada_en_linea)" (click)="modificarDatosFacturacionSucursal(sucursal)" title="Facturación" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)">
                                <i class="ace-icon fas fa-file-invoice-dollar text-180"></i>
                              </a>
                              <a (click)="modificarDatosAlmacenesSucursal(sucursal)" *ngIf="aplicacion_empresa.puede_modificar && usuario.empresa.usar_almacenes" title="Almacenes" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)">
                                <i class="ace-icon fas fa-warehouse text-180"></i>
                              </a>
                            </div>
                          </div>

                          <hr />
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>


              <div class="col-lg-4 col-12 p-4 dropdown-clickable">
                <h5 class="text-blue-m1">
                  <i class="fa fa-envelope mr-1 text-grey-m2"></i>
                  Escribenos
                </h5>

                <form class="my-3">
                  <div class="  mb-2">
                    <input placeholder="Nombre" type="text" class="form-control border-l-2" />
                  </div>

                  <div class="  mb-2">
                    <input placeholder="Correo Electrónico" type="text" class="form-control border-l-2" />
                  </div>

                  <div class="  mb-4">
                    <textarea class="form-control brc-primary-m2 border-l-2 text-grey-d1" rows="3" placeholder="Tu mensaje..."></textarea>
                  </div>

                  <div class="text-center">
                    <button type="reset" class="btn btn-smd btn-wide btn-secondary text-110">Cancelar</button>
                    <button type="button" class="btn btn-smd btn-wide btn-primary text-110">Enviar</button>
                  </div>
                </form>
              </div>

            </div>


            <!--<div class="row mx-0 order-first order-lg-last bgc-primary-l4 border-t-1 brc-default-m4">
              <div class="col-lg-8 offset-lg-2 d-flex justify-content-center py-4 d-flex">

                <button class="mx-2px btn btn-sm btn-app btn-outline-warning btn-h-outline-warning btn-a-outline-warning radius-1 border-2">
                  <i class="fa fa-cog text-190 d-block mb-2 h-4"></i>
                  <span class="text-muted">Settings</span>
                </button>

                <button class="mx-2px btn btn-sm btn-app btn-outline-info btn-h-outline-info radius-1 border-2">
                  <i class="fa fa-edit text-190 d-block mb-2 h-4"></i>
                  Edit
                  <span class="position-tr text-danger-m2 text-130 mr-1">*</span>
                </button>

                <button class="mx-2px btn btn-sm btn-app btn-dark radius-1">
                  <i class="fa fa-lock text-150 d-block mb-2 h-4"></i>
                  Lock
                </button>

              </div>
            </div>-->
          </div>
        </li>
        <li class="nav-item dd-backdrop dropdown dropdown-mega" *ngIf="usuario.empresa && (usuario.empresa.usar_registro_pedagogico) && es_usuario_profesor">
          <a title="Ruleta" (click)="abrirRuleta()" class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-dharmachakra text-110 icon-animated-vertical mr-lg-1"></i>

            <i class="caret fa fa-power-off d-block d-lg-none"></i>
            <div class="dropdown-caret brc-warning-l2"></div>
          </a>
        </li>
        <li class="nav-item dd-backdrop dropdown dropdown-mega" *ngIf="usuario.empresa && usuario.empresa.punto_venta_offline">
          <a class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-sync text-110 icon-animated-vertical mr-lg-1"></i>

            <span class="d-inline-block d-lg-none ml-2">Sincronización</span>

            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
            <div class="dropdown-caret brc-warning-l2"></div>
          </a>
          <div class="shadow dropdown-menu dropdown-animated animated-1 dropdown-xs p-0 bg-white brc-warning-l1 border-x-1 border-b-1">
            <div class="bgc-warning-l2 py-25 px-4 border-b-1 brc-warning-l2">
              <span class="text-dark-tp4 text-600 text-90 text-uppercase">
                <i class="fa fa-check mr-2px text-warning-d2 text-120"></i>
                Sincronización de datos con el servidor
              </span>
            </div>
            <div class="px-4 py-2">
              <div class="text-95">
                <a (click)="subirDatosServidor()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-danger">
                  <i class="fa fa-upload bgc-danger-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Subir Datos</span>
                </a>
              </div>
            </div>

            <hr class="my-1 mx-4" />
            <div class="px-4 py-2">
              <div class="text-95">
                <a (click)="abrirModalDescargaDatosServidor()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-success">
                  <i class="fa fa-download bgc-green-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Descargar Datos</span>
                </a>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown dropdown-mega">
          <a class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-bell text-110 icon-animated-bell mr-lg-2"></i>

            <span class="d-inline-block d-lg-none ml-2">Notificaciones</span><!-- show only on mobile -->
            <span id="id-navbar-badge1" class="badge badge-sm badge-warning radius-round text-80 border-1 brc-white-tp5">{{sumarContadoresNotificacion()}}</span>

            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
            <div class="dropdown-caret brc-white"></div>
          </a>
          <div class="shadow dropdown-menu dropdown-animated dropdown-sm p-0 bg-white brc-primary-m3 border-1 border-b-2">
            <ul class="nav nav-tabs nav-tabs-simple w-100 nav-justified dropdown-clickable border-b-1 brc-default-m4">
              <li class="nav-item">
                <a class="d-style px-0 mx-0 py-3 nav-link active text-600 brc-blue-m2 text-grey-m3 bgc-h-blue-l4" data-toggle="tab" href="#navbar-notif-tab-1" role="tab">
                  <span class="d-active text-blue-m1 text-105">Notificaciones</span>
                  <span class="d-n-active">Notificaciones</span>
                </a>
              </li>
              <li class="nav-item" *ngIf="usuario.empresa && usuario.empresa.usar_mensajeria">
                <a class="d-style px-0 mx-0 py-3 nav-link text-600 brc-purple-m2 text-grey-m3 bgc-h-purple-l4" data-toggle="tab" href="#navbar-notif-tab-2" role="tab">
                  <span class="d-active text-purple-m1 text-105">Mensajes</span>
                  <span class="d-n-active">Mensajes</span>
                </a>
              </li>
            </ul><!-- .nav-tabs -->


            <div class="tab-content tab-sliding p-0">

              <div *ngIf="usuario.empresa && (usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes || usuario.empresa.usar_vencimiento_economico_ventas || usuario.empresa.usar_vencimiento_economico_compras || usuario.empresa.usar_vencimientos || usuario.empresa.usar_pedidos_distribucion || usuario.empresa.usar_contabilidad || usuario.empresa.usar_pedidos_en_linea || usuario.empresa.usar_mantenimiento_vehiculos || usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto)" class="tab-pane mh-none show active px-md-1 pt-1" id="navbar-notif-tab-1" role="tabpanel">

                <a *ngIf="usuario.empresa.usar_vencimientos" (click)="abrirListaVencimientoProductos()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fa fa-gift bgc-blue-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Vencimientos Productos</span>
                  <span class="float-right badge badge-danger radius-round text-80">+{{notificacion.contador_vencimiento_productos_notificacion}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_vencimiento_economico_ventas" (click)="abrirListaVencimientoCreditos()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-money-bill-alt bgc-success-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Vencimientos Créditos</span>
                  <span class="float-right badge badge-info radius-round text-80">+{{notificacion.contador_ventas_credito_notificacion}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_vencimiento_economico_compras" (click)="abrirListaVencimientoDeudas()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-money-bill-alt bgc-success-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Vencimiento Deudas</span>
                  <span class="float-right badge badge-success radius-round text-80">+{{notificacion.contador_compras_credito_notificacion}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_despachos || usuario.empresa.usar_pedidos_en_linea" (click)="abrirListaPedidos()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fa fa-shopping-cart bgc-purple-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Pedidos</span>
                  <span class="float-right badge badge-success radius-round text-80">+{{notificacion.contador_pedidos_notificacion}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_contabilidad_semi_automatica" (click)="abrirListaVentasNoContabilizadas()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="far fa-clock bgc-warning-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Ventas no contabilizadas</span>
                  <span class="float-right badge badge-warning radius-round text-80">+{{notificacion.contador_ventas_no_contabilizadas}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_contabilidad_semi_automatica" (click)="abrirListaPagosVentasNoContabilizadas()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="far fa-clock bgc-warning-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Pagos de ventas no contabilizados</span>
                  <span class="float-right badge badge-warning radius-round text-80">+{{notificacion.contador_pagos_venta_no_contabilizados}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_inventario_minimo" (click)="abrirListaProductosInventarioMinimo()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="far fa-clock bgc-primary text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Inventario Mínimo</span>
                  <span class="float-right badge badge-primary radius-round text-80">+{{notificacion.contador_productos_inventario_minimo}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_mantenimiento_vehiculos" (click)="abrirListaLlantasNotificacion()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-dharmachakra bgc-info text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Llantas</span>
                  <span class="float-right badge badge-info radius-round text-80">+{{notificacion.contador_llantas}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_mantenimiento_vehiculos" (click)="abrirListaAceitesNotificacion()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-oil-can bgc-warning text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Aceite</span>
                  <span class="float-right badge badge-warning radius-round text-80">+{{notificacion.contador_aceites}}</span>
                </a>
                <ng-container *ngIf="usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes && (es_usuario_administrador || es_usuario_contabilidad)">
                  <a (click)="abrirListaVencimientoPagosEstudiantes(true)" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                    <i class="fas fa-money-bill-alt bgc-success-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                    <span class="text-muted">Vencimientos Pagos Estudiantes</span>
                    <span class="float-right badge badge-info radius-round text-80">+{{notificacion.contador_vencimientos_pagos_estudiantes_notificacion}}</span>
                  </a>
                  <a (click)="abrirListaVencimientoPagosEstudiantes(false)" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                    <i class="fas fa-money-bill-alt bgc-success-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                    <span class="text-muted">Vencimientos Pagos Estudiantes Anulados</span>
                    <span class="float-right badge badge-info radius-round text-80">+{{notificacion.contador_vencimientos_pagos_estudiantes_anulados_notificacion}}</span>
                  </a>
                </ng-container>
                <a *ngIf="(usuario.empresa.usar_estudiantes_colegio || usuario.empresa.usar_estudiantes_instituto) && (es_usuario_administrador || es_usuario_contabilidad)" (click)="abrirListaAsuntosAdministrativosNotificacion()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-file-signature bgc-info text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Asuntos Administrativos</span>
                  <span class="float-right badge badge-info radius-round text-80">+{{notificacion.contador_asuntos_administrativos_notificacion}}</span>
                </a>
                <a *ngIf="usuario.empresa.usar_torneos" (click)="abrirListaJugadoresCarnet()" href="javascript:void(0)" class="mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                  <i class="fas fa-users bgc-warning-tp1 text-white text-110 mr-1 p-2 radius-1"></i>
                  <span class="text-muted">Jugadores Carnet</span>
                  <span class="float-right badge badge-warning radius-round text-80">+{{notificacion.contador_jugadores}}</span>
                </a>
                <!--<hr class="mt-1 mb-1px brc-info-m4" />
                <a href="#" class="mb-0 py-3 border-0 list-group-item text-blue-m2 text-uppercase text-center text-85 font-bold">
                  See All Notifications
                  <i class="ml-2 fa fa-arrow-right text-muted"></i>
                </a>-->

              </div><!-- .tab-pane : notifications -->


              <div class="tab-pane mh-none pl-md-2" id="navbar-notif-tab-2" role="tabpanel">
                <div ace-scroll='{"ignore": "mobile", "height": 300, "smooth":true}'>
                  <ng-container>
                    <ng-container *ngFor="let mensaje of notificacion.ultimos_5_mensajes">
                      <a (click)="leerMensaje(mensaje)" href="javascript:void(0)" class="d-flex mb-0 border-0 list-group-item list-group-item-action btn-h-lighter-secondary">
                        <img src="{{rest_server+mensaje.mensaje.usuario_creador.persona.imagen}}" width="48" class="align-self-start border-2 brc-primary-m3 p-1px mr-2 radius-round" />
                        <div>
                          <span class="text-blue-m1 font-bolder">{{mensaje.mensaje.usuario_creador.persona.nombres}}:</span>
                          <span class="text-grey text-90">{{mensaje.mensaje.mensaje}} ...</span>
                          <br />
                          <span class="text-grey-m2 text-85">
                                              <i class="far fa-clock"></i>
                                              {{mensaje.mensaje.createdAt | date:"dd/MM/yyyy - HH:mm"}}
                                          </span>
                        </div>
                      </a>
                      <hr class="my-1px brc-grey-l3" />
                    </ng-container>
                  </ng-container>

                </div><!-- ace-scroll -->

                <hr class="my-1px brc-grey-l2 border-double" />
                <a (click)="verTodosMensajes()" href="javascript:void(0)" class="mb-0 py-3 border-0 list-group-item text-blue-m2 text-uppercase text-center text-85 font-bold">
                  Ver todos los mensajes
                  <i class="ml-2 fa fa-arrow-right text-muted"></i>
                </a>
              </div><!-- .tab-pane : messages -->

            </div>
          </div>
        </li>

        <!--<li class="nav-item dd-backdrop dropdown dropdown-mega">
          <a class="nav-link dropdown-toggle pl-lg-3 pr-lg-4" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <i class="fa fa-flask text-110 icon-animated-vertical mr-lg-1"></i>

            <span class="d-inline-block d-lg-none ml-2">Tasks</span>
            <span id="id-navbar-badge2" class="badge badge-sm text-90 text-success-l4">+2</span>

            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
            <div class="dropdown-caret brc-warning-l2"></div>
          </a>
          <div class="shadow dropdown-menu dropdown-animated animated-1 dropdown-xs p-0 bg-white brc-warning-l1 border-x-1 border-b-1">
            <div class="bgc-warning-l2 py-25 px-4 border-b-1 brc-warning-l2">
              <span class="text-dark-tp4 text-600 text-90 text-uppercase">
                      <i class="fa fa-check mr-2px text-warning-d2 text-120"></i>
                      4 Tasks to complete
                    </span>
            </div>


            <div class="px-4 py-2">
              <div class="text-95">
                <span class="text-grey-d1">Software update</span>
              </div>
              <div class="progress mt-2">
                <div class="progress-bar bgc-info" role="progressbar" style="width: 60%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">60%</div>
              </div>
            </div>

            <hr class="my-1 mx-4" />
            <div class="px-4 py-2">
              <div class="text-95">
                <span class="text-grey-d1">Hardware upgrade</span>
              </div>
              <div class="progress mt-2">
                <div class="progress-bar bgc-warning" role="progressbar" style="width: 40%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">40%</div>
              </div>
            </div>

            <hr class="my-1 mx-4" />
            <div class="px-4 py-2">
              <div class="text-95">
                <span class="text-grey-d1">Customer support</span>
              </div>
              <div class="progress mt-2">
                <div class="progress-bar bgc-danger" role="progressbar" style="width: 30%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">30%</div>
              </div>
            </div>

            <hr class="my-1 mx-4" />
            <div class="px-4 py-2">
              <div class="text-95">
                <span class="text-grey-d1">Fixing bugs</span>
              </div>
              <div class="progress mt-2">
                <div class="progress-bar bgc-success progress-bar-striped progress-bar-animated" role="progressbar" style="width: 85%;" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100">85%</div>
              </div>
            </div>




            <hr class="my-1px mx-2 brc-info-l2 " />
            <a href="#" class="d-block bgc-h-primary-l4 py-3 border-0 text-center text-blue-m2">
              <span class="text-85 text-600 text-uppercase">See All Tasks</span>
              <i class="ml-2 fa fa-arrow-right text-muted"></i>
            </a>
          </div>
        </li>-->


        <li class="nav-item dropdown order-first order-lg-last">
          <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">
            <img id="id-navbar-user-image" class="d-none d-lg-inline-block radius-round border-2 brc-white-tp1 mr-2" width="36" height="36" src="{{rest_server+usuario.persona.imagen}}" alt="Jason's Photo">
            <span class="d-inline-block d-lg-none d-xl-inline-block">
                      <span class="text-90" id="id-user-welcome">Bienvenido,</span>
            <span class="nav-user-name">{{usuario.persona.nombres}}</span>
            </span>

            <i class="caret fa fa-angle-down d-none d-xl-block"></i>
            <i class="caret fa fa-angle-left d-block d-lg-none"></i>
          </a>

          <div class="dropdown-menu dropdown-caret dropdown-menu-right dropdown-animated brc-primary-m3">
            <div class="d-none d-lg-block d-xl-none">
              <div class="dropdown-header">
                {{usuario.persona.nombres}}
              </div>
              <div class="dropdown-divider"></div>
            </div>

            <a class="dropdown-item btn btn-outline-grey btn-h-lighter-primary btn-a-lighter-primary" href="html/page-profile.html">
              <i class="fa fa-user text-primary-m1 text-105 mr-1"></i>
              Profile
            </a>

            <a *ngIf="!usuario.id_empresa" class="dropdown-item btn btn-outline-grey btn-h-lighter-success btn-a-lighter-success" href="#" data-toggle="modal" (click)="abrirPopupConfirmacionReinicioServidor()">
              <i class="fas fa-sync text-success-m1 text-105 mr-1"></i>
              Reiniciar
            </a>

            <div class="dropdown-divider brc-primary-l2"></div>

            <a class="dropdown-item btn btn-outline-grey btn-h-lighter-secondary btn-a-lighter-secondary" href="javascript:void(0)" (click)="cerrarSesion()">
              <i class="fa fa-power-off text-warning-d1 text-105 mr-1"></i>
              Salir
            </a>
          </div>
        </li><!-- /.nav-item:last -->

      </ul><!-- /.navbar-nav menu -->
    </div><!-- /.navbar-nav -->

  </div><!-- /.navbar-menu.navbar-collapse -->
</div><!-- /.navbar-inner -->

<ng-template #mensaje_vista_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Vista Mensaje</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <div class="row">
      <app-mensaje-vista class="col-12 col-sm-12 col-xl-12 pt-3" id="message-view"
          [mensaje]="mensaje.mensaje"
          [vista_bandeja]="false"
          [movimiento_inventario]="movimiento_inventario_mensaje"
      ></app-mensaje-vista>
    </div>
  </div>
</ng-template>

<ng-template #datos_sucursal let-modal>
  <div class="modal-header">
		<div class="col-6 col-xs-6">
			<h4 class="widget-title">Datos Sucursal: {{sucursal_modificacion.nombre}}</h4>
		</div>
		<div class="col-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="guardarDatosSucursal()">
				<i class="ace-icon fa fa-save red2 align-top text-180"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top text-180"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary px-2" >
      <div class="box-body">
        <div class="row">
					<div class="col-2 col-sm-2">
              <label>Nombre </label> 
              <input [(ngModel)]="sucursal_modificacion.nombre" placeholder="Nombre" type="text" class="form-control border-l-2" />
          </div>
          <div class="col-3 col-sm-3">
            <label>Número Sucursal </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.numero" min="0" placeholder="Número" type="number" step="0" class="form-control border-l-2" />
          </div>
					<div class="col-3 col-sm-3">
              <label>Dirección </label>&nbsp;
              <input [(ngModel)]="sucursal_modificacion.direccion" placeholder="Dirección" type="text" class="form-control border-l-2" />
          </div>
				</div>
        <hr>
        <div class="row">
          <div class="col-3 col-sm-3">
            <label>Teléfono Fijo </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.telefono1" placeholder="Teléfono Fijo" type="text" class="form-control border-l-2" />
          </div>
          <div class="col-3 col-sm-3">
            <label>Teléfono Móvil  </label>&nbsp;
            <input [(ngModel)]="sucursal_modificacion.telefono2" placeholder="Teléfono Móvil" type="text" class="form-control border-l-2" />
          </div>
          <div class="col-3 col-sm-3">
            <label>Ciudad </label>&nbsp;
            <select [compareWith]="compararObjectos" class="form-control" name="departamento" [(ngModel)]="sucursal_modificacion.departamento">
              <option [ngValue]="departamento" *ngFor="let departamento of departamentos">{{departamento.nombre}}</option>
            </select>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-3 col-sm-3" *ngIf="usuario.empresa.usar_pedidos_en_linea">
            <label>¿Abierto?</label>&nbsp;
            <label>
              <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="sucursal_modificacion.abierto"/>
            </label>
          </div>
          <div class="col-3 col-sm-3" *ngIf="usuario.empresa.usar_pedidos_delivery">
            <label>¿Delivery propio activado?</label>&nbsp;
            <label>
              <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="sucursal_modificacion.delivery_propio_activado"/>
            </label>
          </div>
          <div class="col-3 col-sm-3" *ngIf="usuario.empresa.usar_pedidos_delivery">
            <label>¿Acepta Pedido Inmediato?</label>&nbsp;
            <label>
              <input name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="sucursal_modificacion.acepta_pedido_inmediato"/>
            </label>
          </div>
        </div> 
        <hr>
        <div class="row">
          <div class="col-2 col-form-label text-sm-right">
            Ubicación en el mapa
          </div>
          <div class="col-5">
            <google-map *ngIf="conexion_google_map" #mapa 
                width="100%"
                height="300px"
                [center]="center"
                [zoom]="zoom"
                [options]="options">
                <map-marker
                    #markerElem
                    *ngFor="let marker of markers"
                    [position]="marker.position"
                    [label]="marker.label"
                    [title]="marker.title"
                    [options]="marker.options"
                    (mapDragend)="arrastarPinSucursal($event)"
                    >
                </map-marker>
            </google-map>
          </div>
          <div class="col-1">
            <label>Imagen</label>&nbsp;
          </div>
          <div class="col-1" *ngIf="sucursal_modificacion.id">
            <img  src="{{rest_server+sucursal_modificacion.imagen}}" width="100">
          </div>
          <div class="col-3">
              <div class="d-inline-flex align-items-center col-12 col-sm-7 px-0">
                  <div>
                      <input type="file" class="ace-file-input" id="imagen-sucursal" autocomplete="off" (change)="subirImagenSucursal($event)" />
                  </div>
              </div>
          </div>
        </div>    
      </div>
    </div>
	</div>
</ng-template>

<ng-template #descargaDatos let-modal>
	<div class="modal-header">
		<div class="col-md-9 col-xs-9">
			<h4 class="widget-title">Selección descarga datos Servidor</h4>
		</div>
		<div class="col-md-3 col-xs-3">
      <button class="btn btn-primary" type="button" (click)="descargarDatosServidor()">
				<i class="fa fa-download align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body card-body">
    <div class="row">
      <div class="col-3">
          <label>¿Empresa?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="empresa" [(ngModel)]="descarga_servidor.empresa" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
        <label>¿Conceptos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="conceptos" [(ngModel)]="descarga_servidor.tipos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
        <label>¿Productos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="productos" [(ngModel)]="descarga_servidor.productos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Usuarios?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="usuarios" [(ngModel)]="descarga_servidor.usuarios" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
        <label>¿Clientes?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="clientes" [(ngModel)]="descarga_servidor.clientes" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Sucursales?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="sucursales" [(ngModel)]="descarga_servidor.sucursales" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Configuracion Impresion?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="configuracion_impresion" [(ngModel)]="descarga_servidor.configuracion_impresion" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
          <label>¿Aplicaciones?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="aplicaciones" [(ngModel)]="descarga_servidor.aplicaciones" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-3">
          <label>¿Modulos?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="modulos" [(ngModel)]="descarga_servidor.modulos" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
      <div class="col-3">
        <label>¿Dosificaciones?</label>
      </div>
      <div class="col-3">
        <label>
          <input name="dosificaciones" [(ngModel)]="descarga_servidor.dosificaciones" type="checkbox" class="ace-switch input-lg ace-switch-sino bgc-blue-d1">
        </label>
      </div>
    </div>
    <hr>
	</div>
</ng-template>

<ng-template #datoscursosmaterias let-modal>
  <div class="modal-header">
		<div class="col-md-8 col-xs-8">
			<h4 class="widget-title">Mis Cursos y Materias</h4>
		</div>
		<div class="col-md-4 col-xs-4">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-12">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm" *ngFor="let gestion of tipo_gestiones_profesor.clases | orderBy:'nombre':true">
                <a (click)="establecerGestionTab(gestion)" [ngClass]="{'active':gestion_tab.nombre==gestion.nombre}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                  {{gestion.nombre}}
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div *ngFor="let gestion of tipo_gestiones_profesor.clases" [ngClass]="{'active':gestion_tab.nombre==gestion.nombre}" class="tab-pane fade show text-95" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="text-primary-d3 text-120 mb-2">
                  <table class="table table-striped table-hover">
                    <thead>
                        <tr>
                            <th>Nº</th>
                            <th>Nivel Escolar</th>
                            <th>Curso</th>
                            <th>Materia</th>
                            <th *ngIf="!usuario.empresa.usar_estudiantes_colegio">
                              <div class="row">
                                <div class="col-4">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="crearNuevoRegistroProfesor()">
                                    <i class="fa fa-plus text-blue text-180"></i>
                                  </a>
                                </div>
                                <div class="col-4">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="guardarRegistrosProfesor(gestion_tab)">
                                    <i class="fa fa-save text-green text-180"></i>
                                  </a>
                                </div>
                                <div class="col-4">
                                  <a class="text-secondary-d1" href="javascript:void(0)">          
                                    <i class="fa fa-cog text-105"></i>        
                                  </a>      
                                </div>
                              </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let registro_profesor of gestion_tab.registros_profesor; let i=index;">
                            <tr *ngIf="!registro_profesor.eliminado" >
                                <td>{{(i+1)}}</td>
                                <td>{{registro_profesor.nivel_escolar.nombre}}</td>
                                <td>{{registro_profesor.curso.nombre}}</td>
                                <td>{{registro_profesor.materia.nombre}}</td>
                                <td *ngIf="!usuario.empresa.usar_estudiantes_colegio">
                                  <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarRegistroProfesor(registro_profesor)">          
                                    <i class="fa fa-trash-alt text-105"></i>        
                                  </a>      
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #datoscursos let-modal>
  <div class="modal-header">
		<div class="col-md-8 col-xs-8">
			<h4 class="widget-title">Lista de Estudiantes</h4>
		</div>
		<div class="col-md-4 col-xs-4">
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
  <div class="modal-body card-body">
    <div class="row">
      <div class="col-12">
        <div class="card bcard bgc-transparent shadow-none">
          <div class="card-body tabs-left p-0">
            <ul class="nav nav-tabs align-self-start" role="tablist">
              <li class="nav-item brc-success shadow-sm" *ngFor="let gestion of tipo_gestiones_profesor.clases | orderBy:'nombre':true">
                <a (click)="establecerGestionTabCursos(gestion)" [ngClass]="{'active':gestion_tab.nombre==gestion.nombre}" class="nav-link text-left py-3" id="home14-tab-btn" data-toggle="tab" href="#home14" role="tab" aria-controls="home14" aria-selected="true">
                  {{gestion.nombre}}
                </a>
              </li>
            </ul>

            <div class="tab-content p-35 border-1 brc-grey-l1 shadow-sm bgc-white">
              <div *ngFor="let gestion of tipo_gestiones_profesor.clases" [ngClass]="{'active':gestion_tab.nombre==gestion.nombre}" class="tab-pane fade show text-95" id="home14" role="tabpanel" aria-labelledby="home14-tab-btn">
                <div class="row">
                  <div class="col-md-7 col-12">
                    <div class="text-primary-d3 text-120 mb-2">
                      <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th class="text-center pr-0">Nº</th>
                                <th class="text-center pr-0">Curso</th>
                                <th class="text-center pr-0">Cantidad Estudiantes</th>
                                <th class="text-center pr-0">
                                  <a class="text-secondary-d1" href="javascript:void(0)">          
                                    <i class="fa fa-cog text-105"></i>        
                                  </a>      
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let registro_profesor of gestion_tab.registros_profesor; let i=index;">
                                <tr #cursos [ngClass]="{'bgc-success-l2':registro_profesor.id_curso==registro_profesor_actual.id_curso}" class="bgc-h-success-l3 jqgrow ui-row-ltr" *ngIf="!registro_profesor.eliminado" >
                                    <td>{{(i+1)}}</td>
                                    <td class="text-center pr-0">{{registro_profesor.curso}}</td>
                                    <td class="text-center pr-0">{{registro_profesor.total_estudiantes}}</td>
                                    <td class="text-center pr-0">
                                      <ng-container *ngIf="!usuario.empresa.usar_estudiantes_colegio">
                                        <a id="{{i}}impest" title="Descargar excel filiación" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="descargarPlantillaFiliacion(registro_profesor)">          
                                          <i class="fas fa-file-download text-180"></i>        
                                        </a>
                                        <button (click)="clickBotonSubir(i+'subir-excel-estudiantes')" class="fileUpload btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" aria-label="Export" type="button" title="Subir excel importación" aria-expanded="false">
                                          <i class="fa fa-file-upload text-red fileUpload-button text-180"></i>
                                          <input id="{{i}}subir-excel-estudiantes" style="display:none" type="file" class="form-control" (change)="subirPlanillaFiliacion($event,registro_profesor)" placeholder="Upload file" accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                                        </button>
                                      </ng-container>
                                      <a id="{{i}}listest" title="Ver lista estudiantes" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-warning btn-a-outline-warning btn-text-warning" href="javascript:void(0)" (click)="verListaEstudiantes(registro_profesor)">          
                                        <i class="fas fa-angle-double-right text-180"></i>        
                                      </a>      
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="col-md-5 col-12">
                    <div class="text-primary-d3 text-120 mb-2">
                      <table class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th colspan="3" class="text-center pr-0">Curso: {{registro_profesor_actual.curso}}</th>
                            </tr>
                            <tr>
                              <th class="text-center pr-0">Nº</th>
                              <th class="text-center pr-0">Estudiante</th>
                              <th class="text-center pr-0">
                                <div class="row">
                                  <div class="col-6">
                                    <a *ngIf="registro_profesor_actual.id_curso && !usuario.empresa.usar_estudiantes_colegio" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="crearNuevoEstudiante()">
                                      <i class="fa fa-plus text-blue text-180"></i>
                                    </a>
                                    <a *ngIf="registro_profesor_actual.id_curso" title="Imprimir Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="generarPdfDatosEstudiantes()">          
                                      <i class="fas fa-file-pdf text-180"></i>        
                                    </a>
                                  </div>
                                  <div class="col-6">
                                    <a class="text-secondary-d1" href="javascript:void(0)">          
                                      <i class="fa fa-cog text-105"></i>        
                                    </a>      
                                  </div>
                                </div>
                              </th>
                          </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let estudiante of registro_profesor_actual.estudiantes_vista; let i=index;">
                                <tr #cursos class="bgc-h-success-l3 jqgrow ui-row-ltr">
                                    <td>{{(i+1)}}</td>
                                    <td>{{estudiante.persona.nombre_completo}}</td>
                                    <td>
                                      <a title="Modificar Datos" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="modificarEstudiante(estudiante)">          
                                        <i class="fas fa-edit text-180"></i>        
                                      </a>
                                      <a *ngIf="!usuario.empresa.usar_estudiantes_colegio" title="Eliminar" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="abrirPopupConfirmacionEliminacionInscripcion(estudiante)">          
                                        <i class="fas fa-trash-alt text-180"></i>        
                                      </a>      
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div><!-- /.card -->
      </div>
    </div>
  </div>
</ng-template>

<ng-template #registro_curso_materia let-modal>
  <div class="modal-header">
		<div class="col-6 col-xs-6">
			<h4 class="widget-title">Registro Curso Materia</h4>
		</div>
		<div class="col-6 col-xs-6">
			<button class="btn btn-success" type="button" (click)="agregarRegistroProfesor()">
				<i class="ace-icon fa fa-arrow-down red2 align-top bigger-110"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary px-2">
            <div class="box-body">
                <div class="row">
                  <div class="col-12 col-sm-12">
                    <label>Nivel Escolar </label> 
                      <select (change)="obtenerCursosMateriasNivel(registro_profesor_actual.nivel_escolar)" [compareWith]="compararObjectos" class="form-control" required name="nivel_escolar" [(ngModel)]="registro_profesor_actual.nivel_escolar">
                        <option [ngValue]="null" [selected]="true">Seleccionar nivel escolar</option>
                        <option [ngValue]="nivel_escolar" *ngFor="let nivel_escolar of niveles_escolares">{{nivel_escolar.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-12 col-sm-12">
                    <label>Curso </label> 
                      <select [compareWith]="compararObjectos" class="form-control" required name="curso" [(ngModel)]="registro_profesor_actual.curso">
                        <option [ngValue]="null" [selected]="true">Seleccionar curso</option>
                        <option [ngValue]="curso" *ngFor="let curso of cursos; trackBy:trackByFunction">{{curso.nombre}}</option>
                      </select>
                  </div>
                  <div class="col-12 col-sm-12">
                    <label>Materia </label> 
                      <select [compareWith]="compararObjectos" class="form-control" required name="materia" [(ngModel)]="registro_profesor_actual.materia">
                        <option [ngValue]="null" [selected]="true">Seleccionar materia</option>
                        <option [ngValue]="materia" *ngFor="let materia of materias">{{materia.nombre}}</option>
                      </select>
                  </div>
				        </div>
            </div>
        </div>
	</div>
</ng-template>

<ng-template #importacion_estudiantes let-modal>
	<div class="modal-header">
		<div class="col-md-10 col-xs-10">
			<h4 class="widget-title">Filiación estudiantes: {{registro_profesor_actual.curso}}</h4>
		</div>
		<div class="col-md-2 col-xs-2">
			<button class="btn btn-primary" type="button" [disabled]="mensaje_importacion || estudiantes.length==0" (click)="guardarImportacionEstudiantes()">
				<i class="fa fa-save align-top bigger-110"></i>
			</button>&nbsp;
			<button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top bigger-110"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="card card-info card-outline">
			<div class="box box-primary">
				<div class="box-body">
          <div *ngIf="mensaje_importacion" class="alert bgc-red-l4 border-none border-l-4 brc-red-tp1 radius-0 text-dark-tp2">
            <h4 class="text-danger-d1 text-130">Oh no! Ocurrio un problema</h4>
            <i class="fas fa-frown mr-3 fa-2x text-orange-d1"></i> <span class="align-self-center">{{mensaje_importacion}}.</span>
          </div>
					<div class="table-fixed-header">
						<table class="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th>Nº</th>
                  <th>Código Rude</th>
                  <th>Apellido Paterno</th>
                  <th>Apellido Materno</th>
                  <th>Nombres</th>
                  <th>Género</th>
                  <th>Teléfono Fijo</th>
                  <th>Teléfono Móvil</th>
                  <th>Correo Electrónico</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let estudiante of estudiantes; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{estudiante.codigo_rude}}</td>
                  <td>{{estudiante.persona.apellido_paterno}}</td>
                  <td>{{estudiante.persona.apellido_materno}}</td>
                  <td>{{estudiante.persona.nombres}}</td>
                  <td>{{estudiante.persona.genero}}</td>
                  <td>{{estudiante.persona.telefono_fijo}}</td>
                  <td>{{estudiante.persona.telefono_movil}}</td>
                  <td>{{estudiante.persona.correo_electronico}}</td>
                </tr>
              </tbody>
            </table>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #datos_almacenes_sucursal let-modal>
  <div class="modal-header">
		<div class="col-6 col-xs-6">
			<h4 class="widget-title">Almacenes Sucursal: {{sucursal_modificacion.nombre}}</h4>
		</div>
		<div class="col-6 col-xs-6">
			<button class="btn btn-primary" type="button" (click)="guardarAlmacenesSucursal()">
				<i class="ace-icon fa fa-save red2 align-top text-180"></i>
      </button>&nbsp;
      <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
				<i class="ace-icon fa fa-times red2 align-top text-180"></i>
			</button>
		</div>
	</div>
	<div class="modal-body">
		<div class="box box-primary px-2" >
      <div class="box-body">
        <div class="row">
          <div class="col-6 col-xs-6">
            <h4 class="widget-title">Registro Almacen</h4>
          </div>
          <div class="col-6 col-xs-6">
            <h4 class="widget-title">Almacenes Registrados</h4>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-2 col-sm-2">
            <label>Nombre Almacen </label>&nbsp; 
            <input [(ngModel)]="almacen.nombre" placeholder="Nombre" type="text" class="form-control border-l-2" />
          </div>
          <div class="col-3 col-sm-3">
            <label>Número </label>&nbsp; 
            <input [(ngModel)]="almacen.numero" placeholder="Número" type="number" class="form-control border-l-2" />
          </div>
          <div class="col-1 col-sm-1">
            <button class="btn btn-primary" type="button" (click)="agregarAlmacen(almacen)">
							<i class="ace-icon fa fa-arrow-right red2 align-top bigger-110"></i>
						</button>
          </div>
          <div class="col-5 col-sm-5">
            <div class="table-fixed-header">
              <table class="table table-striped text-dark-m2 text-95 table-bordered table-hover">
                  <thead fix-head class="table-head-color sticky-nav">
                      <tr>
                        <th>Nº</th>
                        <th>Almacen</th>
                        <th>Número</th>
                        <th style="text-align: center;" data-field="tools">
                          <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                          <div class="fht-cell"></div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let almacen of sucursal_modificacion.almacenes let i=index;">
                        <tr *ngIf="!almacen.eliminado">
                            <td>{{i+1}}</td>
                            <td>{{almacen.nombre}}</td>
                            <td>{{almacen.numero}}</td>
                            <td>
                              <a (click)="removerAlmacen(almacen)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)">
                                <i class="fa fa-trash-alt"></i>
                              </a>
                            </td>
                        </tr>
                      </ng-container>
                    </tbody>
              </table>
            </div>
          </div>
        </div>    
      </div>
    </div>
	</div>
</ng-template>

<ng-template #llantas_notificacion_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Llantas</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover ">
          <thead class="bgc-primary-d1 text-white sticky-nav">
              <tr class="sticky-nav">
                  <th>Nº</th>
                  <th>Vehículo</th>
                  <th>Llanta</th>
                  <th>Kilometraje Vida</th>
                  <th>Kilometraje Actual</th>
                  <th>Kilometraje Restante</th>
                  <th>Marca</th>
                  <th>Tipo</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let llanta of notificacion.notificacion_llantas; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{llanta.placa}}</td>
                  <td>{{llanta.nombre}}</td>
                  <td>{{llanta.kilometraje_vida}}</td>
                  <td>{{llanta.kilometraje_actual}}</td>
                  <td>{{llanta.kilometraje_vida-llanta.kilometraje_actual}}</td>
                  <td>{{llanta.marca}}</td>
                  <td>{{llanta.tipo}}</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
</ng-template>

<ng-template #aceites_notificacion_modal let-modal>
  <div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Aceites</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
  <div class="modal-body">
    <div class="table-fixed-header">
      <table class="table table-striped table-bordered table-hover ">
          <thead class="bgc-primary-d1 text-white sticky-nav">
              <tr class="sticky-nav">
                  <th>Nº</th>
                  <th>Vehículo</th>
                  <th>Tipo de Aceite</th>
                  <th>Kilometraje Actual</th>
                  <th>Kilometraje Proximo Cambio</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let aceite of notificacion.notificacion_aceites; let i=index;">
                  <td>{{i+1}}</td>
                  <td>{{aceite.placa}}</td>
                  <td>{{aceite.tipo_aceite}}</td>
                  <td>{{aceite.kilometraje_actual}}</td>
                  <td>{{aceite.kilometraje_proximo_cambio}}</td>
              </tr>
          </tbody>
      </table>
    </div>
  </div>
</ng-template>