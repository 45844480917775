import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { InventariosService } from '../../services/inventarios/inventarios.service';
import { MovimientoInventarioService } from '../../services/movimiento-inventario/movimiento-inventario.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { ProductosService } from '../../services/productos/productos.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { Util } from 'src/app/utils/utils';
import { RegistroProductoComponent } from '../registro-producto/registro-producto.component';

declare const $:any;
declare const aplicarVisorImagenes:any;
declare const enfocarElemento:any;

@Component({
  selector: 'app-registro-movimiento-inventario',
  templateUrl: './registro-movimiento-inventario.component.html',
  styleUrls: ['./registro-movimiento-inventario.component.css']
})
export class RegistroMovimientoInventarioComponent extends BaseComponent implements OnInit {

  movimiento_inventario:any={detallesMovimientoInventario:[],clase:{}};
  detalleMovimientoInventario:any={producto:{}};
  inventariosDisponibleProducto:any[];
  sucursales:any[];
  almacenes:any[];
  sucursalesDiferente:any[];
  almacenesDiferente:any[];
  sucursalesUsuario:any;
  clases:any[]=[];
  sucursales_empresa:any;
  porcentaje:any;
  color:any;
  producto_busqueda:any;
  searching:any;
  searchFailed:any;

  aplicacion_producto:any;
  busqueda_codigo_barra:boolean=false;
  inhabilitar_sucursal:boolean=false;
  inhabilitar_almacen:boolean=false;
  inhabilitar_movimiento:boolean=false;
  nombre_corto_clase_ingreso:string=GlobalVariable.Dictionary.ING_INV_INICIAL;
  nombre_corto_clase_egreso:string=GlobalVariable.Dictionary.EGRE_BAJA;
  id_almacen_seleccionado:any=null;

  tipos_movimiento:any[]=[];
  nombre_corto_tipo_movimiento:string=GlobalVariable.Dictionary.MOV_ING;

  edicionproducto:NgbModalRef;

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();
  

  @BlockUI() blockUI: NgBlockUI;

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public modalService: NgbModal,
    public toastr: ToastrService,
    public empresaService:EmpresaService,
	public inventariosService:InventariosService,
	public productosService:ProductosService,
	public movimientoInventarioService:MovimientoInventarioService,
	public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

	async ngOnInit(): Promise<void> {
		this.aplicacion_producto= this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS)[0];
		this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
		await this.obtenerSucursalesEmpresa();
		
		this.sucursalesUsuario=Util.obtenerIdsSucursales(this.usuario);
		
		this.movimiento_inventario.id_empresa=this.usuario.id_empresa;
		this.movimiento_inventario.id_usuario=this.usuario.id;
		await this.obtenerTiposMovimientos();
		//this.movimiento_inventario.sucursal=this.sucursales.length==1?this.sucursales[0]:null;

		this.movimiento_inventario.sucursal=this.movimiento_inventario.sucursal?this.movimiento_inventario.sucursal:this.sucursales[0];
		await this.obtenerAlmacenesSucursalesDiferente(this.movimiento_inventario.sucursal.id);
		this.obtenerAlmacenesActividades(this.movimiento_inventario.sucursal.id);
		var fechaActual=new Date();
		this.movimiento_inventario.fechaTexto={
			year: fechaActual.getFullYear(), 
			month: (fechaActual.getMonth()+1),
			day:fechaActual.getDate()
		}	
		this.detalleMovimientoInventario={
			producto:{},centroCosto:{},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
			fechaVencimientoTexto:{
				year: fechaActual.getFullYear(), 
				month: (fechaActual.getMonth()+1),
				day:fechaActual.getDate()
			}	
		}
  	}

	async establecerClase(){
		let movimiento;
		if(this.movimiento_inventario.tipo.nombre_corto=="MOVING"){
			movimiento=this.clases.filter(e => e.nombre_corto == this.nombre_corto_clase_ingreso)[0];
		}else{
			movimiento=this.clases.filter(e => e.nombre_corto == this.nombre_corto_clase_egreso)[0];
		}
		this.movimiento_inventario.clase=movimiento;
  	}

	async obtenerTiposMovimientos(){
		this.blockUI.start();
		this.tipos_movimiento=[];
		await this.tipos_movimiento.push(await this.generalService.obtenerClases("MOVING").toPromise());
		await this.tipos_movimiento.push(await this.generalService.obtenerClases("MOVEGR").toPromise());
		this.movimiento_inventario.tipo=this.tipos_movimiento.filter(t => t.nombre_corto==this.nombre_corto_tipo_movimiento)[0];
		await this.establecerTipo();
		this.blockUI.stop();
	}

	async establecerTipo(){
		this.blockUI.start();
		if(this.movimiento_inventario.tipo.nombre_corto=="MOVING"){
			this.clases=this.movimiento_inventario.tipo.clases.filter(e => e.nombre_corto == GlobalVariable.Dictionary.ING_INV_INICIAL || e.nombre_corto == GlobalVariable.Dictionary.ING_AJUSTE || e.nombre_corto == GlobalVariable.Dictionary.ING_COMPRA || e.nombre_corto == GlobalVariable.Dictionary.ING_PRODUCCION || e.nombre_corto == GlobalVariable.Dictionary.ING_SOBRANTE);
		}else{
			this.clases=this.movimiento_inventario.tipo.clases.filter(e => e.nombre_corto == GlobalVariable.Dictionary.EGRE_AJUSTE  || e.nombre_corto == GlobalVariable.Dictionary.EGRE_BAJA  || e.nombre_corto == GlobalVariable.Dictionary.EGRE_CONSUMO  || e.nombre_corto == GlobalVariable.Dictionary.EGRE_PUNTO_VENTA  || e.nombre_corto == GlobalVariable.Dictionary.EGRE_VENTA_PERSONAL || e.nombre_corto == GlobalVariable.Dictionary.EGRE_MERMA || e.nombre_corto == GlobalVariable.Dictionary.EGRE_FALTA_INVENTARIO);
		}
		let transacciones=this.clases;
		if(this.usuario.empresa.usar_permisos_avanzados_usuarios){
			let configuracion_aplicacion_movimiento_inventario:any=await this.usuarioService.obtenerConfiguracionPaginaPorCodigo(this.usuario.id_empresa,GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_INVENTARIO,this.usuario.empresa.usar_permisos_avanzados_usuarios?this.usuario.id:0).toPromise();
			transacciones=configuracion_aplicacion_movimiento_inventario.datos.opciones_permisos.transacciones_asignadas.length==0?transacciones:[];
			for(let i=0;i<configuracion_aplicacion_movimiento_inventario.datos.opciones_permisos.transacciones_asignadas.length;i++){
				transacciones.push(this.clases.filter(cnf => cnf.id==configuracion_aplicacion_movimiento_inventario.datos.opciones_permisos.transacciones_asignadas[i])[0]);
			}
			let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.clases=es_usuario_administrador?this.clases:transacciones;
		}
		await this.establecerClase();
		this.blockUI.stop();
	}

  	async obtenerSucursalesEmpresa(){
    	this.sucursales_empresa=await this.empresaService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
  	}

	cerrarComponente(){
		this.alTerminar.emit();
	}

  agregarDetalleMovimientoInventario(detalleMovimientoInventario){
	if(detalleMovimientoInventario.producto.activar_inventario){
		if(this.movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_AJUSTE || this.movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO || this.movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_BAJA || this.movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_PRODUCCION || this.movimiento_inventario.clase.nombre_corto == GlobalVariable.Dictionary.EGRE_CONSUMO  || this.movimiento_inventario.clase.nombre_corto == GlobalVariable.Dictionary.EGRE_PUNTO_VENTA  || this.movimiento_inventario.clase.nombre_corto == GlobalVariable.Dictionary.EGRE_VENTA_PERSONAL){
			if(detalleMovimientoInventario.producto.activar_inventario && detalleMovimientoInventario.cantidad>detalleMovimientoInventario.inventario_disponible){
				this.toastr.error("Cantidad insuficiente en inventario!");
				return;
			  }
		  }
			if(detalleMovimientoInventario.fechaVencimientoTexto){
				detalleMovimientoInventario.fecha_vencimiento=Util.convertirObjetoAfecha(detalleMovimientoInventario.fechaVencimientoTexto);
			}
	
			let detalleVentaAAgregar=detalleMovimientoInventario;
			var detalleVentaProductoExistente=this.movimiento_inventario.detallesMovimientoInventario.filter(e => e.producto.id == detalleVentaAAgregar.producto.id);
			if(detalleVentaProductoExistente.length>0){
				detalleVentaProductoExistente=detalleVentaProductoExistente[0];
				detalleVentaProductoExistente.cantidad=detalleVentaProductoExistente.cantidad+detalleVentaAAgregar.cantidad;
				this.detalleMovimientoInventario=detalleVentaProductoExistente;
				this.calcularImporte();
				this.movimiento_inventario.detallesMovimientoInventario[this.movimiento_inventario.detallesMovimientoInventario.indexOf(detalleVentaProductoExistente)]=detalleVentaProductoExistente;
			}else{
				this.movimiento_inventario.detallesMovimientoInventario.push(detalleMovimientoInventario);
			}
	
			this.inventariosDisponibleProducto=[];
			this.sumarTotal();
			this.sumarTotalImporte();
			this.detalleMovimientoInventario={producto:{activar_inventario:true},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false}
			this.reiniciarDetalleMovimientoInventario();
	}else{
		this.toastr.warning("El producto tiene el inventario desactivado!");
	}
  }
  
  calcularImporte(){
		this.detalleMovimientoInventario.importe=Math.round((this.detalleMovimientoInventario.cantidad*this.detalleMovimientoInventario.costo_unitario)*1000)/1000;
		this.detalleMovimientoInventario.total=this.detalleMovimientoInventario.importe;
  }
  
  sumarTotal=function(){
		var sumaTotal=0;
		for(var i=0;i<this.movimiento_inventario.detallesMovimientoInventario.length;i++){
			sumaTotal=sumaTotal+this.movimiento_inventario.detallesMovimientoInventario[i].total;
		}		
		this.movimiento_inventario.total=Math.round((sumaTotal)*1000)/1000;
  }
  
  sumarTotalImporte(){
		var sumaImporte=0;
		for(var i=0;i<this.movimiento_inventario.detallesMovimientoInventario.length;i++){
			sumaImporte=sumaImporte+this.movimiento_inventario.detallesMovimientoInventario[i].importe;
		}		
		this.movimiento_inventario.importe=Math.round((sumaImporte)*1000)/1000;
  }
  
  establecerClaseSeleccion(){
		this.reiniciarDetalleMovimientoInventario();
  }
  
  async obtenerAlmacenesSucursalesDiferente(idSucursalOrigen){
		this.movimiento_inventario.sucursalDestino=null;
		await this.obtenerAlmacenes(idSucursalOrigen);
		this.obtenerSucursalesDiferente(idSucursalOrigen);
		this.reiniciarDetalleMovimientoInventario();
  }
  
  async obtenerAlmacenes(idSucursal){
	let datos_sucursal:any=await this.empresaService.obtenerSucursal(idSucursal).toPromise();
	this.almacenes=datos_sucursal.almacenes;
	if(this.usuario.empresa.usar_almacenes_usuario){
		let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
		this.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
	}
	if(this.configuracion_pagina.datos.opciones_permisos.almacen_defecto!=null){
		this.movimiento_inventario.almacen=this.configuracion_pagina.datos.opciones_permisos.almacen_defecto;
	}else{
		this.movimiento_inventario.almacen=(this.id_almacen_seleccionado?this.almacenes.filter(a => a.id==this.id_almacen_seleccionado)[0]:(this.almacenes.length>0?this.almacenes[0]:null));
	}
  }
  
  obtenerSucursalesDiferente(idSucursal){
		var sucursalOrigen=this.sucursales_empresa.filter(e => e.id == idSucursal)[0];
		this.sucursalesDiferente=this.sucursales_empresa.filter(e => e.id != idSucursal);
		if(sucursalOrigen.almacenes.length>1){
			this.sucursalesDiferente.push(sucursalOrigen);
    }
    //this.almacenesDiferente=this.sucursalesDiferente.almacenes;
  }
  
  obtenerAlmacenesActividades(idSucursal){
		this.obtenerAlmacenes(idSucursal);
		this.reiniciarDetalleMovimientoInventario();
  }
  
  establecerProducto(event){
	  let producto=event.item;
		producto.tipoProducto=producto['tipoProducto']==null?{id:producto['tipoProducto.id'],nombre:producto['tipoProducto.nombre'],nombre_corto:producto['tipoProducto.nombre_corto']}:producto.tipoProducto;
		this.inventariosService.obtenerInventariosProducto(producto.id,this.movimiento_inventario.almacen.id).subscribe(async (inventarios:any[])=>{
			if(this.usuario.empresa.usar_productos_sucursal){
				let producto_sucursal:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,producto.id,this.movimiento_inventario.sucursal.id).toPromise();
				producto.activar_inventario=producto_sucursal.productos_sucursal[0].activar_inventario;
				producto.activar_vencimiento=producto_sucursal.productos_sucursal[0].activar_vencimiento;
				producto.producto_sucursal=producto_sucursal;
			}
			producto.inventarios=inventarios;
			this.inventariosDisponibleProducto=[];
			this.inventariosDisponibleProducto.push({id:0,fecha_vencimiento:"TODOS",fechaVencimientoTexto:"TODOS"});
			this.inventariosDisponibleProducto=this.inventariosDisponibleProducto.concat(producto.inventarios);
			var inventarioDisponible=this.obtenerInventarioTotal(producto);
			this.detalleMovimientoInventario={
				producto:producto,precio_unitario:producto.precio_unitario,
				activar_inventario:producto.activar_inventario,
				costo_unitario:(producto.costo_unitario==null || producto.costo_unitario==undefined)?1:producto.costo_unitario,
				inventarioProducto:this.inventariosDisponibleProducto[0],
				inventario_disponible:inventarioDisponible,
				costos:producto.activar_inventario?producto.inventarios:[],
				cantidad:1,descuento:producto.descuento,recargo:0,ice:0,excento:0,
				tipo_descuento:(producto.descuento>0?true:false),tipo_recargo:false,
				fechaVencimientoTexto:this.movimiento_inventario.fechaTexto
			}

			if(!this.usuario.empresa.usar_vencimientos || !producto.activar_vencimiento){
				this.detalleMovimientoInventario.fechaVencimientoTexto=null;
			}
			
			this.colorearInventarioDisponible(inventarioDisponible,producto);
			this.calcularImporte();
			this.enfocarElemento({which:13},"cantidad");
			setTimeout(()=>{
				aplicarVisorImagenes("imagen-producto-vista");
			}, 200);
    });
  }
  
  obtenerInventarioTotal(producto){
		var cantidadTotal = 0;
		if(producto.activar_inventario){
			for(var i = 0; i < producto.inventarios.length; i++){
				cantidadTotal += (producto.inventarios[i].cantidad);
			}
			for(var j=0;j<this.movimiento_inventario.detallesMovimientoInventario.length;j++){
				if(this.movimiento_inventario.detallesMovimientoInventario[j].producto.id==producto.id){
					cantidadTotal=cantidadTotal-this.movimiento_inventario.detallesMovimientoInventario[j].cantidad;
				}
			}
		}else{
			cantidadTotal=500000;
		}
		return cantidadTotal;
  }
  
  colorearInventarioDisponible(inventarioDisponible,producto){
		if(inventarioDisponible==0){
			this.porcentaje="100";
			this.color="red";
		}else if(inventarioDisponible>((producto.inventario_minimo*3)+1)){
			this.porcentaje="100";
			this.color="green";
		}else if(inventarioDisponible>((producto.inventario_minimo*2)+1)){
			this.porcentaje="75";
			this.color="green";
		}else if(inventarioDisponible>((producto.inventario_minimo*1.5)+1)){
			this.porcentaje="50";
			this.color="green"
		}else if(inventarioDisponible==(producto.inventario_minimo+1)){
			this.porcentaje="38";
			this.color="yellow";
		}else if(inventarioDisponible==producto.inventario_minimo){
			this.porcentaje="25";
			this.color="red";
		}else if(inventarioDisponible<producto.inventario_minimo && inventarioDisponible>0){
			this.porcentaje="12";
			this.color="red";
		}
  }
  
  async obtenerAlmacenesDiferente(sucursal_destino){
	  if(sucursal_destino){
		var idSucursalDestino=sucursal_destino.id;
		let datos_sucursal:any=await this.empresaService.obtenerSucursal(idSucursalDestino).toPromise();
		this.almacenesDiferente=datos_sucursal.almacenes.filter(e => e.id!=this.movimiento_inventario.almacen.id);;
		this.movimiento_inventario.almacenDestino=this.almacenesDiferente.length>0?this.almacenesDiferente[0]:null;
		this.detalleMovimientoInventario={producto:{activar_inventario:true},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false}
		this.movimiento_inventario.detallesMovimientoInventario=[];
	  }
	}

	buscarProducto = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
	  switchMap( (termp) =>  this.productosService.buscarProducto(this.usuario.id_empresa,termp)),
	  catchError(() => {
		this.searchFailed = true;
		return of([]);
	  })  ,
      tap(() => this.searching = false)
	)
	
	  formatterProducto = (x: any) => (x.nombre);

	reiniciarDetalleMovimientoInventario(){
		this.producto_busqueda=null;
		if(this.detalleMovimientoInventario.producto && this.detalleMovimientoInventario.producto.id){
			this.enfocarElemento({which:13},"id_producto");
		}else if(this.detalleMovimientoInventario.combo && this.detalleMovimientoInventario.combo.id){
			this.enfocarElemento({which:13},"id_combo");
		}else{
			this.enfocarElemento({which:13},"id_descripcion");
		}
		this.detalleMovimientoInventario={
			producto:{activar_inventario:true},cantidad:1,descuento:0,recargo:0,ice:0,excento:0,tipo_descuento:false,tipo_recargo:false,
			fechaVencimientoTexto:this.movimiento_inventario.fechaTexto
		}
	}

	enfocarElemento(keyEvent,id_elemento){
		if (keyEvent.which === 13){
		  enfocarElemento(id_elemento);
		}
	  }

	actualizarAtributoProducto(producto,atributo,valor){
		this.blockUI.start();
		this.detalleMovimientoInventario.activar_inventario=valor;
		if(this.usuario.empresa.usar_productos_sucursal){
			let producto_sucursal:any=producto.producto_sucursal;
			producto_sucursal.productos_sucursal[0][atributo]=valor;
			this.productosService.actualizarProductoSucursal(producto_sucursal).subscribe((res:any)=>{
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}else{
			let datos:any={
				atributo:atributo,
				valor:valor
			};
			if(producto){
				datos.producto=producto;
			}else{
				datos.id_empresa=this.usuario.id_empresa;
			}
			this.productosService.actualizarAtributoProducto(datos).subscribe((res:any) => {
				this.getItems();
				this.toastr.success(res.mensaje);
				this.blockUI.stop();
			});
		}

		if(atributo=="activar_inventario" && valor){
			var inventarioDisponible=this.obtenerInventarioTotal(producto);
			this.detalleMovimientoInventario.inventario_disponible=inventarioDisponible;
		}
	}

	interceptarTecla(keyEvent,elemento,esEnfocar){
		if (keyEvent.which === 13){
			if(esEnfocar){
				$("#"+elemento).focus();
			}else{
				setTimeout(()=>{
					$('#'+elemento).trigger('click');
				  }, 0);
			}
		}
	}
	
	eliminarDetalleMovimiento(detalleMovimiento){
		this.movimiento_inventario.detallesMovimientoInventario.splice(this.movimiento_inventario.detallesMovimientoInventario.indexOf(detalleMovimiento),1);
		this.sumarTotal();
		this.sumarTotalImporte();
	}

	validarMovimientoInventario(){
		let res=true;
		if(this.movimiento_inventario.clase.nombre_corto==GlobalVariable.Dictionary.EGRE_TRASPASO){
			if(!this.movimiento_inventario.sucursalDestino || !this.movimiento_inventario.almacenDestino){
				res=res && false;
		  		this.toastr.error("Debe especificar la sucursal y el almacen de destino!");
			}
		}
		if(!this.movimiento_inventario.fechaTexto || !this.movimiento_inventario.fechaTexto.year || !this.movimiento_inventario.fechaTexto.month || !this.movimiento_inventario.fechaTexto.day){
		  res=res && false;
		  this.toastr.error("Debe especificar la fecha completa para realizar el movimiento!");
		}
		if(this.movimiento_inventario.detallesMovimientoInventario.length==0){
		  res=res && false;
		  this.toastr.error("Debe agregar al menos un item al movimiento!");
		}
		return res;
	  }

	guardarMovimientoInventario(){
		if(this.validarMovimientoInventario()){
			var tiempoActual=new Date();
			this.movimiento_inventario.fecha=Util.convertirObjetoAfecha(this.movimiento_inventario.fechaTexto);
			this.movimiento_inventario.fecha.setHours(tiempoActual.getHours());
			this.movimiento_inventario.fecha.setMinutes(tiempoActual.getMinutes());
			this.blockUI.start();
			if(this.movimiento_inventario.id){
			}else{
				this.movimientoInventarioService.guardarMovimientoInventario(this.movimiento_inventario).subscribe((res:any)=>{
					this.blockUI.stop();
					if(res.tiene_error){
						if(res.stack){
							this.toastr.error(res.stack.substring(0,500));
						}
						this.toastr.error(res.mensaje);
					}else{
						this.toastr.success(res.mensaje);
						this.alTerminar.emit(res);
					}
				});
			}
		}
	}

	activarDesactivarBusquedaCodigoBarra(){
		this.busqueda_codigo_barra=!this.busqueda_codigo_barra;
	}

	agregarNuevoProducto(){
		this.edicionproducto = this.modalService.open(RegistroProductoComponent, {windowClass : "wizard-edicion-producto",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicionproducto.componentInstance.producto = {};
		this.edicionproducto.componentInstance.usuario = this.usuario;
		this.edicionproducto.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		
		this.edicionproducto.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.establecerProducto({item:$e.producto});
			}
			this.edicionproducto.close();
		});
	}

	buscarCodigoBarraProducto(evento:any,codigo_barra:any){
		let compra=this;
		if (evento.which === 13){
		  this.productosService.buscarProducto(this.usuario.id_empresa,codigo_barra).subscribe((results:any[]) => {
			if(results.length>=1){
			  compra.establecerProducto({item:results[0]});
			  Util.enfocarElemento('compra_costo_unitario');
			}
		  });
		}
	}

}
