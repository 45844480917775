<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Tarjetas de Jugador</span>
    </div>
    <div class="col-md-4 col-xs-4">
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
  <div class="row">
    <div class="col-2">
      <label>Jugador</label>
    </div>
    <div class="col-6">
      <ng-container *ngIf="impresion_tarjetas_jugador.jugador.id">
        <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{impresion_tarjetas_jugador.jugador.persona.nombre_completo}}</span><br>
      </ng-container>
      <input id="jugador_texto" name="jugador_texto" *ngIf="!impresion_tarjetas_jugador.jugador.id"
      [inputFormatter]="formatterJugador" [resultTemplate]="rt" (selectItem)="establecerJugador($event)"
      type="text" class="form-control" [(ngModel)]="impresion_tarjetas_jugador.jugador_texto" 
      [ngbTypeahead]="buscarJugadorEmpresa" placeholder="Buscar jugador" autocomplete="off"
      />
      <ng-template #rt let-r="result" let-t="term">
        <ngb-highlight [result]="(r.persona.nombre_completo+' - '+r.equipo.campeonato.nombre)" [term]="t"></ngb-highlight>
      </ng-template>
    </div>
    <div class="col-4 p-0">
      <button *ngIf="impresion_tarjetas_jugador.jugador.id" (click)="reiniciarJugador()" class="btn btn-danger"  >
        <i class="fa fa-trash text-110 mr-1"></i>
      </button>&nbsp;
      <button (click)="agregarJugadorImpresion()" class="btn btn-success"  >
        <i class="fa fa-plus text-110 mr-1"></i>
      </button>&nbsp;
      <button *ngIf="impresion_tarjetas_jugador.lista_jugadores.length>0" (click)="generarTarjetaIdentificacion(impresion_tarjetas_jugador.lista_jugadores)" class="btn btn-danger">
        <i class="fa fa-file-pdf text-110 mr-1"></i>
      </button>
    </div>
  </div>
  <hr>
  <div class="row">
    <table *ngIf="impresion_tarjetas_jugador.lista_jugadores.length>0" class="table text-dark-m2 text-95 table-bordered table-hover table-striped table-responsive" id="table">
      <thead class="bgc-white text-grey text-uppercase text-80">
        <tr>
          <th>N°</th>
          <th class="center">
            <a href="javascript:void(0)">¿Con carnet? 
              <span id="publicar_panel" class="sort fa fa-sort icon-only"></span>
            </a>
          </th>
          <th class="center">Código</th>
          <th class="center">Foto</th>
          <th class="center">Nombre</th>
          <th class="center">Campeonato</th>
          <th class="center">Disciplina</th>
          <th class="center">Categoria</th>
          <th class="center">Equipo</th>
          <th style="text-align: center;" data-field="tools">
            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
            <div class="fht-cell"></div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr #campeonatos *ngFor="let jugador of impresion_tarjetas_jugador.lista_jugadores; let i=index;">
          <td>
            {{(i+1)+(this.itemsPerPage*(this.currentPage-1))}}
          </td>
          <td>
            <label>
              <input (change)="actualizarAtributoJugador(jugador,'con_carnet',jugador.con_carnet)" name="switch-field-1" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="jugador.con_carnet"/>
            </label>
          </td>
          <td>
              {{jugador.codigo}}
          </td>
          <td >
            <img width="50" height="50" alt="sin imagen" src="{{rest_server+jugador.persona.imagen}}" />
          </td>
          <td>
              {{jugador.persona.nombre_completo}}
          </td>
          <td>
              {{jugador.equipo.campeonato.nombre}}
          </td>
          <td>
            {{jugador.equipo.disciplina.nombre}}
        </td>
          <td>
            {{jugador.equipo.categoria.nombre}}
        </td>
          <td>
            {{jugador.equipo.nombre}}
            </td>
          <td style="text-align: center;">
            <div class="action-buttons">        
              <a class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="eliminarJugadorImpresion(jugador)">          
                <i class="fa fa-trash-alt text-105"></i>        
              </a>      
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>