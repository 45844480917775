import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { Router } from '@angular/router';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { GeneralService } from '../../../base/services/general/general.service';
import { ProductosService } from '../../services/productos/productos.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { ExcelService } from '../../../base/services/excel/excel.service';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import * as XLSX from 'xlsx';
import { NgbModalRef, NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../../base/components/popup-confirmacion/popup-confirmacion.component';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { VecinosService } from '../../../neighborhood/services/vecinos/vecinos.service';
import { RegistroProductoComponent } from '../registro-producto/registro-producto.component';
import { MovimientoProductoComponent } from '../movimiento-producto/movimiento-producto.component';
import { Util } from 'src/app/utils/utils';
import { EmpresaService } from '../../../base/services/empresas/empresa.service';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';
import { ConfiguracionAplicacionProductosComponent } from 'src/app/base/components/configuracion-aplicacion-productos/configuracion-aplicacion-productos.component';
import { ImpresionCodigosBarrasComponent } from '../impresion-codigos-barras/impresion-codigos-barras.component';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { ResultadoLaboratorioComponent } from 'src/app/medicina/components/resultado-laboratorio/resultado-laboratorio.component';

declare const resaltarPestañaMenu:any;
declare const aplicarVisorImagenes:any;
declare const aplicarCongeladorCabecera:any;
declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent extends BaseComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  configuracion_pagina:any;
  rest_server=GlobalVariable.SERVER_URL;
  global_variable=GlobalVariable;

  tipos_productos:any[];
  producto_sucursal:any;
  productos:any[]=[];
  productos_relacion:any[]=[];
  productos_servicios_sin:any[]=[];
  producto_servicio_sin:any={};
  productos_importacion:any[];
  precios_productos:any[]=[];
  tipo_producto_precio:any;
  filtro:any;
  tabla_producto_general:any={publicar_panel:false,activar_inventario:false,activar_vencimiento:false};
  tipo_grupo:any;
  tipo_subgrupo:any;
  producto:any;
  sin_unidades_medidas:any[]=[];

  searching:any;
  searchFailed:any;
  vecino:any={acciones_agua:[{categoria:{}}]};
  mensaje_importacion:any;
  suplemento_producto:any={cantidad_maxima_pedido:1};
  suplementos_producto:any[]=[];

  productosImportacion:NgbModalRef;
  @ViewChild('importacionproductos')
  private importacionproductos: TemplateRef<any>;

  lista_productos_sin:NgbModalRef;
  @ViewChild('lista_productos_sin')
  private lista_productos_sin_ref: TemplateRef<any>;

  relacion_productos_sin:NgbModalRef;
  @ViewChild('relacion_productos_sin')
  private relacion_productos_sin_ref: TemplateRef<any>;

  importacion_productos_precios:NgbModalRef;
  @ViewChild('importacion_productos_precios')
  private importacion_productos_precios_ref: TemplateRef<any>;

  modal_registro_producto_sucursal:NgbModalRef;
  @ViewChild('modal_registro_producto_sucursal')
  private modal_registro_producto_sucursal_ref: TemplateRef<any>;

  modal_registro_ingredientes:NgbModalRef;
  @ViewChild('modal_registro_ingredientes')
  private modal_registro_ingredientes_ref: TemplateRef<any>;

  edicionproducto:NgbModalRef;
  modificacionPruebaLaboratorio:NgbModalRef;
  kardexProducto:NgbModalRef;

  @ViewChildren('productos') productos_lectura: QueryList<any>;

  popupConfirmacion:NgbModalRef;

  constructor(private router: Router,
	public generalService:GeneralService,
	public persistenciaService:PersistenciaService,
	private productosService:ProductosService,
	public usuarioService:UsuarioService,
	public excelService:ExcelService,
	public modalService: NgbModal,
	private toastr: ToastrService,
	private vecinoService:VecinosService,
	private empresaService:EmpresaService,
	private pdfService:PdfService) { 
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  	ngOnInit(): void {
		this.init();
		this.buscarAplicacion(this.router.url.substring(1));
		this.configuracion_pagina={};
		this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_PRODUCTOS);
		this.filter={
			id_empresa:this.usuario.id_empresa,
			grupo: GlobalVariable.Dictionary.SELECCION_TODOS,//this.grupos_filtro[0],
			subgrupo:GlobalVariable.Dictionary.SELECCION_TODOS,//this.subgrupos_filtro[0],
			grupos_filtro:[GlobalVariable.Dictionary.SELECCION_TODOS],
			subgrupos_filtro:[GlobalVariable.Dictionary.SELECCION_TODOS],
			sucursales:Util.obtenerSucursalesUsuario(this.usuario),
			sucursal: Util.obtenerSucursalesUsuario(this.usuario)[0],
			almacenes:[],
			almacen: {},
		}

		this.obtenerAlmacenesSucursal(this.filter.sucursal);

    //this.grupos_filtro=[{nombre:"TODOS",id:0}];
		//this.subgrupos_filtro=[{nombre:"TODOS",id:0}];
		this.obtenerTipoProducto();
		this.obtenerProductos();
		//this.obtenerSucursales();
		this.obtenerGruposProductosEmpresa();
		this.obtenerSubGruposProductosEmpresa();
		/*if(this.usuario.empresa.usar_facturacion){
			this.obtenerListaProductosSin();
		}*/

		/*this.tabla_producto_general={
			publicar_panel:false,
			actilet_inventario:false
		}*/

		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			this.obtenerSinUnidadesMedida();
			this.obtenerListaProductosSin();

		}
  }

	ngAfterViewInit() {
		//aplicarScriptPaginaProductos();	
		resaltarPestañaMenu(this.router.url.substring(1)+"1");
		this.productos_lectura.changes.subscribe(t => {
			aplicarVisorImagenes("imagen-producto-vista");
		});
		this.obtenerTextosEmpresa();
	}

	obtenerTipoProducto(){
		this.blockUI.start();
		this.generalService.obtenerClases("TPS").subscribe((entidad:any) => {
			this.tipos_productos=entidad.clases;
			this.blockUI.stop();
		});
	}
  
  	obtenerProductos(){
		this.column = "codigo";
		this.getSearch(this.text_search,null);
	}

	async obtenerSinUnidadesMedida(){
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			let entidad:any=await this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.SIN_UNIDADES_MEDIDA).toPromise();
			this.sin_unidades_medidas=entidad.clases;
		}
	}
	
	getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.productosService.obtenerListaProductos(this).subscribe((dato:any) => {
			this.setPages(dato.paginas);
			this.productos=dato.productos;
			for(let i=0;i<this.productos.length;i++){
				this.productos[i].publicar_tienda=this.productos[i].publicar_tienda==1?true:false;
				this.productos[i].publicar_panel=this.productos[i].publicar_panel==1?true:false;
				this.productos[i].activar_inventario=this.productos[i].activar_inventario==1?true:false;
				this.productos[i].activar_vencimiento=this.productos[i].activar_vencimiento==1?true:false;
			}
			this.blockUI.stop();
			/*$timeout(function(){
				aplicarVisorImagenes("imagen-producto-vista");
				aplicarReordenamientoTabla(this.idTablaProductos);
			},1000);*/
		});
	}

	async descargarExcelEjemploImportacion () {
		let data=[];
		let cabecera=[];
		cabecera.push("CÓDIGO");
		cabecera.push("NOMBRE");
		if(this.usuario.empresa.usar_ventas || this.usuario.empresa.usar_cotizaciones){
			cabecera.push("UNID. MED.");
			cabecera.push("PRECIO UNITARIO");
			cabecera.push("DESCRIPCIÓN");
			cabecera.push("CÓDIGO DE FABRICA");
		}
		cabecera.push("GRUPO");
		cabecera.push("SUBGRUPO");
		if(this.usuario.empresa.usar_inventario_minimo){
			cabecera.push("INV. MÍNIMO");
		}
		if(this.usuario.empresa.usar_inventario){
			cabecera.push("COSTO U.");
			cabecera.push("CANTIDAD");
			if(this.usuario.empresa.usar_vencimientos){
				cabecera.push("FECHA VENCIMIENTO");
				cabecera.push("LOTE");
				cabecera.push("REG. SANITARIO");
				cabecera.push("GRUPO 3");
			}
		}
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			cabecera.push("CODIGO PRODUCTO S.I.N.");
		}
		if(this.usuario.empresa.usar_medicina_dietas){
			cabecera.push("UND-MD");
			cabecera.push("MD-Kcal");
			cabecera.push("MD-Hum(%)");
			cabecera.push("MD-Prot(g)");
			cabecera.push("MD-Lip.(mg)");
			cabecera.push("MD-Glu.(g)");
			cabecera.push("MD-Fib.(mg)");
			cabecera.push("MD-Ca.(mg)");
			cabecera.push("MD-P.(mg)");
			cabecera.push("MD-Fe.(mg)");
			cabecera.push("MD-No.(mg)");
			cabecera.push("MD-k.(mg)");
			cabecera.push("MD-Zn.(mg)");
			cabecera.push("MD-A.(mcg)");
			cabecera.push("MD-B1(mg)");
			cabecera.push("MD-B2(mg)");
			cabecera.push("MD-PP(mg)");
			cabecera.push("MD-C(mg)");
		}
		if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
			cabecera.push(null);
			cabecera.push(null);
			cabecera.push(null);
			cabecera.push("UNIDADES DE MEDIDAS S.I.N.");
			cabecera.push("CODIGOS PRODUCTOS S.I.N.");
			cabecera.push("DESCRIPCION PRODUCTOS S.I.N.");
		}
		//data.push(cabecera);
		let totalCosto = 0, i=0;
		for (i = 1; i <= 14; i++) {
			let columns = [];
			columns.push("CI"+i);
			columns.push("PRODUCTO "+i);
			if(this.usuario.empresa.usar_ventas || this.usuario.empresa.usar_cotizaciones){
				columns.push("UND");
				columns.push(i*2.3);
				columns.push("PRODUCTO "+i);
				columns.push("33303305845");
			}
			columns.push("GRUPO");
			columns.push("SUBGRUPO");
			if(this.usuario.empresa.usar_inventario_minimo){
				columns.push("3");
			}
			if(this.usuario.empresa.usar_inventario){
				columns.push("");
				columns.push("");
				if(this.usuario.empresa.usar_vencimientos){
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
				}
			}
			if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
				columns.push("");
			}
			if(this.usuario.empresa.usar_medicina_dietas){
				columns.push("100");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
				columns.push("5.8");
			}
			if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(this.sin_unidades_medidas[i-1].nombre);
				columns.push(this.productos_servicios_sin[i-1]?this.productos_servicios_sin[i-1].codigo_producto_servicio:null);
				columns.push(this.productos_servicios_sin[i-1]?this.productos_servicios_sin[i-1].descripcion:null);
			}
			data.push(columns);
		}

		let longitud_larga=this.productos_servicios_sin.length>this.sin_unidades_medidas.length?this.productos_servicios_sin.length:this.sin_unidades_medidas.length;

		for (let j = i; j <=longitud_larga; j++) {
			let columns = [];
			columns.push(null);
			columns.push(null);
			if(this.usuario.empresa.usar_ventas || this.usuario.empresa.usar_cotizaciones){
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
			}
			columns.push(null);
			columns.push(null);
			if(this.usuario.empresa.usar_inventario_minimo){
				columns.push(null);
			}
			if(this.usuario.empresa.usar_inventario){
				columns.push(null);
				columns.push(null);
				if(this.usuario.empresa.usar_vencimientos){
					columns.push("");
					columns.push("");
					columns.push("");
					columns.push("");
				}
			}
			if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
				columns.push("");
			}
			if(this.usuario.empresa.usar_medicina_dietas){
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(null);
			}
			if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
				columns.push(null);
				columns.push(null);
				columns.push(null);
				columns.push(this.sin_unidades_medidas[j-1].nombre);
				columns.push(this.productos_servicios_sin[j-1]?this.productos_servicios_sin[j-1].codigo_producto_servicio:null);
				columns.push(this.productos_servicios_sin[j-1]?this.productos_servicios_sin[j-1].descripcion:null);
			}
			data.push(columns);
		}

		let reportData = {
			title: "EJEMPLO-DATOS-PRODUCTOS",
			data: data,
			headers: cabecera
		}

		this.excelService.descargarFormatoProdcutosExcel(reportData,"EJEMPLO-DATOS-PRODUCTOS","PRODUCTOS");
	}

	subirExcelProductos(event){
		this.blockUI.start();
		//this.mensaje_importacion=null;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
				let productos=[],sumatoria=0;
				me.mensaje_importacion="";
				let conceptos_precios=[];
				if(me.usuario.empresa.usar_precios_productos){
					let datos_conceptos_precios=worksheet['D'+1]!=undefined && worksheet['D'+1]!=""?worksheet['D'+1].v.toString():null;
					console.log(datos_conceptos_precios.split("-"));
					let datos_conceptos=datos_conceptos_precios.split("-");
					for(let j=0;j<datos_conceptos.length;j++){
						conceptos_precios.push(datos_conceptos[j]);
					}
				}
				do {
					let producto:any={};
					producto.publicar_tienda=me.usuario.empresa.usar_pedidos_en_linea;
					producto.publicar_panel=me.usuario.empresa.usar_panel;
					producto.activar_inventario=me.usuario.empresa.usar_inventario;
					producto.activar_vencimiento=me.usuario.empresa.usar_vencimientos;
					producto.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
					producto.nombre=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
					if(me.usuario.empresa.usar_ventas || me.usuario.empresa.usar_cotizaciones){
						let celda_unidad_medida=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
						producto.unidad_medida=celda_unidad_medida;
						producto.sin_unidad_medida=me.sin_unidades_medidas.filter(g => g.nombre==celda_unidad_medida).length>0?me.sin_unidades_medidas.filter(g => g.nombre==celda_unidad_medida)[0]:null;
						if(me.usuario.empresa.usar_precios_productos){
							producto.precios=[];
							let datos_precio_unitario=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
							console.log(datos_precio_unitario.split("-"));
							let precios_producto=datos_precio_unitario.split("-");
							for(let j=0;j<precios_producto.length;j++){console.log(parseFloat(precios_producto[j].replace(',', '.')));
								if(precios_producto[j]!=undefined && precios_producto[j]!=""){
									producto.precios.push({concepto:conceptos_precios[j],precio_unitario:parseFloat(precios_producto[j].replace(',', '.')),por_defecto:j+1==precios_producto.length});
								}
							}
							producto.precio_unitario=producto.precios[0].precio_unitario;
						}else{
							producto.precio_unitario=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?parseFloat(worksheet['D'+row].v.toString()):null;
						}
						producto.descripcion=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?worksheet['E'+row].v.toString():null;
						producto.codigo_fabrica=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?worksheet['F'+row].v.toString():null;
						producto.grupo=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?worksheet['G'+row].v.toString():null;
						producto.subgrupo=worksheet['H'+row]!=undefined && worksheet['H'+row]!=""?worksheet['H'+row].v.toString():null;
						if(me.usuario.empresa.usar_inventario_minimo){
							producto.inventario_minimo=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?parseInt(worksheet['I'+row].v.toString()):0;
							if(me.usuario.empresa.usar_inventario){
								producto.costo_unitario=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?parseFloat(worksheet['J'+row].v.toString().replace(',','.')):0;
								producto.cantidad=worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?parseFloat(worksheet['K'+row].v.toString().replace(',','.')):0;
								sumatoria=sumatoria+(producto.costo_unitario*producto.cantidad);
								if(me.usuario.empresa.usar_vencimientos){
									producto.fecha_vencimiento=me.leerFechaVencimiento(worksheet,row,'L');
									producto.lote = worksheet['M' + row] != undefined && worksheet['M' + row] != "" ? worksheet['M' + row].v.toString() : null;
									producto.registro_sanitario = worksheet['N' + row] != undefined && worksheet['N' + row] != "" ? worksheet['N' + row].v.toString() : null;
									producto.grupo_3 = worksheet['O' + row] != undefined && worksheet['O' + row] != "" ? worksheet['O' + row].v.toString() : null;
									if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
										producto.codigo_producto_sin=worksheet['P'+row]!=undefined && worksheet['P'+row]!=""?worksheet['P'+row].v.toString():null;
										producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
									}
								}else{
									if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
										producto.codigo_producto_sin=worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?worksheet['L'+row].v.toString():null;
										producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
									}
								}
							}else{
								if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
									producto.codigo_producto_sin=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?worksheet['J'+row].v.toString():null;
									producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
								}
							}
						}else{
							if(me.usuario.empresa.usar_inventario){
								producto.costo_unitario=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?parseFloat(worksheet['I'+row].v.toString().replace(',','.')):0;
								producto.cantidad=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?parseFloat(worksheet['J'+row].v.toString().replace(',','.')):0;
								sumatoria=sumatoria+(producto.costo_unitario*producto.cantidad);
								if(me.usuario.empresa.usar_vencimientos){
									producto.fecha_vencimiento=me.leerFechaVencimiento(worksheet,row,'K');
									producto.lote = worksheet['L' + row] != undefined && worksheet['L' + row] != "" ? worksheet['L' + row].v.toString() : null;
									producto.registro_sanitario = worksheet['M' + row] != undefined && worksheet['M' + row] != "" ? worksheet['M' + row].v.toString() : null;
									producto.grupo_3 = worksheet['N' + row] != undefined && worksheet['N' + row] != "" ? worksheet['N' + row].v.toString() : null;
									if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
										producto.codigo_producto_sin=worksheet['O'+row]!=undefined && worksheet['O'+row]!=""?worksheet['O'+row].v.toString():null;
										producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
									}
								}else{
									if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
										producto.codigo_producto_sin=worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?worksheet['K'+row].v.toString():null;
										producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
									}
								}
							}else{
								if(me.usuario.empresa.usar_facturacion_computarizada_en_linea){
									producto.codigo_producto_sin=worksheet['I'+row]!=undefined && worksheet['I'+row]!=""?worksheet['I'+row].v.toString():null;
									producto.producto_sin=me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin).length>0?me.productos_servicios_sin.filter(g => g.codigo_producto_servicio==producto.codigo_producto_sin)[0]:null;
								}
							}
						}
					}else{
						producto.grupo=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
						producto.subgrupo=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
						if(me.usuario.empresa.usar_inventario_minimo){
							producto.inventario_minimo=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?parseInt(worksheet['E'+row].v.toString()):0;
							if(me.usuario.empresa.usar_inventario){
								producto.costo_unitario=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?parseFloat(worksheet['F'+row].v.toString().replace(',','.')):0;
								producto.cantidad=worksheet['G'+row]!=undefined && worksheet['G'+row]!=""?parseFloat(worksheet['G'+row].v.toString().replace(',','.')):0;
								sumatoria=sumatoria+(producto.costo_unitario*producto.cantidad);
								if(me.usuario.empresa.usar_vencimientos){
									producto.fecha_vencimiento=me.leerFechaVencimiento(worksheet,row,'H');
									producto.lote = worksheet['I' + row] != undefined && worksheet['I' + row] != "" ? worksheet['I' + row].v.toString() : null;
									producto.registro_sanitario = worksheet['J' + row] != undefined && worksheet['J' + row] != "" ? worksheet['J' + row].v.toString() : null;
									producto.grupo_3 = worksheet['K' + row] != undefined && worksheet['K' + row] != "" ? worksheet['K' + row].v.toString() : null;
								}
							}
						}else{
							if(me.usuario.empresa.usar_inventario){
								producto.costo_unitario=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?parseFloat(worksheet['E'+row].v.toString().replace(',','.')):0;
								producto.cantidad=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?parseFloat(worksheet['F'+row].v.toString().replace(',','.')):0;
								sumatoria=sumatoria+(producto.costo_unitario*producto.cantidad);
								if(me.usuario.empresa.usar_vencimientos){
									producto.fecha_vencimiento=me.leerFechaVencimiento(worksheet,row,'G');
									producto.lote = worksheet['H' + row] != undefined && worksheet['H' + row] != "" ? worksheet['H' + row].v.toString() : null;
									producto.registro_sanitario = worksheet['I' + row] != undefined && worksheet['I' + row] != "" ? worksheet['I' + row].v.toString() : null;
									producto.grupo_3 = worksheet['J' + row] != undefined && worksheet['J' + row] != "" ? worksheet['J' + row].v.toString() : null;
								}
							}
						}
					}
					
					
					producto.caracteristica_especial1=null;
					producto.caracteristica_especial2=null;
					producto.comision=0;
					producto.alerta=0;
					producto.descuento=0;
					producto.descuento_fijo=null;
					
					if(me.usuario.empresa.usar_medicina_dietas){
						producto.dieta_und_md=worksheet['J'+row]!=undefined && worksheet['J'+row]!=""?parseFloat(worksheet['J'+row].v.toString()):null;
						producto.dieta_kcal=worksheet['K'+row]!=undefined && worksheet['K'+row]!=""?parseFloat(worksheet['K'+row].v.toString()):null;
						producto.dieta_hum=worksheet['L'+row]!=undefined && worksheet['L'+row]!=""?parseFloat(worksheet['L'+row].v.toString()):null;
						producto.dieta_prot=worksheet['M'+row]!=undefined && worksheet['M'+row]!=""?parseFloat(worksheet['M'+row].v.toString()):null;
						producto.dieta_lip=worksheet['N'+row]!=undefined && worksheet['N'+row]!=""?parseFloat(worksheet['N'+row].v.toString()):null;
						producto.dieta_glu=worksheet['O'+row]!=undefined && worksheet['O'+row]!=""?parseFloat(worksheet['O'+row].v.toString()):null;
						producto.dieta_fib=worksheet['P'+row]!=undefined && worksheet['P'+row]!=""?parseFloat(worksheet['P'+row].v.toString()):null;
						producto.dieta_ca=worksheet['Q'+row]!=undefined && worksheet['Q'+row]!=""?parseFloat(worksheet['Q'+row].v.toString()):null;
						producto.dieta_p=worksheet['R'+row]!=undefined && worksheet['R'+row]!=""?parseFloat(worksheet['R'+row].v.toString()):null;
						producto.dieta_fe=worksheet['S'+row]!=undefined && worksheet['S'+row]!=""?parseFloat(worksheet['S'+row].v.toString()):null;
						producto.dieta_no=worksheet['T'+row]!=undefined && worksheet['T'+row]!=""?parseFloat(worksheet['T'+row].v.toString()):null;
						producto.dieta_k=worksheet['U'+row]!=undefined && worksheet['U'+row]!=""?parseFloat(worksheet['U'+row].v.toString()):null;
						producto.dieta_zn=worksheet['V'+row]!=undefined && worksheet['V'+row]!=""?parseFloat(worksheet['V'+row].v.toString()):null;
						producto.dieta_a=worksheet['W'+row]!=undefined && worksheet['W'+row]!=""?parseFloat(worksheet['W'+row].v.toString()):null;
						producto.dieta_b1=worksheet['X'+row]!=undefined && worksheet['X'+row]!=""?parseFloat(worksheet['X'+row].v.toString()):null;
						producto.dieta_b2=worksheet['Y'+row]!=undefined && worksheet['Y'+row]!=""?parseFloat(worksheet['Y'+row].v.toString()):null;
						producto.dieta_pp=worksheet['Z'+row]!=undefined && worksheet['Z'+row]!=""?parseFloat(worksheet['Z'+row].v.toString()):null;
						producto.dieta_c=worksheet['AA'+row]!=undefined && worksheet['AA'+row]!=""?parseFloat(worksheet['AA'+row].v.toString()):null;
					}
					let repetido=productos.filter(p => p.codigo==producto.codigo).length>0;
					if(repetido){
						me.mensaje_importacion=me.mensaje_importacion+" codigo repetido "+producto.codigo+" para el producto "+producto.nombre+",";
					}
					productos.push(producto);
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);
				//if(!me.usuario.empresa.usar_pedidos && productos.length>500){
				//	me.mensaje_importacion="Número de productos máximo permitido: 50";
				//}
				me.filter.total=sumatoria;
				me.blockUI.stop();
				me.productos_importacion=productos;
				me.productosImportacion=me.modalService.open(me.importacionproductos, {windowClass:"importacion-productos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
				me.productosImportacion.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
				//aplicarCongeladorCabecera();
				//this.abrirPopup(this.idModalVistaPreviaImportacion);
				//this.guardarProductos(productos);
				//blockUI.stop();*/
			};
			reader.readAsBinaryString(f);
	    }  
	}

	leerFechaVencimiento(worksheet,row,letra){
		var fecha_vencimiento = null,fecha_vencimiento_texto="";
		if (worksheet[letra + row] != undefined && worksheet[letra + row] != "") {
			if (typeof worksheet[letra + row].v === 'number') {
				if (worksheet[letra + row].v % 1 === 0) {
					fecha_vencimiento = new Date((worksheet[letra + row].v - (25567 + 1)) * 86400 * 1000);
				}
			} else {
				fecha_vencimiento = Util.convertirTextoAfecha(worksheet[letra + row].v);
				fecha_vencimiento_texto=worksheet[letra + row].v;
			}
			//fecha_vencimiento_texto=worksheet[letra + row].v;
		}
		return fecha_vencimiento;
		//detalle.fecha_vencimiento_texto = fecha_vencimiento_texto;
	}

	async guardarProductos(){
		if(this.mensaje_importacion.length>0){
			this.toastr.error(this.mensaje_importacion);
		}else{
			this.blockUI.start();let id_almacen=null,id_sucursal=null;
			if(this.usuario.empresa.usar_inventario){
				id_almacen=this.filter.almacen.id;
				id_sucursal=this.filter.sucursal.id;
			}
			this.productosService.guardarProductos({
				productos:this.productos_importacion,
				id_empresa:this.usuario.id_empresa,
				id_almacen:id_almacen,
				id_sucursal:id_sucursal,
				id_usuario:this.usuario.id,
				total:this.filter.total,
				usar_precios_productos:this.usuario.empresa.usar_precios_productos
			}).subscribe((res:any) => {
				this.blockUI.stop();
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
				}else{
					this.productosImportacion.close();
					this.getItems();
					this.toastr.success(res.mensaje);
				}
			});
		}
	}

	async obtenerAlmacenesSucursal(sucursal){
		let datos_sucursal:any=await this.empresaService.obtenerSucursal(sucursal.id).toPromise();
		this.filter.almacenes=datos_sucursal.almacenes;
		if(this.usuario.empresa.usar_almacenes_usuario){
			let almacenes:any=await this.empresaService.obtenerAlmacenesUsuario(this.usuario.id).toPromise();
			this.filter.almacenes=almacenes.length==0?datos_sucursal.almacenes:almacenes;
		}
		this.filter.almacen=this.filter.almacenes.length>0?this.filter.almacenes[0]:{};
	}

	async obtenerGruposProductosEmpresa(){
		this.filter.grupos_filtro=[GlobalVariable.Dictionary.SELECCION_TODOS];
		this.tipo_grupo=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"GRUPOS PRODUCTOS").toPromise();
		this.filter.grupos_filtro=this.filter.grupos_filtro.concat(this.tipo_grupo.clases);
	}

	obtenerSubGruposProductosEmpresa(){
		this.filter.subgrupos_filtro=[GlobalVariable.Dictionary.SELECCION_TODOS];
		this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SUBGRUPOS PRODUCTOS").subscribe((tipoSubgrupoEmpresa:any) => {
			this.tipo_subgrupo=tipoSubgrupoEmpresa;
			this.filter.subgrupos_filtro=this.filter.subgrupos_filtro.concat(tipoSubgrupoEmpresa.clases);
		});
	}

	abrirRegistroProducto(){
		this.edicionproducto = this.modalService.open(RegistroProductoComponent, {windowClass : "wizard-edicion-producto",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicionproducto.componentInstance.producto = this.producto;
		this.edicionproducto.componentInstance.usuario = this.usuario;
		this.edicionproducto.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		
		this.edicionproducto.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.getItems();
			}
			this.edicionproducto.close();
        });
	}

	crearNuevoProducto(){
		this.producto={};
		this.abrirRegistroProducto();
	}

	modificarProducto(producto){
		this.blockUI.start();
		this.productosService.obtenerProducto(producto.id).subscribe((datosProducto:any) => {
			this.producto=datosProducto;
			this.producto.publicar_panel=this.producto.publicar_panel==1?true:false;
			this.producto.activar_inventario=this.producto.activar_inventario==1?true:false;
			this.producto.activar_vencimiento=this.producto.activar_vencimiento==1?true:false;

			if(this.producto.productosBase){
				this.producto.totalBase=0;
				for (let i = 0; i < this.producto.productosBase.length; i++) {
					this.producto.totalBase =this.producto.totalBase+(this.producto.productosBase[i].productoBase.precio_unitario*this.producto.productosBase[i].formulacion);
				}
			}
	
			if(this.producto.almacenErp){
				this.producto.sucursal_erp=this.producto.almacenErp.sucursal;
				//this.obtenerAlmacenes(this.producto.sucursal_erp.id);
			}
			this.abrirRegistroProducto();
			this.blockUI.stop();
		});
	}
	
	abrirPopupConfirmacionEliminacion(producto){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de eliminar el producto?";
		this.popupConfirmacion.componentInstance.data = producto;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarProducto($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

	abrirPopupConfirmacionGeneradorValoresInicialesProductosSucursales(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de aplicar la generacion de valores iniciales de los productos de la empresa en las sucursales?";
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.generarValoresInicialesProductosSucursales();
			}
			this.popupConfirmacion.close();
        });
	}

	async generarValoresInicialesProductosSucursales(){
		this.blockUI.start();
		let sucursales:any=await this.empresaService.obtenerSucursalesEmpresa(this.usuario.id_empresa).toPromise();
		let mensaje="";
		for(let i=0;i<sucursales.length;i++){
			let res:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,0,sucursales[i].id).toPromise();
			mensaje=res.mensaje;
		}
		this.toastr.info(mensaje);
		this.blockUI.stop();
	}

	eliminarProducto(producto){
		this.blockUI.start();
		this.productosService.eliminarProducto(producto).subscribe((dato:any) => {
			this.getItems();
			this.toastr.info(dato.mensaje);
			this.blockUI.stop();
		});
	}

	actualizarAtributoProducto(producto,atributo,valor){
		this.blockUI.start();
		let datos:any={
			atributo:atributo,
			valor:valor
		};
		if(producto){
			datos.producto=producto;
		}else{
			datos.id_empresa=this.usuario.id_empresa;
		}
		this.productosService.actualizarAtributoProducto(datos).subscribe((res:any) => {
			this.getItems();
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
		});
	}
	
	subirExcelProductosPrecios(event){
		this.blockUI.start();
		//this.mensaje_importacion=null;
		let me=this;
		var files = event.target.files;
		var i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			var reader = new FileReader();
			var name = f.name;
			reader.onload = function(e) {
			  var data = e.target.result;

			  var workbook = XLSX.read(data, {type: 'binary'});
				var first_sheet_name = workbook.SheetNames[0];
				var row=2,i=0;
				var worksheet = workbook.Sheets[first_sheet_name];
				me.precios_productos=[];
				do {
					let producto:any={};
					producto.codigo=worksheet['A'+row]!=undefined && worksheet['A'+row]!=""?worksheet['A'+row].v.toString():null;
					producto.nombre=worksheet['B'+row]!=undefined && worksheet['B'+row]!=""?worksheet['B'+row].v.toString():null;
					producto.unidad_medida=worksheet['C'+row]!=undefined && worksheet['C'+row]!=""?worksheet['C'+row].v.toString():null;
					producto.concepto=worksheet['D'+row]!=undefined && worksheet['D'+row]!=""?worksheet['D'+row].v.toString():null;
					producto.precio_unitario=worksheet['E'+row]!=undefined && worksheet['E'+row]!=""?parseFloat(worksheet['E'+row].v.toString()):null;
					producto.por_defecto=worksheet['F'+row]!=undefined && worksheet['F'+row]!=""?((parseInt(worksheet['F'+row].v.toString())==1)?true:false):false;
					me.precios_productos.push(producto);
					row++;	
					i++;				
				} while (worksheet['A'+row]!=undefined);

				me.blockUI.stop();
				me.importacion_productos_precios=me.modalService.open(me.importacion_productos_precios_ref, {windowClass:"importacion-productos",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
				me.importacion_productos_precios.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
			};
			reader.readAsBinaryString(f);
	    }  
	}

	verKardexProducto(producto){
		this.kardexProducto = this.modalService.open(MovimientoProductoComponent, {windowClass : "movimientos-producto",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.kardexProducto.componentInstance.producto = producto;
		this.kardexProducto.componentInstance.usuario = this.usuario;
		this.kardexProducto.componentInstance.alTerminar.subscribe(($e) => {
			this.kardexProducto.close();
        });
	}

	generarKardexProducto(producto){
		this.kardexProducto = this.modalService.open(MovimientoProductoComponent, {windowClass : "movimientos-producto",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.kardexProducto.componentInstance.producto = producto;
		this.kardexProducto.componentInstance.usuario = this.usuario;
		this.kardexProducto.componentInstance.alTerminar.subscribe(($e) => {
			this.kardexProducto.close();
        });
	}

	descargarExcelProductos(){
		this.blockUI.start();
		var data=[];
		var cabecera=["Codigo", "Nombre", "Unidad de medida", "Precio Unitario","Descripción",
		"Codigo de Fabrica","Grupo","Sub-grupo","Costo Unitario","Utilidad"];
		data.push(cabecera);
		var totalCosto = 0;
		for (var i = 0; i < this.productos.length; i++) {
			var columns = [];
			columns.push(this.productos[i].codigo);
			columns.push(this.productos[i].nombre);
			columns.push(this.productos[i].unidad_medida);
			columns.push(this.productos[i].precio_unitario);			
			columns.push(this.productos[i].descripcion);
			columns.push(this.productos[i].codigo_fabrica);
			columns.push(this.productos[i].grupo);
			columns.push(this.productos[i].subgrupo);
			columns.push(this.productos[i].costo_unitario);
			let utilidad:any="";
			if(this.productos[i].costo_unitario!=null){
				let costo_unitario=this.productos[i].costo_unitario?this.productos[i].costo_unitario:1;
				utilidad=((this.productos[i].precio_unitario-costo_unitario)/costo_unitario)*100;
			}
			
			columns.push(utilidad);
			data.push(columns);
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("PRODUCTOS");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "CatalogoDeProductos.xlsx");
		})
		this.blockUI.stop();
	}

	abrirConfiguracionAplicacion(){
		this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionProductosComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

	async descargarExcelProductosPrecios () {
		let entidad:any = await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").toPromise();
		let tipo_producto_precio=entidad;
		let productos:any=await this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).toPromise();
		var data = [["Codigo", "Nombre o Descripción", "Unidad de medida", "Concepto Precio", "Precio Unitario",
				"Por Defecto (SI=1/NO=0)"]]
		var totalCosto = 0;
		for (var i = 0; i < productos.length; i++) {
			for(var j=0;j<tipo_producto_precio.clases.length;j++){
				var columns = [];
				columns.push(productos[i].codigo);
				columns.push(productos[i].nombre);
				columns.push(productos[i].unidad_medida);
				columns.push(tipo_producto_precio.clases[j].nombre);
				data.push(columns);
			}
		}

		let workbook = new Workbook();
		let worksheet = workbook.addWorksheet("PRECIOS");
		data.forEach(d => {
		let row = worksheet.addRow(d);
		}
		);

		const dobCol2 = worksheet.getColumn(2);
		dobCol2.width = 40;
		const dobCol3 = worksheet.getColumn(3);
		dobCol3.width = 20;

		workbook.xlsx.writeBuffer().then((data) => {
		let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		fs.saveAs(blob, "EJEMPLO-DATOS-PRECIOS-PRODUCTOS.xlsx");
		})
		this.blockUI.stop();
	}

	guardarPreciosProductos(){
		this.blockUI.start();
		this.productosService.guardarProductosPrecios({
			productos_precios:this.precios_productos,
			id_empresa:this.usuario.id_empresa
		}).subscribe((res:any) => {
			this.importacion_productos_precios.close();
			this.getItems();
			this.blockUI.stop();
			this.toastr.success(res.mensaje);
		});
	}

	async convertirSvgACanvas(){
		this.configuracion_aplicacion_modal=this.modalService.open(ImpresionCodigosBarrasComponent, {windowClass:"generado-codigos-barras", ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
		this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
		this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
		this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
			this.configuracion_aplicacion_modal.close();
		});
	}

	async abrirRegistroProductoSucursal(producto){
		this.filter.sucursales=Util.obtenerSucursalesUsuario(this.usuario),
		this.filter.sucursal=Util.obtenerSucursalesUsuario(this.usuario)[0],
		this.producto=producto;
		await this.obtenerDatosProductoSucursal(producto);
		this.modal_registro_producto_sucursal=this.modalService.open(this.modal_registro_producto_sucursal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_registro_producto_sucursal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	async obtenerDatosProductoSucursal(producto){
		this.blockUI.start();
		let res:any=await this.productosService.obtenerProductoSucursal(this.usuario.id_empresa,producto.id,this.filter.sucursal.id).toPromise();
		let producto_respuesta=res;
		this.tipo_producto_precio = await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"PRECIOS PRODUCTOS").toPromise();
		if(producto_respuesta.precios.length==0){
			producto_respuesta.precios=[{sucursal:this.filter.sucursal,id_producto:producto.id,concepto:this.tipo_producto_precio.clases[0],precio_unitario:producto.precio_unitario,por_defecto:true}];
		}
		this.producto_sucursal=res;
		this.blockUI.stop();
	}

	actualizarProductoSucursal(){
		this.blockUI.start();
		this.productosService.actualizarProductoSucursal(this.producto_sucursal).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			//this.modal_registro_producto_sucursal.close();
			this.blockUI.stop();
		});
	}

	async alActualizarGrupos(){
		await this.obtenerGruposProductosEmpresa();
		if(this.usuario.empresa.usar_precios_productos_grupos){
			for(let i=0;i<this.tipo_grupo.clases.length;i++){
				await this.productosService.actualizarPreciosProductosGrupos({
					precio_unitario:parseFloat(this.tipo_grupo.clases[i].nombre_corto),
					id_grupo:this.tipo_grupo.clases[i].id,
					id_empresa:this.usuario.id_empresa
				}).toPromise();
			}
			this.getItems();
		}
	}

	abrirListaProductosSin(){
		this.obtenerListaProductosSin();
		this.lista_productos_sin=this.modalService.open(this.lista_productos_sin_ref, {windowClass:"lista-productos-sin",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
		this.lista_productos_sin.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	obtenerListaProductosSin(){
		this.empresaService.obtenerProductosSin(this.usuario.id_empresa).subscribe((productos_servicios_sin:any)=>{
			this.productos_servicios_sin=productos_servicios_sin;
		});
	}

	guardarProductoServicioSin(){
		this.producto_servicio_sin.id_empresa=this.usuario.id_empresa;
		this.producto_servicio_sin.id_sucursal=this.usuario.sucursalesUsuario[0].sucursal.id;
		this.empresaService.guardarProductoSin(this.producto_servicio_sin).subscribe((res:any)=>{
			this.producto_servicio_sin={};
			if(res.respuesta.mensaje){
				this.toastr.success(res.respuesta.mensaje);
			}else{
				for(var i=0;i<res.respuesta.length;i++){
					this.toastr.error(res.respuesta[i].nombre);
				}
			}
			this.obtenerListaProductosSin();
		});
	}

	sincronizarProductosSin(){
		this.blockUI.start();
		this.empresaService.sincronizarProductosSin({id_sucursal:this.usuario.sucursalesUsuario[0].sucursal.id}).subscribe((res:any)=>{
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.obtenerListaProductosSin();
			}
			this.blockUI.stop();
		});
	}

	abrirListaProductosRelacionSin(){
		this.productos_relacion=[];
		this.productosService.obtenerProductosEmpresa(this.usuario.id_empresa).subscribe((productos:any)=>{
			this.productos_relacion=productos;
		});
		this.obtenerListaProductosSin();
		this.relacion_productos_sin=this.modalService.open(this.relacion_productos_sin_ref, {windowClass:"lista-productos-sin",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
		this.relacion_productos_sin.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	guardarRelacionProductosSin(){
		this.blockUI.start();
		this.productosService.guardarRelacionProductosSin({productos:this.productos_relacion}).subscribe((res:any)=>{
			this.blockUI.stop();
			if(res.tiene_error){
				this.toastr.error(res.mensaje);

			}else{
				this.relacion_productos_sin.close();
				this.toastr.success(res.mensaje);
			}
		});
	}

	async descargarCatalogoPdfProductos(){
		this.blockUI.start();
		let doc = new PDFDocument({size:[612, 792],margin:10});
		let stream = doc.pipe(blobStream());
		var y = 140, items = 0,itemsPorPagina=14, pagina = 1, totalPaginas = Math.ceil(this.productos.length / itemsPorPagina),fecha_reporte=new Date();
		this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"CATÁLOGO DE PRODUCTOS",pagina,totalPaginas,this.usuario,
			this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
			(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
		(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
		(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
		fecha_reporte,{imprimir_usuario:false});
		await this.dibujarCabeceraPdfCatalogoproductos(doc);
		for (let i = 0; i < this.productos.length; i++) {
			let imagen = await Util.convertirUrlToBase64Image(GlobalVariable.SERVER_URL+this.productos[i].imagen);
			doc.rect(50,y-10,540,40).stroke();
			doc.font('Helvetica',8);
			doc.text((i+1)+"",55,y);
			doc.text(this.productos[i].codigo,70,y);
			doc.image(imagen,130,y-8,{width:35,height:35});
			doc.text(this.productos[i].nombre,180,y,{width:100});
			doc.text(this.productos[i].unidad_medida,320,y);
			doc.text(this.productos[i].grupo,450,y,{width:70});
			doc.text(this.productos[i].subgrupo?this.productos[i].subgrupo:"",530,y);
			doc.text(this.productos[i].precio_unitario.toFixed(2),400,y);
			//doc.text(detallesVenta[i].total,705,y);
			y=y+40;
			items++;
			

			if (items == itemsPorPagina && (i+1) < this.productos.length) {
				doc.addPage({ size: [612, 792], margin: 10 });
				y = 140;
				items = 0;
				pagina = pagina + 1;
				//EscribirCabeceraFacturaCartaOficio(doc, vacia, completa, venta, papel, pagina, totalPaginas, usuario,configuracionImpresion);
				this.pdfService.dibujarCabeceraGeneralReporteCartaOficio(doc,"CATÁLOGO DE PRODUCTOS",pagina,totalPaginas,this.usuario,
					this.usuario.empresa.imagen,this.usuario.empresa.nombre,this.usuario.empresa.nombre,this.usuario.empresa.direccion,
					(this.usuario.empresa.telefono1!=null?this.usuario.empresa.telefono1:"")+
				(this.usuario.empresa.telefono2!=null?"-"+this.usuario.empresa.telefono2:"")+
				(this.usuario.empresa.telefono3!=null?"-"+this.usuario.empresa.telefono3:""),"COCHABAMBA - BOLIVIA",GlobalVariable.Dictionary.PAPEL_CARTA,
				fecha_reporte,{imprimir_usuario:false});
				await this.dibujarCabeceraPdfCatalogoproductos(doc);
			}
			
		}
		doc.end();
		this.blockUI.stop();
		stream.on('finish', function() {
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
	}

	dibujarCabeceraPdfCatalogoproductos(doc){
		let y=140;
		doc.font('Helvetica-Bold',8);
		doc.roundedRect(50,105,540,25,5).fillOpacity(0.8).stroke();
		
		doc.text("Nº", 55, y-30);
		doc.text("CODIGO", 70, y-30);
		doc.text("IMAGEN", 130, y-30);
		doc.text("NOMBRE", 200, y-30);
		doc.text("GRUPO", 460, y-30);
		doc.text("SUB-GRUPO", 520, y-30);
		doc.text("PRECIO UNITARIO", 370, y-30);
		doc.text("UNIDAD MEDIDA", 280, y-30);
	}

	async asignarIngredientes(producto){
		this.producto=producto;
		this.blockUI.start();
		let suplementos_producto:any=await this.productosService.obtenerSuplementosProducto(this.producto.id).toPromise();
		this.suplementos_producto=suplementos_producto;
		this.blockUI.stop();
		this.modal_registro_ingredientes=this.modalService.open(this.modal_registro_ingredientes_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_registro_ingredientes.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	agregarSuplementoProducto(){
		if(!this.suplemento_producto.grupo){
			this.toastr.error("Debe seleccionar un grupo!");
		}else{
			let suplemento_encontrado=this.suplementos_producto.filter(su => su.grupo.id==this.suplemento_producto.grupo.id);
			if(suplemento_encontrado.length==0){
				this.suplementos_producto.push(this.suplemento_producto);
				this.suplemento_producto={cantidad_maxima_pedido:1};
			}
		}
	}

	guardarSuplementosProducto(){
		this.blockUI.start();
		this.productosService.guardarSuplementosProducto({id_producto:this.producto.id,suplementos:this.suplementos_producto}).subscribe((res:any)=>{
			this.toastr.success(res.mensaje);
			this.blockUI.stop();
			this.modal_registro_ingredientes.close();
		});
	}

	eliminarSuplementoProducto(suplemento){
		suplemento.eliminado=true;
	}

	abrirResultadoLaboratorio(producto){
		this.modificacionPruebaLaboratorio = this.modalService.open(ResultadoLaboratorioComponent, {scrollable:true,windowClass:'edicion-resultado-prueba',ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modificacionPruebaLaboratorio.componentInstance.producto = producto;
		this.modificacionPruebaLaboratorio.componentInstance.usuario = this.usuario;
		this.modificacionPruebaLaboratorio.componentInstance.sucursales = Util.obtenerSucursalesUsuario(this.usuario);
		
		this.modificacionPruebaLaboratorio.componentInstance.alTerminar.subscribe(($e) => {
			
			this.modificacionPruebaLaboratorio.close();
        });
	}
}
