import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';

@Injectable({
  providedIn: 'root'
})
export class CampeonatosService {

  constructor(private http: HttpClient) { }

  public obtenerListaCampeonatos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"campeonatos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction,GlobalVariable.httpOptions);
  }

  public obtenerCampeonatosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"campeonatos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarCampeonato(campeonato){
    return this.http.post(GlobalVariable.API_SERVER_URL+"campeonatos/",campeonato,GlobalVariable.httpOptions);
  }

  public actualizarCampeonato(campeonato){
    return this.http.put(GlobalVariable.API_SERVER_URL+"campeonatos/"+campeonato.id,campeonato,GlobalVariable.httpOptions);
  }

  public actualizarComfiguracionTarjetaJugadorCampeonato(campeonato){
    return this.http.put(GlobalVariable.API_SERVER_URL+"configuracion_tarjeta_jugador/campeonato/"+campeonato.id,campeonato,GlobalVariable.httpOptions);
  }

  public guardarEquipo(equipo){
    return this.http.post(GlobalVariable.API_SERVER_URL+"equipos/",equipo,GlobalVariable.httpOptions);
  }

  public actualizarEquipo(equipo){
    return this.http.put(GlobalVariable.API_SERVER_URL+"equipos/"+equipo.id,equipo,GlobalVariable.httpOptions);
  }

  public obtenerListaEquipos(id_campeonato,id_categoria,id_disciplina){
    return this.http.get(GlobalVariable.API_SERVER_URL+"equipos/campeonato/"+id_campeonato+"/categoria/"+id_categoria+"/disciplina/"+id_disciplina,GlobalVariable.httpOptions);
  }

  public obtenerListaJugadores(id_equipo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"jugadores/equipo/"+id_equipo,GlobalVariable.httpOptions);
  }

  public guardarJugador(jugador){
    return this.http.post(GlobalVariable.API_SERVER_URL+"jugadores/",jugador,GlobalVariable.httpOptions);
  }

  public actualizarJugador(jugador){
    return this.http.put(GlobalVariable.API_SERVER_URL+"jugadores/"+jugador.id,jugador,GlobalVariable.httpOptions);
  }

  public eliminarJugador(jugador){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"jugadores/"+jugador.id,GlobalVariable.httpOptions);
  }

  public buscarJugador(id_campeonato,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"jugadores/campeonato/"+id_campeonato+"/texto/"+texto, GlobalVariable.httpOptions);
  }

  public buscarJugadorEmpresa(id_empresa,texto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"jugadores/empresa/"+id_empresa+"/texto/"+texto, GlobalVariable.httpOptions);
  }

  public obtenerClienteEquipos(id_cliente){
    return this.http.get(GlobalVariable.API_SERVER_URL+"equipos/cliente/"+id_cliente, GlobalVariable.httpOptions);
  }

  public obtenerPartidosEquipo(id_equipo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"partidos/equipo/"+id_equipo, GlobalVariable.httpOptions);
  }

  public actualizarAtributoEquipo(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"actualizar-atributo-equipos",datos,GlobalVariable.httpOptions);
  }
  
  public actualizarAtributoJugador(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"actualizar-atributo-jugadores",datos,GlobalVariable.httpOptions);
  }

  public obtenerListaJugadoresSinCarnet(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"jugadores/sin-carnet/"+id_empresa,GlobalVariable.httpOptions);
  }
}
