import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralService } from 'src/app/base/services/general/general.service';
import { PersistenciaService } from 'src/app/base/services/persistencia/persistencia.service';
import { UsuarioService } from 'src/app/base/services/usuario/usuario.service';
import { BaseComponent } from 'src/app/models/base-component';
import { CampeonatosService } from '../../services/campeonatos/campeonatos.service';
import { Util } from 'src/app/utils/utils';
import { GlobalVariable } from 'src/app/global';
import { RolesPartidosService } from '../../services/roles-partidos/roles-partidos.service';
import * as fs from 'file-saver';
import { OrderPipe } from 'ngx-order-pipe';
import * as CryptoJS from 'crypto-js';
import { RegistroJugadorComponent } from '../registro-jugador/registro-jugador.component';
import { NgbModalRef } from 'node_modules/@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-club',
  templateUrl: './club.component.html',
  styleUrls: ['./club.component.css']
})
export class ClubComponent extends BaseComponent implements OnInit {

  id_cliente:any;
  cliente:any;
  partidos:any=[];
  equipo_tab:any={};
  modalJugador:NgbModalRef;

  constructor(private _Activatedroute:ActivatedRoute,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
    public campeonatoService:CampeonatosService,
  public rolesPartidosService:RolesPartidosService,
  private toastr: ToastrService,
  private orderPipe: OrderPipe) {
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  async ngOnInit(): Promise<void> {
    this.id_cliente=CryptoJS.AES.decrypt(this._Activatedroute.snapshot.paramMap.get("id_cliente").toString().replaceAll('Por21Ld', '/'), GlobalVariable.Dictionary.TIPO_RECURSOS_MULTIMEDIA).toString(CryptoJS.enc.Utf8);
    await this.obtenerEquiposCliente(this.id_cliente);
    await this.establecerEquipoTab(this.cliente.equipos[0]);

  }

  establecerEquipoTab(equipo){
    this.equipo_tab=equipo;
    this.obtenerPartidosEquipo();
  }

  async obtenerEquiposCliente(id_cliente){
    this.blockUI.start();
    this.cliente=await this.campeonatoService.obtenerClienteEquipos(id_cliente).toPromise();
    for(let i=0;i<this.cliente.equipos.length;i++){
      this.cliente.equipos[i].jugadores=this.orderPipe.transform(this.cliente.equipos[i].jugadores, 'persona.nombre_completo',false);
    }
    this.blockUI.stop();
  }

  async obtenerPartidosEquipo(){
    this.blockUI.start();
    this.partidos=await this.campeonatoService.obtenerPartidosEquipo(this.equipo_tab.id).toPromise();
    this.blockUI.stop();
  }

  async generarListaConvocados(jugadores,partido){
    this.blockUI.start();
    let doc = new PDFDocument({size:[612, 612],margin:10});
    let stream = doc.pipe(blobStream());
    let fondo=await Util.convertirUrlToBase64Image(this.rest_server+"img/fondo-rol-partido.png");
    await this.dibujarCabeceraPartido(doc,fondo,this.equipo_tab.campeonato,partido.rol_partido);
    
    let y_doc=jugadores.length>0?90:180;
    let logo_equipo_1=await Util.convertirUrlToBase64Image(this.rest_server+partido.equipo_1.logo);
    let logo_equipo_2=await Util.convertirUrlToBase64Image(this.rest_server+partido.equipo_2.logo);
    doc.image(logo_equipo_1,45,y_doc, { width: 150, height: 150}); 
    doc.image(logo_equipo_2,415,y_doc, { width: 150, height: 150}); 

    doc.font('Helvetica-Bold',40);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
    doc.text("VS",0,y_doc+30,{width:612,align:"center"});
    doc.roundedRect(201,y_doc+60,210,90,5).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();;
    doc.font('Helvetica-Bold',20);
    let fecha_hora=new Date(partido.fecha_hora);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text(fecha_hora.toLocaleDateString("es-ES", { weekday: 'long' }).toUpperCase(),0,y_doc+70,{width:612,align:"center"});
    doc.text(fecha_hora.getDate()+" "+fecha_hora.toLocaleString('es-ES', { month: 'long' }).toUpperCase()+" "+fecha_hora.getFullYear(),0,y_doc+90,{width:612,align:"center"});
    doc.text(fecha_hora.getHours()+":"+fecha_hora.getMinutes(),0,y_doc+110,{width:612,align:"center"});
    doc.text(this.equipo_tab.categoria.nombre,0,y_doc+130,{width:612,align:"center"});

    doc.font('Helvetica-Bold',13);
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
    doc.text(partido.equipo_1.nombre,30,y_doc+210,{width:200,align:"center"});
    doc.text(partido.equipo_2.nombre,382,y_doc+210,{width:200,align:"center"});

    let y_jugador=360;
    if(jugadores.length>0){
      doc.font('Helvetica-Bold',20);
      doc.roundedRect(156,335,300,23,5).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();;
      doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
      doc.text("LISTA DE CONVOCADOS",0,340,{width:612,align:"center"});
      
      doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta);
      doc.font('Helvetica-Bold',15);
     
      for(let i=0;i<jugadores.length;i++){
        doc.text((i+1)+".- "+jugadores[i].persona.nombre_completo,150,y_jugador,{width:400,align:"left"});
        y_jugador=y_jugador+15;
      }
    }

    doc.font('Helvetica-Bold',12);
    doc.rect(0,550,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    doc.fillColor(this.equipo_tab.campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.text("ESCENARIO:",20,560,{width:572,align:"center"});
    doc.text(partido.escenario.nombre.toUpperCase()+" - "+partido.escenario.direccion.toUpperCase(),20,572,{width:572,align:"center"});

    doc.end();
    let me=this;
    stream.on('finish', async function(d) {
      var reader = new FileReader();
      reader.readAsDataURL(stream.toBlob('application/pdf')); 
      reader.onloadend = async function() {
        var base64data = reader.result;                
        me.rolesPartidosService.convertirPdfImg({base64:base64data,nombre:"rol-partidos.png"}).subscribe(async (res:any)=>{
          fs.saveAs(GlobalVariable.SERVER_URL+res.path, "rol-partidos.png");
          me.blockUI.stop();
        });
      }
      /*var fileURL = stream.toBlobURL('application/pdf');
      window.open(fileURL,'_blank','location=no');*/
    });
  }

  async dibujarCabeceraPartido(doc,fondo,campeonato,rol_partido){
    doc.image(fondo,0,0, { width: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0], height: GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[1],align:'right' });
    doc.rect(0,0,GlobalVariable.Dictionary.PAPEL_OFICIO_MEDIDA[0],80).fill(campeonato.configuracion_tarjeta_jugador.fondo_color_tarjeta).stroke();
    let logo=await Util.convertirUrlToBase64Image(this.rest_server+campeonato.logo);
    let logo_empresa=await Util.convertirUrlToBase64Image(this.rest_server+this.cliente.empresa.imagen);
    doc.image(logo_empresa,20,5, { width: 70, height: 70,align:'left' }); 
    doc.fillColor(campeonato.configuracion_tarjeta_jugador.enunciados_color);
    doc.font('Times-Bold',30);
    doc.text(campeonato.nombre.toUpperCase(),0,20,{align:"center"});
    doc.font('Times-Bold',15);
    doc.text(rol_partido.rol.nombre,0,50,{align:"center"});
    doc.image(logo,522,5, { width: 70, height: 70,align:'right' });
    doc.font('Helvetica-Bold',16);
  }

  async crearNuevoJugador(equipo){
    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.equipo = equipo;
		this.modalJugador.componentInstance.usuario = {id_empresa:this.cliente.id_empresa};
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerEquiposCliente(this.id_cliente);
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
			
		});
  }

  async modificarJugador(jugador_a_modificar){
    let jugador:any=jugador_a_modificar;
    jugador.persona.fecha_nacimiento_texto=jugador.persona.fecha_nacimiento?Util.convertirFechaAObjectoFecha(new Date(jugador.persona.fecha_nacimiento)):null;
    jugador.id_empresa=this.cliente.id_empresa;

    this.modalJugador = this.modalService.open(RegistroJugadorComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
    this.modalJugador.componentInstance.jugador = jugador;
		this.modalJugador.componentInstance.usuario = {id_empresa:this.cliente.id_empresa};
		
		this.modalJugador.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.obtenerEquiposCliente(this.id_cliente);
					this.modalJugador.close();
				}
			}else{
				this.modalJugador.close();
			}
		});
  }

  abrirPopupConfirmacionEliminacionJugador(jugador){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro de eliminar al jugador?";
		this.popupConfirmacion.componentInstance.data = jugador;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.eliminarJugador($e.data);
			}
			this.popupConfirmacion.close();
        });
	}

  eliminarJugador(jugador){
		this.blockUI.start();
		this.campeonatoService.eliminarJugador(jugador).subscribe((dato:any)=>{
			this.blockUI.stop();
			if(dato.tiene_error){
				this.toastr.error(dato.mensaje);
			}else{
        this.obtenerEquiposCliente(this.id_cliente);
				this.toastr.success(dato.mensaje);
			}
		});
	}

}
