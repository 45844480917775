<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Resultado Laboratorio</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" (click)="guardarResultadosPrueba()" >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-1 col-12">
            <label>Clasificacion</label>
        </div>
        <div class="col-md-2 col-sm-12">
            <select [compareWith]="compararObjectos" class="form-control" required name="clasificacion" [(ngModel)]="resultado_prueba.clasificacion">
                <option [ngValue]="clasificacion" *ngFor="let clasificacion of clasificaciones">{{clasificacion.nombre}}</option>
            </select>
            <span class="fa fa-edit point-edit blue" (click)="abrirConceptoEmpresa(global_variable.Dictionary.TIPO_PRUEBA_CLASIFICACIONES,'obtenerClasificacionesPruebas')"></span>
        </div>
        <div class="col-md-1 col-12">
            <label>Etiqueta Resultado</label>
        </div>
        <div class="col-md-2 col-sm-12">
            <input type="text" name="resultado_etiqueta" [(ngModel)]="resultado_prueba.resultado_etiqueta" placeholder="Resultado Etiqueta" class="form-control form-control-lg col-12 pr-45 pl-25"  />
        </div>
        <div class="col-md-1 col-12">
            <label>Rango Referencia</label>
        </div>
        <div class="col-md-2 col-sm-12">
            <input type="text" name="rango_referencia" [(ngModel)]="resultado_prueba.rango_referencia" placeholder="Rango Referencia" class="form-control form-control-lg col-12 pr-45 pl-25"  />
        </div>
        <div class="col-md-1 col-12">
            <label>Orden</label>
        </div>
        <div class="col-md-1 col-sm-12 input-score">
            <input type="number" name="orden" only-num [(ngModel)]="resultado_prueba.orden" placeholder="Orden" class="form-control form-control-lg col-12 "  />
        </div>
        <div class="col-sm-12 col-md-1 col-6">
            <button type="button" class="btn btn-success btn-sm" (click)="agregarResultadoPrueba()">
                <i class="ace-icon fa fa-plus bigger-110"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="card-body bg-white p-0">
            <table class="table table-striped table-bordered table-hover" *ngIf="resultados_pruebas.length>0">
                <thead>
                    <tr>
                        <th>
                            <label>Nº</label>
                        </th>
                        <th>
                            <label>Clasificacion</label>
                        </th>
                        <th>
                            <label>Etiqueta Resultado</label>
                        </th>
                        <th>
                            <label>Rango Referencia</label>
                        </th>
                        <th>
                            <label>Orden</label>
                        </th>
                        <th style="text-align: center;" data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let resultado_prueba of resultados_pruebas | orderBy:'orden'; let i=index;">
                        <tr *ngIf="!resultado_prueba.eliminado">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{resultado_prueba.clasificacion?resultado_prueba.clasificacion.nombre:''}}
                            </td>
                            <td>
                                {{resultado_prueba.resultado_etiqueta}}
                            </td>
                            <td>
                                {{resultado_prueba.rango_referencia}}
                            </td>
                            <td>
                                {{resultado_prueba.orden}}
                            </td>
                            <td>
                                <a (click)="eliminarResultadoPrueba(resultado_prueba)" class="card-toolbar-btn text-danger-m1" href="javascript:void(0)">
                                    <i class="fa fa-trash bigger-130"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>