import { Component, OnInit, Input, ViewChild, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { PersistenciaService } from '../../base/services/persistencia/persistencia.service';
import { Router } from '@angular/router';
import { GeneralService } from '../../base/services/general/general.service';
import { Util } from 'src/app/utils/utils';
import { Socket } from 'ngx-socket-io';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { VencimientoCreditoComponent } from '../../snapquick/components/notificaciones/vencimiento-credito/vencimiento-credito.component';
import { VencimientoDeudaComponent } from '../../snapquick/components/notificaciones/vencimiento-deuda/vencimiento-deuda.component';
import { InventarioMinimoComponent } from '../../snapquick/components/notificaciones/inventario-minimo/inventario-minimo.component';
import { VencimientoProductoComponent } from '../../snapquick/components/notificaciones/vencimiento-producto/vencimiento-producto.component';
import { PedidosNotificacionComponent } from '../../snapquick/components/notificaciones/pedidos-notificacion/pedidos-notificacion.component';
import { EmpresaService } from '../../base/services/empresas/empresa.service';
import { ToastrService } from 'ngx-toastr';
import { PopupConfirmacionComponent } from '../../base/components/popup-confirmacion/popup-confirmacion.component';
import { MovimientoInventarioService } from '../../snapquick/services/movimiento-inventario/movimiento-inventario.service';
import { UsuarioService } from '../../base/services/usuario/usuario.service';
import { CursosService } from '../../school/services/cursos/cursos.service';
import { MateriasService } from '../../school/services/materias/materias.service';
import { EstudiantesService } from '../../school/services/estudiantes/estudiantes.service';
import { BaseComponent } from 'src/app/models/base-component';
import { RegistroDosificacionComponent } from '../../snapquick/components/registro-dosificacion/registro-dosificacion.component';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { RegistroEstudianteComponent } from '../../school/components/registro-estudiante/registro-estudiante.component';
import { ProfesoresService } from '../../school/services/profesores/profesores.service';
import { CalificacionesService } from '../../school/services/calificaciones/calificaciones.service';
import { VehiculosService } from '../../transporte/services/vehiculos/vehiculos.service';
import { RuletaComponent } from '../../school/components/ruleta/ruleta.component';
import { RegistroAsistenciaComponent } from 'src/app/rrhh/components/registro-asistencia/registro-asistencia.component';
import { WhatsappConfiguracionComponent } from 'src/app/snapquick/components/whatsapp-configuracion/whatsapp-configuracion.component';
import { ConversacionWhatsappComponent } from 'src/app/snapquick/components/conversacion-whatsapp/conversacion-whatsapp.component';
import { PagosServiciosBasicosComponent } from 'src/app/snapquick/components/pagos-servicios-basicos/pagos-servicios-basicos.component';
import { EmpresaFacturacionComponent } from 'src/app/base/components/empresa-facturacion/empresa-facturacion.component';
import { CursosComponent } from 'src/app/school/components/cursos/cursos.component';
import { MateriasComponent } from 'src/app/school/components/materias/materias.component';
import { VencimientoPagosEstudiantesComponent } from 'src/app/school/components/notificaciones/vencimiento-pagos-estudiantes/vencimiento-pagos-estudiantes.component';
import { AsuntosAdministrativosComponent } from 'src/app/school/components/notificaciones/asuntos-administrativos/asuntos-administrativos.component';
import { MensajesEmpresaComponent } from 'src/app/marketing/components/mensajes-empresa/mensajes-empresa.component';
import { ActualizacionClaveUsuarioComponent } from 'src/app/base/components/actualizacion-clave-usuario/actualizacion-clave-usuario.component';
import { VentasNoContabilizadasComponent } from 'src/app/contabilidad/components/notificaciones/ventas-no-contabilizadas/ventas-no-contabilizadas.component';
import { PagosVentasNoContabilizadosComponent } from 'src/app/contabilidad/components/notificaciones/pagos-ventas-no-contabilizados/pagos-ventas-no-contabilizados.component';
import { IMultiSelectOption } from 'ngx-bootstrap-multiselect';
import { SucursalFacturacionComponent } from 'src/app/base/components/sucursal-facturacion/sucursal-facturacion.component';
import { RedesSocialesService } from 'src/app/snapquick/services/redes-sociales/redes-sociales.service';
import { PdfService } from 'src/app/base/services/pdf/pdf.service';
import { TarjetasJugadorComponent } from 'src/app/torneo/components/tarjetas-jugador/tarjetas-jugador.component';

declare const aplicarTitulos:any;
declare const aplicarPluginImagen:any;
declare const aplicarSubidaArchivo:any;
declare const gapi: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  @Input() usuario:any;
  @BlockUI() blockUI: NgBlockUI;
  rest_server=GlobalVariable.SERVER_URL;

  modal_vencimiento_creditos:NgbModalRef;
  modal_whatsapp_qr:NgbModalRef;
  modal_chat_whatsapp:NgbModalRef;
  popupConfirmacion:NgbModalRef;

  movimiento_inventario_mensaje:any={};

  descargaDatos:NgbModalRef;
  @ViewChild('descargaDatos')
  private descargaDatosRef: TemplateRef<any>;

  datoscursosmaterias:NgbModalRef;
  @ViewChild('datoscursosmaterias')
  private datoscursosmateriasRef: TemplateRef<any>;

  datoscursos:NgbModalRef;
  @ViewChild('datoscursos')
  private datoscursosRef: TemplateRef<any>;

  modal_impresion_tarjetas_jugador:NgbModalRef;

  modal_datos_facturacion_empresa:NgbModalRef;
  modal_lista_cursos_colegio:NgbModalRef;
  modal_lista_materias_colegio:NgbModalRef;

  modal_lista_plantillas_mensajes:NgbModalRef;
  modal_actualizacion_clave:NgbModalRef;

  descarga_servidor:any={};

  pedido_actual_sucursal:any={pedido_actual:1,sucursal:{}};
  ficha_actual_sucursal:any={ficha_actual:1,sucursal:{}};

  notificacion:any={
    contador_ventas_credito_notificacion:0,
    contador_compras_credito_notificacion:0,
    contador_pedidos_notificacion:0,
    contador_ventas_no_contabilizadas:0,
    contador_pagos_venta_no_contabilizados:0,
    contador_productos_inventario_minimo:0,
    contador_vencimiento_productos_notificacion:0,
    contador_vencimientos_pagos_estudiantes_notificacion:0,
    contador_vencimientos_pagos_estudiantes_anulados_notificacion:0,
    contador_mensajes:0,
    contador_asuntos_administrativos_notificacion:0,
    ultimos_5_mensajes:[],
    contador_llantas:0,
    notificacion_llantas:[],
    contador_aceites:0,
    notificacion_aceites:[],
    contador_jugadores:0,
  }

  es_usuario_administrador:boolean=false;
  es_usuario_contabilidad:boolean=false;
  es_usuario_profesor:boolean=false;
  ids_sucursales:string="0";
  filtro:any={};

  mensaje:any;
  aplicacion_empresa:any={};
  departamentos:any[]=[];
  escuela:any;
  almacen:any={};

  mensaje_vista_modal:NgbModalRef;
  @ViewChild('mensaje_vista_modal')
  private mensaje_vista_modal_ref: TemplateRef<any>;

  closeResult='';
  sucursales:any[]=[];
  empresa:any={};
  sucursal_modificacion:any;

  tipo_gestiones_profesor:any={clases:[]};
  gestion_actual:any={};
  gestion_tab:any={};

  registro_dosificacion:NgbModalRef;

  datos_facturacion_sucursal:NgbModalRef;

  datos_sucursal:NgbModalRef;
  @ViewChild('datos_sucursal')
  private datos_sucursal_ref: TemplateRef<any>;

  datos_almacenes_sucursal:NgbModalRef;
  @ViewChild('datos_almacenes_sucursal')
  private datos_almacenes_sucursal_ref: TemplateRef<any>;

  registro_curso_materia:NgbModalRef;
  @ViewChild('registro_curso_materia')
  private registro_curso_materia_ref: TemplateRef<any>;

  registro_profesor_actual:any={};
  niveles_escolares:any[]=[];
  cursos:any[]=[];
  materias:any[]=[];
  estudiantes_vista:any[]=[];

  @ViewChildren('cursos') cursos_lectura: QueryList<any>;

  importacion_estudiantes:NgbModalRef;
  @ViewChild('importacion_estudiantes')
  private importacion_estudiantes_ref: TemplateRef<any>;

  estudiantes:any[]=[];

  edicion_estudiante:NgbModalRef;
  estudiante:any;
  mensaje_importacion:string;

  llantas_notificacion_modal:NgbModalRef;
  @ViewChild('llantas_notificacion_modal')
  private llantas_notificacion_modal_ref: TemplateRef<any>;

  aceites_notificacion_modal:NgbModalRef;
  @ViewChild('aceites_notificacion_modal')
  private aceites_notificacion_modal_ref: TemplateRef<any>;

  markers:any[];
  center:any= { lat: -17.39380008501286700000, lng: -66.15696068334569000000 };
  conexion_google_map:boolean=true;

  constructor(public persistenciaService:PersistenciaService,
            public router:Router,
            public generalService:GeneralService,
            private socket: Socket,
            public modalService: NgbModal,
            public empresasService:EmpresaService,
            private toastr: ToastrService,
            public usuarioService:UsuarioService,
            private movimientoInventarioService:MovimientoInventarioService,
            private cursosService:CursosService,
            private materiasService:MateriasService,
            private profesoresService:ProfesoresService,
            private estudiantesService:EstudiantesService,
            private calificacionesService:CalificacionesService,
            private vehiculosService:VehiculosService,
            public redesSocialesService:RedesSocialesService,
          public pdfService:PdfService) {
      super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
    if(this.usuario.empresa && this.usuario.empresa.usar_estudiantes_colegio){
      this.es_usuario_contabilidad=Util.tieneRol(GlobalVariable.Dictionary.ROL_CONTABILIDAD,this.usuario);
      this.es_usuario_profesor=Util.tieneRol(GlobalVariable.Dictionary.ROL_PROFESOR,this.usuario);
    }
    if(this.usuario.id_empresa){
      this.ids_sucursales=Util.obtenerIdsSucursales(this.usuario);
      let id_usuario=0;
      if(this.usuario.empresa.usar_control_por_usuarios){
        if(this.es_usuario_administrador){	
          id_usuario=0;
        }else{
          id_usuario=this.usuario.id;
        }
      }

      this.filtro={
        id_empresa:this.usuario.id_empresa,
        id_sucursal:this.ids_sucursales,
        id_usuario:id_usuario
      }

      if(this.usuario.empresa.usar_vencimientos){
        this.obtenerContadorVencimientoProductos();
      }

      if((this.usuario.empresa.usar_estudiantes_colegio || this.usuario.empresa.usar_estudiantes_instituto) && (this.es_usuario_administrador || this.es_usuario_contabilidad)){
        this.obtenerContadorAsuntosAdministrativos();
      }

      if(this.usuario.empresa.usar_vencimiento_economico_ventas){
        this.obtenerContadorVencimientoCreditos();
      }

      if(this.usuario.empresa.usar_vencimiento_economico_compras){
        this.obtenerContadorVencimientoDeudas();
      }

      if(this.usuario.empresa.usar_fechas_vencimientos_pagos_estudiantes && (this.es_usuario_administrador || this.es_usuario_contabilidad)){
        this.obtenerContadorVencimientoPagosEstudiantes();
        this.obtenerContadorVencimientoPagosEstudiantesAnulados();
      }

      if(this.usuario.empresa.usar_despachos || this.usuario.empresa.usar_pedidos_en_linea){
        this.obtenerContadorPedidosNotificacion();
      }

      if(this.usuario.empresa.usar_pedidos_restaurante || this.usuario.empresa.usar_pedidos_distribucion || this.usuario.empresa.usar_pedidos_en_linea){
        this.obtenerNumeroPedidoActual(this.usuario.sucursalesUsuario[0]);
      }

      if(this.usuario.empresa.usar_medicina_consultorio){
        this.obtenerNumeroFichaActual(this.usuario.sucursalesUsuario[0]);
      }

      if(this.usuario.empresa.usar_inventario_minimo){
        this.obtenerContadorInventarioMinimo();  
      }

      if(this.usuario.empresa.usar_mensajeria){
        this.obtenerContadorMensajes();
      }

      if(this.usuario.empresa.usar_mantenimiento_vehiculos){
        this.obtenerContadorLlantas();
        this.obtenerContadorAceites();
      }

      if(this.usuario.empresa.usar_contabilidad_semi_automatica){
        this.obtenerContadorVentasNoContabilizadas();
        this.obtenerContadorPagosVentaNoContabilizados();
      }

      if(this.usuario.empresa.usar_mensajeria_redes_sociales){
        this.usuario.empresa.uid=this.persistenciaService.get("uid_empresa_whatsapp_"+this.usuario.empresa.id);
      }

      if(this.usuario.empresa.usar_torneos){
        this.obtenerContadorJugadores();
      }

      this.configuracionSockets();

      let app_emp=this.usuario.aplicacionesUsuario.filter(app_usuario => app_usuario.aplicacion.codigo==GlobalVariable.Dictionary.CODIGO_APP_EMPRESAS);
      this.aplicacion_empresa= app_emp.length>0?app_emp[0]:{};
      this.obtenerEmpresa();
      this.obtenerSucursales();
    }
    this.establecerDatosApp();
  }

  ngAfterViewInit(){
    aplicarTitulos("registroprofesor");
    aplicarTitulos("estudiantes");
  }

  obtenerEmpresa(){
    this.empresasService.obtenerEmpresa(this.usuario.id_empresa).subscribe((empresa:any)=>{
      this.empresa=empresa;
    });
  }

  cerrarSesion(){
    this.blockUI.start('Saliendo... espere por favor!');
    if(this.usuario.empresa && this.usuario.empresa.usar_google_class_room){
      this.generalService.cargarGapiScript(GlobalVariable.GAPI_SCRIPT,false,this.usuario.empresa.usar_google_class_room).subscribe(() => {
        //if(gapi.auth2.getAuthInstance().isSignedIn.get()){
          gapi.auth2.getAuthInstance().signOut().then(function(rg){
            console.log(rg);
          });	
        //}
      });
    }
    this.usuario = null;
    this.persistenciaService.removerUsuario();
    window.localStorage.removeItem("ngStorage-usuario");//clear();
    window.localStorage.removeItem("ngStorage-token");
    window.localStorage.clear();
    this.router.navigateByUrl('/', {skipLocationChange: false}).then(()=>{
      this.blockUI.stop();
      this.router.navigate(['/']);
      window.location.reload()
    });
  }

  obtenerContadorVencimientoProductos(){
    this.generalService.obtenerContadorVencimientoProductos(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_vencimiento_productos_notificacion=dato.contador;
    });
  }

  obtenerContadorAsuntosAdministrativos(){
    this.generalService.obtenerContadorAsuntosAdministrativos(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_asuntos_administrativos_notificacion=dato.contador;
    });
  }

  obtenerContadorVencimientoCreditos(){
    this.generalService.obtenerContadorVencimientoCreditos(this.filtro).subscribe((vencimientosCreditos:any)=>{
      this.notificacion.contador_ventas_credito_notificacion=vencimientosCreditos.contador;
    });
  }

  obtenerContadorVencimientoPagosEstudiantes(){
    this.generalService.obtenerContadorVencimientoPagosEstudiantes(this.filtro).subscribe((vencimientosDeudas:any)=>{
      this.notificacion.contador_vencimientos_pagos_estudiantes_notificacion=vencimientosDeudas.contador;
    });
  }

  obtenerContadorVencimientoPagosEstudiantesAnulados(){
    this.generalService.obtenerContadorVencimientoPagosEstudiantesAnulados(this.filtro).subscribe((vencimientosDeudas:any)=>{
      this.notificacion.contador_vencimientos_pagos_estudiantes_anulados_notificacion=vencimientosDeudas.contador;
    });
  }

  obtenerContadorVencimientoDeudas(){
    this.generalService.obtenerContadorVencimientoDeudas(this.filtro).subscribe((vencimientosDeudas:any)=>{
      this.notificacion.contador_compras_credito_notificacion=vencimientosDeudas.contador;
    });
  }

  obtenerContadorPedidosNotificacion(){
    this.generalService.obtenerContadorPedidosNotificacion(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_pedidos_notificacion=dato.contador;
    });
  }

  obtenerContadorVentasNoContabilizadas(){
    this.generalService.obtenerContadorVentasNoContabilizadas(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_ventas_no_contabilizadas=dato.contador;
    });
  }

  obtenerContadorPagosVentaNoContabilizados(){
    this.generalService.obtenerContadorPagosVentaNoContabilizados(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_pagos_venta_no_contabilizados=dato.contador;
    });
  }

  obtenerContadorInventarioMinimo(){
    this.generalService.obtenerContadorInventarioMinimo(this.filtro).subscribe((dato:any)=>{
      this.notificacion.contador_productos_inventario_minimo=dato.contador;
    });
  }

  obtenerContadorMensajes(){
    this.generalService.obtenerContadorMensajes({id_usuario:this.usuario.id}).subscribe((dato:any)=>{
      this.notificacion.contador_mensajes=dato.contador;
      this.notificacion.ultimos_5_mensajes=dato.ultimos_5_mensajes;
    });
  }

  obtenerContadorLlantas(){
    this.vehiculosService.obtenerContadorLlantas(this.usuario.id_empresa).subscribe((dato:any)=>{
      this.notificacion.contador_llantas=dato.contador;
    });
  }

  obtenerContadorAceites(){
    this.vehiculosService.obtenerContadorAceites(this.usuario.id_empresa).subscribe((dato:any)=>{
      this.notificacion.contador_aceites=dato.contador;
    });
  }

  obtenerContadorJugadores(){
    this.generalService.obtenerContadorJugadores(this.usuario.id_empresa).subscribe((dato:any)=>{
      this.notificacion.contador_jugadores=dato.contador;
    });
  }

  sumarContadoresNotificacion(){
    return (this.notificacion.contador_ventas_credito_notificacion+
      this.notificacion.contador_compras_credito_notificacion+
      this.notificacion.contador_pedidos_notificacion+
      this.notificacion.contador_ventas_no_contabilizadas+
      this.notificacion.contador_pagos_venta_no_contabilizados+
      this.notificacion.contador_productos_inventario_minimo+
      this.notificacion.contador_vencimiento_productos_notificacion+
      this.notificacion.contador_mensajes+
      this.notificacion.contador_llantas+
      this.notificacion.contador_vencimientos_pagos_estudiantes_notificacion+
      this.notificacion.contador_vencimientos_pagos_estudiantes_anulados_notificacion+
      this.notificacion.contador_asuntos_administrativos_notificacion+
      this.notificacion.contador_jugadores);
  }

  configuracionSockets(){
    let me=this;
    this.socket.on('actualizarPedidos', (dato) => {
      if(me.usuario.empresa.usar_despachos || me.usuario.empresa.usar_pedidos_en_linea){
        me.obtenerContadorPedidosNotificacion();					
      }
      let sucursal_pedido=me.usuario.sucursalesUsuario.filter(us => us.id_sucursal==dato.id_sucursal);
      if(sucursal_pedido.length>0 && dato.pedido){
        sucursal_pedido[0].pedido_actual=dato.pedido.numero_pedido+1;
        sucursal_pedido[0].sucursal.pedido_correlativo=dato.pedido.numero_pedido+1;
        this.persistenciaService.setUsuario(this.usuario);
      }
    });

    this.socket.on('actualizarJugadores', (dato) => {
      if(me.usuario.empresa.usar_torneos){
        me.obtenerContadorJugadores();					
      }
    });

    this.socket.on('actualizarVentasCredito', function (dato) {
      if (me.usuario.id_empresa==dato.id_empresa && me.usuario.empresa.usar_vencimiento_economico_ventas) {
        me.obtenerContadorVencimientoCreditos();
      }
    });
    
    this.socket.on('actualizarComprasCredito', function (dato) {
      if (me.usuario.id_empresa==dato.id_empresa && me.usuario.empresa.usar_vencimiento_economico_compras) {
        me.obtenerContadorVencimientoDeudas();
      }
    });
    
    this.socket.on('actualizarMensajes', function (dato) {
      if(me.usuario.empresa.usar_mensajeria && dato.id_empresa==me.usuario.id_empresa){
        me.obtenerContadorMensajes();
      }
    });
    this.socket.on('actualizarInventarioMinimo', function (dato) {
      if(me.usuario.empresa.usar_inventario_minimo){
        me.obtenerContadorInventarioMinimo();
      }
    });  
    this.socket.on('actualizarAsuntosAdministrativos', function (dato) {
      if(dato.id_empresa==me.usuario.id_empresa){
        me.obtenerContadorAsuntosAdministrativos();
      }
    }); 
    this.socket.on('actualizarVencimientosPagosEstudiantes', function (dato) {
      if(dato.id_empresa==me.usuario.id_empresa){
        me.obtenerContadorVencimientoPagosEstudiantes();
        me.obtenerContadorVencimientoPagosEstudiantesAnulados();
      }
    });
    this.socket.on('actualizarVentasNoContabilizadas', function (dato) {
      if (me.usuario.id_empresa==dato.id_empresa && me.usuario.empresa.usar_contabilidad_semi_automatica) {
        me.obtenerContadorVentasNoContabilizadas();
      }
    });
    this.socket.on('actualizarPagosVentasNoContabilizados', function (dato) {
      if (me.usuario.id_empresa==dato.id_empresa && me.usuario.empresa.usar_contabilidad_semi_automatica) {
        me.obtenerContadorPagosVentaNoContabilizados();
      }
    });
    this.socket.on('actualizarCitasMedicas', (dato) => {console.log(dato);
      let sucursal_ficha=me.usuario.sucursalesUsuario.filter(us => us.id_sucursal==dato.id_sucursal);
      if(sucursal_ficha.length>0 && dato.cita_medica){
        if(dato.cita_medica.ficha_correlativo){
          sucursal_ficha[0].sucursal.ficha_correlativo=dato.cita_medica.ficha_correlativo;
          sucursal_ficha[0].ficha_actual=dato.cita_medica.ficha_correlativo;
        }
        if(dato.cita_medica.ficha_tratamiento_correlativo){
          sucursal_ficha[0].sucursal.ficha_tratamiento_correlativo=dato.cita_medica.ficha_tratamiento_correlativo;
          sucursal_ficha[0].ficha_actual=dato.cita_medica.ficha_tratamiento_correlativo;
        }
        this.persistenciaService.setUsuario(this.usuario);
      }
    });
    
  }

  abrirListaVencimientoCreditos(){
    this.modal_vencimiento_creditos = this.modalService.open(VencimientoCreditoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaVencimientoPagosEstudiantes(estado){
    this.modal_vencimiento_creditos = this.modalService.open(VencimientoPagosEstudiantesComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"vencimiento-estudiantes",scrollable:true});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
    this.modal_vencimiento_creditos.componentInstance.estado = estado;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaVencimientoDeudas(){
    this.modal_vencimiento_creditos = this.modalService.open(VencimientoDeudaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaProductosInventarioMinimo(){
    this.modal_vencimiento_creditos = this.modalService.open(InventarioMinimoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaLlantasNotificacion(){
    this.blockUI.start();
    this.vehiculosService.obtenerLlantasNotificacion(this.usuario.id_empresa).subscribe((datos:any[])=>{
      this.blockUI.stop();
      this.notificacion.notificacion_llantas=datos;
      this.llantas_notificacion_modal=this.modalService.open(this.llantas_notificacion_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
      this.llantas_notificacion_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  abrirListaAsuntosAdministrativosNotificacion(){
    this.modal_vencimiento_creditos = this.modalService.open(AsuntosAdministrativosComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
    this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
    
    this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
      this.modal_vencimiento_creditos.close();
    });
  }

  abrirListaAceitesNotificacion(){
    this.blockUI.start();
    this.vehiculosService.obtenerAceitesNotificacion(this.usuario.id_empresa).subscribe((datos:any[])=>{
      this.blockUI.stop();
      this.notificacion.notificacion_aceites=datos;
      this.aceites_notificacion_modal=this.modalService.open(this.aceites_notificacion_modal_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable:true});
      this.aceites_notificacion_modal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  abrirListaVencimientoProductos(){
    this.modal_vencimiento_creditos = this.modalService.open(VencimientoProductoComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaPedidos(){
    this.modal_vencimiento_creditos = this.modalService.open(PedidosNotificacionComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  obtenerNumeroPedidoActual(sucursal_usuario){console.log(sucursal_usuario);
    this.pedido_actual_sucursal=sucursal_usuario;
    this.empresasService.obtenerNumeroPedidoSucursal(this.pedido_actual_sucursal.id_sucursal).subscribe((dato:any)=>{
      this.pedido_actual_sucursal.pedido_actual=dato.numero_pedido_actual;
    });
  }

  obtenerNumeroFichaActual(sucursal_usuario){
    this.ficha_actual_sucursal=sucursal_usuario;
    this.empresasService.obtenerNumeroFichaSucursal(this.ficha_actual_sucursal.id_sucursal).subscribe((dato:any)=>{
      this.ficha_actual_sucursal.ficha_actual=dato.numero_ficha_actual;
    });
  }

  abrirPopupConfirmacionReinicializacion(pedido_sucursal){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = '¿Esta seguro de reiniciar el correlativo de pedidos?';
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reiniciarCorrelativo(pedido_sucursal);
			}
			this.popupConfirmacion.close();
    });
	}

  reiniciarCorrelativo(pedido_sucursal){
    this.blockUI.start();
    let sucursal=pedido_sucursal.sucursal;
    sucursal.pedido_correlativo=1;
    this.empresasService.reiniciarNumeroPedidoSucursal(sucursal).subscribe((res:any)=>{
      pedido_sucursal.pedido_actual=1;
      this.persistenciaService.setUsuario(this.usuario);
      this.blockUI.stop();
      this.toastr.success('Correlativo reiniciado satisfactoriamente!');
    });
  }

  abrirPopupConfirmacionReinicializacionFicha(ficha_sucursal){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = '¿Esta seguro de reiniciar el correlativo de fichas?';
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reiniciarCorrelativoFichas(ficha_sucursal);
			}
			this.popupConfirmacion.close();
    });
	}

  reiniciarCorrelativoFichas(parametro){
    this.blockUI.start();
    let sucursal=parametro.sucursal_usuario.sucursal;
    sucursal.ficha_correlativo=parametro.ficha_correlativo;
    sucursal.ficha_tratamiento_correlativo=parametro.ficha_tratamiento_correlativo;
    this.empresasService.reiniciarNumeroFichaSucursal(sucursal).subscribe((res:any)=>{
      parametro.sucursal_usuario.ficha_actual=1;
      this.persistenciaService.setUsuario(this.usuario);
      this.blockUI.stop();
      this.toastr.success('Correlativo reiniciado satisfactoriamente!');
    });
  }

  subirDatosServidor(){
    this.blockUI.start();
    this.empresasService.sincronizacionSubirDatosServidor().subscribe((res:any)=>{
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
      }
    });
  }

  abrirModalDescargaDatosServidor(){
    this.descarga_servidor={
      empresa:false,
      tipos:false,
      productos:false,
      usuarios:false,
      clientes:false,
      sucursales:false,
      dosificaciones:false,
      configuracion_impresion:false,
      aplicaciones:false,
      modulos:false
		}
		this.descargaDatos=this.modalService.open(this.descargaDatosRef, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.descargaDatos.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
			}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
  }

  descargarDatosServidor(){
    this.blockUI.start();
    this.empresasService.sincronizacionDescargarDatosServidor(this.descarga_servidor).subscribe((res:any)=>{
      this.blockUI.stop();
      this.descargaDatos.close();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
      }
    });
  }

  leerMensaje(mensaje){
    if(mensaje.mensaje.clase.nombre_corto==GlobalVariable.Dictionary.TIPO_MENSAJE_TRASPASO){
      this.blockUI.start();
      this.movimientoInventarioService.obtenerMovimientoInventario(JSON.parse(mensaje.mensaje.dato).id).subscribe((datos:any)=>{
        this.movimiento_inventario_mensaje=datos.movimiento_inventario;
        this.blockUI.stop();
      });
    }
    this.mensaje=mensaje;
    this.mensaje_vista_modal=this.modalService.open(this.mensaje_vista_modal_ref, {windowClass:'vista-mensaje', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.mensaje_vista_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
    mensaje.leido=true;
    this.blockUI.start();
    this.usuarioService.actualizarMensajeLeido(mensaje).subscribe((res:any)=>{
      this.blockUI.stop();
    });
  }

  verTodosMensajes(){
    this.router.navigateByUrl('/mensajeria', {skipLocationChange: false}).then(()=>{
      this.router.navigate(['/mensajeria']);
      //window.location.reload()
    });
  }

  public getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
		  return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
		  return 'by clicking on a backdrop';
		} else {
		  return `with: ${reason}`;
		}
  }

  obtenerSucursales(){
    this.sucursales=Util.obtenerSucursalesUsuario(this.usuario);
    /*this.empresasService.obtenerSucursalesEmpresa((sucursales:any[])=>{
      this.sucursales=sucursales;
    });*/
  }

  modificarDatosFacturacionSucursal(sucursal_a_modificar){
    this.datos_facturacion_sucursal=this.modalService.open(SucursalFacturacionComponent, {windowClass:'sucursal-facturacion', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.datos_facturacion_sucursal.componentInstance.sucursal_modificacion = sucursal_a_modificar;
		
		this.datos_facturacion_sucursal.componentInstance.alTerminar.subscribe((res) => {
			this.datos_facturacion_sucursal.close();
		});
  }

  modificarDatosSucursal(sucursal_a_modificar){
    this.obtenerDepartamentos();
    this.blockUI.start();
    this.empresasService.obtenerSucursal(sucursal_a_modificar.id).subscribe((sucursal:any)=>{
      this.blockUI.stop();
      this.sucursal_modificacion=sucursal;

      try{
        let latitud=this.sucursal_modificacion.latitud?this.sucursal_modificacion.latitud:-17.39380008501286700000,longitud=this.sucursal_modificacion.longitud?this.sucursal_modificacion.longitud:-66.15696068334569000000;
        let bounds = new google.maps.LatLngBounds();
        let markers = [];
        let posicion_marcador=new google.maps.LatLng(latitud,longitud);
        bounds.extend(posicion_marcador);
        markers.push({
          position: {
            lat: latitud,
            lng: longitud,
          },
          label: {
            color: 'red',
          },
          options: { 
            labelAnchor: "5 55",
            labelClass: 'marker-label', 
            draggable: true
          },
        });
        
        this.markers = markers;
        this.center={ lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
      }catch(e){
        this.conexion_google_map=false;
        this.toastr.error("No se pudo conectar con google maps!")
      }

      this.datos_sucursal=this.modalService.open(this.datos_sucursal_ref, {windowClass:'sucursal-facturacion', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
      this.datos_sucursal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      aplicarPluginImagen("imagen-sucursal");
    });
  }

  arrastarPinSucursal(event) {
		this.sucursal_modificacion.latitud = event.latLng.lat();
		this.sucursal_modificacion.longitud = event.latLng.lng();
	}

  subirImagenSucursal(e){
		let me=this;
		let filesSelected =  e.target.files;
		if (filesSelected.length > 0)
		{
			var fileToLoad = filesSelected[0];
			var fileReader = new FileReader();

			fileReader.onloadend = function() 
			{
				let base64EncodedImage = fileReader.result; // <--- data: base64
				me.sucursal_modificacion.imagen=base64EncodedImage;
			}
			fileReader.readAsDataURL(fileToLoad);	
		}
  }

  obtenerDepartamentos(){
		this.generalService.obtenerClases("DEP").subscribe((entidad:any)=>{
      this.departamentos=entidad?entidad.clases:[];
    });
  }

  validarDatosSucursal(){
    let res=true;
		if(!this.sucursal_modificacion.nombre){
      res=res && false;
      this.toastr.error("Debe especificar el nombre de la sucursal!");
    }
    if(!(this.sucursal_modificacion.numero==0) && !this.sucursal_modificacion.numero){
      res=res && false;
      this.toastr.error("Debe especificar el número de sucursal!");
    }
    if(!this.sucursal_modificacion.direccion){
      res=res && false;
      this.toastr.error("Debe especificar la dirección de la sucursal!");
    }
    if(!this.sucursal_modificacion.departamento){
      res=res && false;
      this.toastr.error("Debe especificar la ciudad de la sucursal!");
    }
		return res;
  }

  guardarDatosSucursal(){
    this.blockUI.start();
    if(this.validarDatosSucursal()){
      this.empresasService.guardarDatosSucursal(this.sucursal_modificacion).subscribe((res:any)=>{
        this.blockUI.stop();
        this.datos_sucursal.close();
        this.toastr.success(res.mensaje);
      });
    }else{
      this.blockUI.stop();
    }
}


  async modificarDatosCursosMaterias(){
    this.blockUI.start();
    let gestion_actual=(new Date().getFullYear()).toString();
    this.escuela=await this.profesoresService.obtenerEscuela(this.usuario.id_empresa).toPromise();
    this.tipo_gestiones_profesor=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    this.tipo_gestiones_profesor.clases=this.tipo_gestiones_profesor.clases?this.tipo_gestiones_profesor.clases:[];
    if(this.tipo_gestiones_profesor.clases.filter(g => g.nombre==gestion_actual).length==0){
      this.tipo_gestiones_profesor.clases.push({nombre:gestion_actual,nombre_corto:gestion_actual});
      await this.generalService.actualizarTipo(this.tipo_gestiones_profesor).toPromise();
      this.tipo_gestiones_profesor=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    }
    this.gestion_actual=this.tipo_gestiones_profesor.clases.filter(g => g.nombre==gestion_actual)[0];
    this.establecerGestionTab(this.gestion_actual);
    this.blockUI.stop();
    this.datoscursosmaterias=this.modalService.open(this.datoscursosmateriasRef, {windowClass:'registro-curso-materia', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable: true });
    this.datoscursosmaterias.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  establecerGestionTab(gestion){
    this.gestion_tab=gestion
    if(!this.gestion_tab.registros_profesor){
      this.profesoresService.obtenerRegistrosProfesorGestion(this.usuario.id_persona,this.gestion_tab.id).subscribe((registros_profesor:any[])=>{
        this.gestion_tab.registros_profesor=registros_profesor;
      });
    }
  }

  async crearNuevoRegistroProfesor(){
    this.obtenerNivelesEscolares();
    this.registro_profesor_actual={
      nivel_escolar:null,
      id_tipo_calculo:this.escuela.id_tipo_calculo_calificacion,
      configuracion:JSON.stringify({dimensiones:await this.calificacionesService.obtenerConfiguracionCalificacion(this.gestion_actual.id,this.usuario.id_empresa,0).toPromise()})
    }
    this.registro_curso_materia=this.modalService.open(this.registro_curso_materia_ref, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.registro_curso_materia.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  obtenerNivelesEscolares(){
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"SCHNE").subscribe((entidad:any)=>{
      this.niveles_escolares=entidad.clases;
    });
  }

  obtenerCursosMateriasNivel(nivel_escolar){
    this.registro_profesor_actual.curso=null;
    this.registro_profesor_actual.materia=null;
    this.obtenerCursos(nivel_escolar.id);
    this.obtenerMaterias(nivel_escolar.id);
  }

  obtenerCursos(id_nivel_escolar){
    this.cursosService.obtenerCursosNivel(this.usuario.id_empresa,id_nivel_escolar).subscribe((cursos:any[])=>{
      this.cursos=cursos;
    });
  }

  obtenerMaterias(id_nivel_escolar){
    this.materiasService.obtenerMateriasNivel(this.usuario.id_empresa,id_nivel_escolar).subscribe((materias:any[])=>{
      this.materias=materias;
    });
  }

  validarRegistroProfesor(){
    let res=true;
    if(!this.registro_profesor_actual.nivel_escolar){
      res=res && false;
      this.toastr.error("Debe especificar el nivel escolar!");
    }
    if(!this.registro_profesor_actual.curso){
      res=res && false;
      this.toastr.error("Debe especificar el curso!");
    }
    if(!this.registro_profesor_actual.materia){
      res=res && false;
      this.toastr.error("Debe especificar la materia!");
    }
    if(this.gestion_tab.registros_profesor.filter(reg=> (reg.nivel_escolar.id==this.registro_profesor_actual.nivel_escolar.id) && (reg.materia.id==this.registro_profesor_actual.materia.id) && (reg.curso.id==this.registro_profesor_actual.curso.id) && !reg.eliminado).length>0){
      res=res && false;
      this.toastr.error("Ya existe un registro con los parametros seleccionados!");
    }
    return res;
  }

  agregarRegistroProfesor(){
    if(this.validarRegistroProfesor()){
      this.gestion_actual.registros_profesor.push(this.registro_profesor_actual);
      this.registro_curso_materia.close();
    }
  }

  guardarRegistrosProfesor(gestion){
    this.blockUI.start();
    this.materiasService.guardarRegistrosProfesor({id_persona:this.usuario.id_persona,gestion:gestion}).subscribe((res:any)=>{
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.toastr.success(res.mensaje);
        this.datoscursosmaterias.close();
        window.location.reload();
      }
    });
  }

  eliminarRegistroProfesor(registro_profesor){
    registro_profesor.eliminado=true;
  }

  async modificarDatosCursos(){
    this.blockUI.start();
    let gestion_actual=(new Date().getFullYear()).toString();
    this.tipo_gestiones_profesor=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    if(this.tipo_gestiones_profesor.clases.filter(g => g.nombre==gestion_actual).length==0){
      this.tipo_gestiones_profesor.clases.push({nombre:gestion_actual,nombre_corto:gestion_actual});
      await this.generalService.actualizarTipo(this.tipo_gestiones_profesor).toPromise();
      this.tipo_gestiones_profesor=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_GESTIONES).toPromise();
    }
    this.gestion_actual=this.tipo_gestiones_profesor.clases.filter(g => g.nombre==gestion_actual)[0];
    this.establecerGestionTabCursos(this.gestion_actual);
    this.escuela=await this.profesoresService.obtenerEscuela(this.usuario.id_empresa).toPromise();
    await this.establecerPrefijoCodigoColegio();
    this.blockUI.stop();
    this.datoscursos=this.modalService.open(this.datoscursosRef, {windowClass:'lista-estudiantes', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable: true });
    this.datoscursos.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  async establecerPrefijoCodigoColegio(){
    let tipo_codigo:any=await this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_ESTUDIANTE).toPromise();
    if(!tipo_codigo.clases || tipo_codigo.clases.length==0){
      tipo_codigo.clases=[{nombre:this.usuario.empresa.id.toString()+this.usuario.empresa.nombre.substring(0,3),nombre_corto:"1",habilitado:true}];
    }else{
      tipo_codigo.clases[0].nombre=this.usuario.empresa.id.toString()+this.usuario.empresa.nombre.substring(0,3);
    }
    await this.generalService.actualizarTipo(tipo_codigo).toPromise();
  }

  establecerGestionTabCursos(gestion){
    this.gestion_tab=gestion;
    this.registro_profesor_actual={}
    if(!this.gestion_tab.registros_profesor){
      this.materiasService.obtenerCursosProfesorGestion(this.usuario.id_persona,this.gestion_tab.id).subscribe((registros_profesor:any[])=>{
        this.gestion_tab.registros_profesor=registros_profesor;
        /*let me=this;
        this.cursos_lectura.changes.subscribe(t => {console.log(t);
          for(let i=0;i<me.gestion_tab.registros_profesor.length;i++){
            aplicarTitulos((i)+"impest");
            aplicarTitulos((i)+"expest");
            aplicarTitulos((i)+"listest");
          }
        });*/
      });
    }
  }

  descargarPlantillaFiliacion (registro_profesor) {
		this.blockUI.start();
		var data = [];
		var cabecera=["CÓDIGO RUDE", "APELLIDO PATERNO", "APELLIDO MATERNO","NOMBRES","GÉNERO M/F", "TELÉFONO FIJO", "TELÉFONO MÓVIL","CORREO ELECTRÓNICO"];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(registro_profesor.curso);

    let headerRow = worksheet.addRow(cabecera);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '4167B8' },
        bgColor: { argb: '' }
      }
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFF' },
        size: 12
      }
    })

    data.forEach(d => {
      let row = worksheet.addRow(d);
    });

    const dobCol1 = worksheet.getColumn(1);
    const dobCol2 = worksheet.getColumn(2);
    const dobCol3 = worksheet.getColumn(3);
    const dobCol4 = worksheet.getColumn(4);
    const dobCol5 = worksheet.getColumn(5);
    const dobCol6 = worksheet.getColumn(6);
    const dobCol7 = worksheet.getColumn(7);
    const dobCol8 = worksheet.getColumn(8);
    dobCol1.width = 20;
    dobCol2.width = 25;
    dobCol3.width = 25;
    dobCol4.width = 25;
    dobCol5.width = 20;
    dobCol6.width = 20;
    dobCol7.width = 20;
    dobCol8.width = 25;

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, "PLANTILLA FILIACIÓN-"+registro_profesor.curso+".xlsx");
      this.blockUI.stop();
    });
  }
  
  subirPlanillaFiliacion(event,registro_profesor){
    this.blockUI.start();
    this.registro_profesor_actual=registro_profesor;
		let me=this;
		let files = event.target.files;
		let i,f;
		for (i = 0, f = files[i]; i != files.length; ++i) {
			let reader = new FileReader();
			let name = f.name;
			reader.onload = function(e) {
				//this.blockUI.start();
			  let data = e.target.result;
			  let workbook = XLSX.read(data, {type: 'binary'});
				let first_sheet_name = workbook.SheetNames[0];
				let row=2,i=0;
				let worksheet = workbook.Sheets[first_sheet_name];
				me.estudiantes=[];
				me.mensaje_importacion="";
				do {
					let estudiante:any = {persona:{}};
					estudiante.codigo_rude = worksheet['A' + row] != undefined && worksheet['A' + row] != "" ? worksheet['A' + row].v.toString() : null;
					estudiante.persona.apellido_paterno = worksheet['B' + row] != undefined && worksheet['B' + row] != "" ? worksheet['B' + row].v.toString() : null;
          estudiante.persona.apellido_materno = worksheet['C' + row] != undefined && worksheet['C' + row] != "" ? worksheet['C' + row].v.toString() : null;
          let nombres= worksheet['D' + row] != undefined && worksheet['D' + row] != "" ? worksheet['D' + row].v.toString() : null;
          if(!nombres){
            me.mensaje_importacion=me.mensaje_importacion+" - La casilla D"+row+" debe tener un valor";
          }
          estudiante.persona.nombres = worksheet['D' + row] != undefined && worksheet['D' + row] != "" ? worksheet['D' + row].v.toString() : null;
          let genero= worksheet['E' + row] != undefined && worksheet['E' + row] != "" ? worksheet['E' + row].v.toString() : null;
          if(!(genero.toUpperCase()=="M" || genero.toUpperCase()=="F")){
            me.mensaje_importacion=me.mensaje_importacion+" - La casilla E"+row+" debe tener como valor M o F";
          }
          estudiante.persona.genero= genero.toUpperCase();
					estudiante.persona.telefono_fijo = worksheet['F' + row] != undefined && worksheet['F' + row] != "" ? worksheet['F' + row].v.toString() : null;
          estudiante.persona.telefono_movil = worksheet['G' + row] != undefined && worksheet['G' + row] != "" ? worksheet['G' + row].v.toString() : null;
          estudiante.persona.correo_electronico = worksheet['H' + row] != undefined && worksheet['H' + row] != "" ? worksheet['H' + row].v.toString() : null;
					/*var fecha_vencimiento = null;
					if (worksheet['H' + row] != undefined && worksheet['F' + row] != "") {
						if (typeof worksheet['H' + row].v === 'number') {
							if (worksheet['H' + row].v % 1 === 0) {
								fecha_vencimiento = new Date((worksheet['F' + row].v - (25567 + 1)) * 86400 * 1000);
							}
						} else {
							fecha_vencimiento = Util.convertirTextoAfecha(worksheet['H' + row].v);
						}
					}*/
					me.estudiantes.push(estudiante);
					row++;
					i++;
				} while (worksheet['D'+row]!=undefined);
				me.importacion_estudiantes=me.modalService.open(me.importacion_estudiantes_ref, {windowClass:"importacion-estudiantes",ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',scrollable: true});
				me.importacion_estudiantes.result.then((result) => {
					me.closeResult = `Closed with: ${result}`;
				}, (reason) => {
					me.closeResult = `Dismissed ${me.getDismissReason(reason)}`;
				});
				me.blockUI.stop();
			};
			reader.readAsBinaryString(f);
	    }  
  }
  
  guardarImportacionEstudiantes(){
    this.blockUI.start();
    this.estudiantesService.guardarImportacionEstudiantes({estudiantes:this.estudiantes,id_curso:this.registro_profesor_actual.id_curso,id_gestion:this.gestion_tab.id,id_empresa:this.usuario.id_empresa,id_escuela:this.escuela.id}).subscribe((res:any)=>{
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
      }else{
        this.gestion_tab.registros_profesor=null;
        this.establecerGestionTabCursos(this.gestion_tab);
        this.importacion_estudiantes.close();
        this.toastr.success(res.mensaje);
      }
    });
  }

  verListaEstudiantes(registro_profesor){
    this.registro_profesor_actual=registro_profesor;
    this.registro_profesor_actual.estudiantes_vista=[];
    this.blockUI.start();
    this.estudiantesService.obtenerListaEstudiantes(this.gestion_tab.id,registro_profesor.id_curso).subscribe((estudiantes:any[])=>{
      this.blockUI.stop();
      this.registro_profesor_actual.estudiantes_vista=estudiantes;
    });
  }

  crearNuevoEstudiante(){
		this.estudiante={persona:{usuario:{},cliente:{}},inscripciones_colegio:[]};
		this.abrirRegistroEstudiante();
	}

  abrirRegistroEstudiante(){
		this.edicion_estudiante = this.modalService.open(RegistroEstudianteComponent, {windowClass : "wizard_edicion_estudiante",ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static'});
		this.edicion_estudiante.componentInstance.estudiante = this.estudiante;
    this.edicion_estudiante.componentInstance.usuario = this.usuario;
    this.edicion_estudiante.componentInstance.gestion = this.gestion_tab;
    this.edicion_estudiante.componentInstance.curso = {id:this.registro_profesor_actual.id_curso,nombre:this.registro_profesor_actual.curso};
		
		this.edicion_estudiante.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.verListaEstudiantes(this.registro_profesor_actual);
			}
			this.edicion_estudiante.close();
    });
  }
  
  modificarEstudiante(estudiante){
    this.blockUI.start();
    this.estudiantesService.obtenerEstudiante(estudiante.id).subscribe((res:any)=>{
      this.blockUI.stop();
      this.estudiante=res;
		  this.abrirRegistroEstudiante();
    });
  }

  anularInscripcion(estudiante){
    this.blockUI.start();
    this.estudiantesService.anularInscripcion(estudiante.id,this.gestion_tab.id,this.registro_profesor_actual.id_curso).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
		  this.verListaEstudiantes(this.registro_profesor_actual);
    });
  }

  abrirPopupConfirmacionEliminacionInscripcion(estudiante){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular la inscripcion del estudiante?";
		this.popupConfirmacion.componentInstance.data = estudiante;
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.anularInscripcion($e.data);
			}
			this.popupConfirmacion.close();
    });
  }

  modificarDatosAlmacenesSucursal(sucursal_a_modificar){
    this.blockUI.start();
    this.empresasService.obtenerSucursal(sucursal_a_modificar.id).subscribe((sucursal:any)=>{
      this.blockUI.stop();
      this.sucursal_modificacion=sucursal;
      this.datos_almacenes_sucursal=this.modalService.open(this.datos_almacenes_sucursal_ref, {windowClass:'sucursal-facturacion', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
      this.datos_almacenes_sucursal.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    });
  }

  agregarAlmacen(almacen){
    let res=true;
    if(!almacen.nombre){
      res=false;
      this.toastr.error("Debe especificar el nombre del almacen!");
    }
    if(res){
      this.sucursal_modificacion.almacenes.push(almacen);
      this.almacen={};
    }
  }

  removerAlmacen(almacen) {
    almacen.eliminado = true;
  }

  guardarAlmacenesSucursal(){
    this.blockUI.start();
    this.empresasService.guardarAlmacenesSucursal(this.sucursal_modificacion).subscribe((res:any)=>{
      this.blockUI.stop();
      this.datos_almacenes_sucursal.close();
      this.toastr.success(res.mensaje);
    });
}

abrirRuleta(){
  this.modal_vencimiento_creditos = this.modalService.open(RuletaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static',windowClass:'ruleta'});
  this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
  this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
    this.modal_vencimiento_creditos.close();
  });
}

abrirRegistroAsistencia(){
  this.modal_vencimiento_creditos = this.modalService.open(RegistroAsistenciaComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
  this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
  this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
    this.modal_vencimiento_creditos.close();
  });
}

abrirPagosServiciosBasicos(){
  this.modal_vencimiento_creditos = this.modalService.open(PagosServiciosBasicosComponent, {windowClass:'pago-servicios', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
  this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
  this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
    this.modal_vencimiento_creditos.close();
  });
}

  abrirConfiguracionWhatsapp(){
    this.modal_whatsapp_qr = this.modalService.open(WhatsappConfiguracionComponent, {ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
    this.modal_whatsapp_qr.componentInstance.usuario = this.usuario;
    this.modal_whatsapp_qr.componentInstance.empresa = this.usuario.empresa;
    this.modal_whatsapp_qr.componentInstance.alTerminar.subscribe((res) => {
      this.modal_whatsapp_qr.close();
    });
  }

  abrirConversacionWhatsapp(sucursal){
    this.modal_chat_whatsapp = this.modalService.open(ConversacionWhatsappComponent, {windowClass:'chat-chatsapp', ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',scrollable:true});
    this.modal_chat_whatsapp.componentInstance.usuario = this.usuario;
    this.modal_chat_whatsapp.componentInstance.sucursal = sucursal;
    this.modal_chat_whatsapp.componentInstance.chats = [];
    this.modal_chat_whatsapp.componentInstance.alTerminar.subscribe((res) => {
      this.modal_chat_whatsapp.close();
    });
  }

  abrirPopupConfirmacionDesconexionClienteWhatsapp(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = '¿Esta seguro de desconectar el cliente de whatsapp?';
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.desconectarClienteWhatsapp();
			}
			this.popupConfirmacion.close();
    });
	}

  abrirPopupConfirmacionReinicioServidor(){
		this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
		this.popupConfirmacion.componentInstance.message = '¿Esta seguro de reiniciar el servidor?';
		
		this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
			if($e.confirm){
				this.reconectarClienteWhatsapp();
			}
			this.popupConfirmacion.close();
    });
	}

  reconectarClienteWhatsapp(){
    this.blockUI.start();
    this.redesSocialesService.solicitarReconexionClienteWhatsapp({id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  desconectarClienteWhatsapp(){
    this.blockUI.start();
    this.redesSocialesService.desconectarClienteWhatsapp({id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.usuario.empresa.conexion_redes_sociales=null;
      this.persistenciaService.delete("uid_empresa_whatsapp_"+this.usuario.id_empresa);
      this.blockUI.stop();
      this.toastr.success(res.mensaje);
    });
  }

  async modificarDatosFacturacionEmpresa(empresa_a_modificar){
		this.empresa=await this.empresasService.obtenerEmpresa(empresa_a_modificar.id).toPromise();
		this.modal_datos_facturacion_empresa = this.modalService.open(EmpresaFacturacionComponent, {windowClass:'empresa-facturacion', ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_datos_facturacion_empresa.componentInstance.empresa = this.empresa;
		this.modal_datos_facturacion_empresa.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modal_datos_facturacion_empresa.close();
				}
			}else{
				this.modal_datos_facturacion_empresa.close();
			}
		});
	}

  verificarComunicacionSin(){
    this.blockUI.start();
    this.empresasService.verificarComunicacion({id_empresa:this.usuario.id_empresa}).subscribe((res:any)=>{
      this.blockUI.stop();
      if(res.tiene_error){
        this.toastr.error(res.mensaje);
        this.toastr.error(res.stack);
      }else{
        this.toastr.success(res.mensaje);
      }
    });
  }

  async verListaCursosColegio(){
		this.modal_lista_cursos_colegio = this.modalService.open(CursosComponent, {windowClass:'lista-cursos', scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_lista_cursos_colegio.componentInstance.empresa = this.empresa;
		this.modal_lista_cursos_colegio.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modal_lista_cursos_colegio.close();
				}
			}else{
				this.modal_lista_cursos_colegio.close();
			}
		});
	}

  async verListaMateriasColegio(){
		this.modal_lista_materias_colegio = this.modalService.open(MateriasComponent, {windowClass:'lista-materias', scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_lista_materias_colegio.componentInstance.empresa = this.empresa;
		this.modal_lista_materias_colegio.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.toastr.success(res.mensaje);
					this.modal_lista_materias_colegio.close();
				}
			}else{
				this.modal_lista_materias_colegio.close();
			}
		});
	}

  async verPlantillasMensajesEmpresa(){
		this.modal_lista_plantillas_mensajes = this.modalService.open(MensajesEmpresaComponent, {windowClass:'lista-mensajes', scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'md', backdrop: 'static'});
		this.modal_lista_plantillas_mensajes.componentInstance.empresa = this.empresa;
		this.modal_lista_plantillas_mensajes.componentInstance.alTerminar.subscribe((res) => {
			this.modal_lista_plantillas_mensajes.close();
		});
	}

  abrirActualizacionClave(){
    this.modal_actualizacion_clave = this.modalService.open(ActualizacionClaveUsuarioComponent, {scrollable:true,ariaLabelledBy: 'modal-basic-title',size: 'sm', backdrop: 'static'});
		this.modal_actualizacion_clave.componentInstance.usuario_datos = this.usuario;
		this.modal_actualizacion_clave.componentInstance.alTerminar.subscribe((res) => {
			this.modal_actualizacion_clave.close();
		});
  }

  abrirListaVentasNoContabilizadas(){
    this.modal_vencimiento_creditos = this.modalService.open(VentasNoContabilizadasComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  abrirListaPagosVentasNoContabilizadas(){
    this.modal_vencimiento_creditos = this.modalService.open(PagosVentasNoContabilizadosComponent, {ariaLabelledBy: 'modal-basic-title',size: 'lg', backdrop: 'static',windowClass:"ventas-credito-popup"});
		this.modal_vencimiento_creditos.componentInstance.usuario = this.usuario;
		
		this.modal_vencimiento_creditos.componentInstance.alTerminar.subscribe((res) => {
			this.modal_vencimiento_creditos.close();
		});
  }

  async generarPdfDatosEstudiantes(){
    let filter={
      gestion:this.gestion_tab,
      nivel:this.registro_profesor_actual.nivel_escolar,
      curso:{nombre:this.registro_profesor_actual.curso}
    }
    await this.pdfService.imprimirDatosEstudiantes(this.registro_profesor_actual.estudiantes_vista,filter);
  }

  abrirListaJugadoresCarnet(){
    this.modal_impresion_tarjetas_jugador = this.modalService.open(TarjetasJugadorComponent, {windowClass:'tarjetas-jugador',scrollable:true,ariaLabelledBy: 'modal-basic-title', backdrop: 'static'});
		this.modal_impresion_tarjetas_jugador.componentInstance.es_desde_notificacion = true;
    this.modal_impresion_tarjetas_jugador.componentInstance.usuario = this.usuario;
		
		this.modal_impresion_tarjetas_jugador.componentInstance.alTerminar.subscribe((res) => {
			this.modal_impresion_tarjetas_jugador.close();
		});
  }


}
