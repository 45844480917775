import { Injectable } from '@angular/core';
import { GlobalVariable } from 'src/app/global';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {

  constructor(private http: HttpClient) { }

  public obtenerListaProductos(paginator){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos/empresa/"+paginator.filter.id_empresa+"/pagina/"+paginator.currentPage+"/items-pagina/"+paginator.itemsPerPage+"/busqueda/"+paginator.search+"/columna/"+paginator.column+"/direccion/"+paginator.direction+"/grupo/"+paginator.filter.grupo.id+"/subgrupo/"+paginator.filter.subgrupo.id,GlobalVariable.httpOptions);
  }

  public guardarProductos(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"productos/empresa",datos,GlobalVariable.httpOptions);
  }

  public obtenerSiguienteCodigo(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"producto/empresa/"+id_empresa+"/siguiente-codigo",GlobalVariable.httpOptions);
  }

  public guardarProducto(producto){
    return this.http.post(GlobalVariable.API_SERVER_URL+"productos",producto,GlobalVariable.httpOptions);
  }

  public obtenerProducto(id_producto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos/"+id_producto,GlobalVariable.httpOptions);
  }

  public actualizarProducto(producto){
    return this.http.put(GlobalVariable.API_SERVER_URL+"productos/"+producto.id,producto,GlobalVariable.httpOptions);
  }

  public eliminarProducto(producto){
    return this.http.delete(GlobalVariable.API_SERVER_URL+"productos/"+producto.id,GlobalVariable.httpOptions);
  }

  public actualizarAtributoProducto(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"actualizar-atributo-productos",datos,GlobalVariable.httpOptions);
  }

  public obtenerContadorVencimientosProductos(id_producto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos/"+id_producto,GlobalVariable.httpOptions);
  }

  public guardarProductosPrecios(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"productos-precios/empresa",datos,GlobalVariable.httpOptions);
  }

  public buscarProducto(id_empresa,texto){
    if (texto === '') {
      return of([]);
    }
    return this.http
      .get(GlobalVariable.API_SERVER_URL+"productos/empresa/"+id_empresa+"/texto/"+texto,GlobalVariable.httpOptions);

    //return this.http.get(GlobalVariable.API_SERVER_URL+"productos/empresa/"+id_empresa+"/texto/"+texto,GlobalVariable.httpOptions);
  }

  public obtenerPreciosProductos(id_producto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-precios/"+id_producto,GlobalVariable.httpOptions);
  }

  public obtenerPreciosProductosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-precios/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerInventariosProductos(ids_productos,id_almacen){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-inventario/"+ids_productos+"/almacen/"+id_almacen,GlobalVariable.httpOptions);
  }

  public obtenerProductosRestringidosUsuario(id_usuario){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-restriccion-usuario/"+id_usuario,GlobalVariable.httpOptions);
  }

  public obtenerProductosSubGrupo(id_subgrupo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-subgrupo/"+id_subgrupo,GlobalVariable.httpOptions);
  }

  public obtenerProductosEmpresa(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerProductosPanel(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos-panel/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerMovimientosProducto(filter,id_producto,fecha_inicial,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"productos/kardex/"+id_producto+"/sucursal/"+filter.sucursal.id+"/almacen/"+filter.almacen.id+"/fecha-inicial/"+fecha_inicial+"/fecha-final/"+fecha_fin+"/lote/"+((filter.lote=="" || filter.lote==undefined)?0:filter.lote),GlobalVariable.httpOptions);
  }

  public obtenerKardexMovimientosProducto(filter,id_producto,fecha_inicial,fecha_fin){
    return this.http.get(GlobalVariable.API_SERVER_URL+"kardex-producto/producto/"+id_producto+"/almacen/"+filter.almacen.id+"/fecha-inicial/"+fecha_inicial+"/fecha-final/"+fecha_fin,GlobalVariable.httpOptions);
  }

  public obtenerProductoSucursal(id_empresa,id_producto,id_sucursal){
    return this.http.get(GlobalVariable.API_SERVER_URL+"producto-sucursal/empresa/"+id_empresa+"/producto/"+id_producto+"/sucursal/"+id_sucursal,GlobalVariable.httpOptions);
  }

  public actualizarProductoSucursal(producto_sucursal){
    return this.http.put(GlobalVariable.API_SERVER_URL+"producto-sucursal/"+producto_sucursal.id,producto_sucursal,GlobalVariable.httpOptions);
  }

  public actualizarPreciosProductosGrupos(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"precios-productos-grupo",datos,GlobalVariable.httpOptions);
  }

  public guardarRelacionProductosSin(datos){
    return this.http.post(GlobalVariable.API_SERVER_URL+"productos-sin/empresa",datos,GlobalVariable.httpOptions);
  }

  public obtenerProductosSin(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"sin-productos-servicios/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public guardarCategoriaTienda(categoria_tienda){
    return this.http.post(GlobalVariable.API_SERVER_URL+"categoria-tienda",categoria_tienda,GlobalVariable.httpOptions);
  }

  public actualizarCategoriaTienda(categoria_tienda){
    return this.http.put(GlobalVariable.API_SERVER_URL+"categoria-tienda/"+categoria_tienda.id,categoria_tienda,GlobalVariable.httpOptions);
  }

  public obtenerCategoriaTienda(id_grupo){
    return this.http.get(GlobalVariable.API_SERVER_URL+"categoria-tienda/grupo/"+id_grupo,GlobalVariable.httpOptions);
  }

  public guardarSuplementosProducto(datos){
    return this.http.put(GlobalVariable.API_SERVER_URL+"suplementos/producto/"+datos.id_producto,datos,GlobalVariable.httpOptions);
  }

  public obtenerSuplementosProducto(id_producto){
    return this.http.get(GlobalVariable.API_SERVER_URL+"suplementos/producto/"+id_producto,GlobalVariable.httpOptions);
  }

  public obtenerTarifasAgua(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"tarifas-agua/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

  public obtenerPruebasLaboratorio(id_empresa){
    return this.http.get(GlobalVariable.API_SERVER_URL+"pruebas/empresa/"+id_empresa,GlobalVariable.httpOptions);
  }

}
