<div class="modal-header card-header">
    <div class="col-md-8 col-xs-8">
        <span class="text-blue text-125">Registro Laboratorio</span>
    </div>
    <div class="col-md-6 col-xs-6">
        <button class="btn btn-primary" (click)="guardarLaboratorio()" >
            <i class="fa fa-save text-110 mr-1"></i>
        </button>&nbsp;
        <button class="btn btn-danger" type="button" (click)="cerrarComponente()">
            <i class="fa fa-times red2 text-110 mr-1"></i>
        </button>
    </div>
</div>
<div class="modal-body card-body">
    <div class="row">
        <div class="col-md-1 col-2">
            <label for="form-field-select-1">Paciente</label>
        </div>
        <div class="col-md-3 col-8">
          <ng-container *ngIf="!dato_paciente_lista">
            <ng-container *ngIf="laboratorio.paciente.id">
              <span class="m-1 bgc-blue-l3 border-1 brc-blue-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{laboratorio.paciente.cliente.identificacion}}</span><br>
            </ng-container>
            <input id="paciente_texto" name="paciente_texto" *ngIf="!laboratorio.paciente.id"
            [inputFormatter]="formatter" [resultTemplate]="rt" (selectItem)="establecerPaciente($event)"
            type="text" class="form-control" [(ngModel)]="laboratorio.paciente_texto" 
            [ngbTypeahead]="buscarPaciente" placeholder="Buscar paciente" autocomplete="off"
            />
            <ng-template #rt let-r="result" let-t="term">
              <ngb-highlight [result]="r.cliente.identificacion" [term]="t"></ngb-highlight>
            </ng-template>
          </ng-container>
          <select *ngIf="dato_paciente_lista" [compareWith]="compararObjectos" class="form-control" required name="paciente" [(ngModel)]="laboratorio.paciente">
            <option [ngValue]="paciente" *ngFor="let paciente of pacientes">{{paciente.cliente.persona.nombres}} {{paciente.cliente.persona.apellido_paterno}} {{paciente.cliente.persona.apellido_materno}}</option>
          </select>
        </div>
        <div class="col-md-2 p-0 col-2" *ngIf="!inhabilitar_paciente">
          <a *ngIf="!laboratorio.paciente.id" title="Nuevo Paciente" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-primary btn-a-outline-primary btn-text-primary" href="javascript:void(0)" (click)="crearNuevoPaciente()">
            <i class="fas fa-plus"></i>
          </a>
          <a *ngIf="laboratorio.paciente.id" href="javascript:void(0)" (click)="modificarPaciente(laboratorio.paciente)" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success">
              <i class="fa fa-edit bigger-130"></i>
          </a>
          <a *ngIf="laboratorio.paciente.id" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-danger btn-a-outline-danger btn-text-danger" href="javascript:void(0)" (click)="reiniciarPaciente()">
            <i class="fa fa-trash bigger-130 red2"></i>
          </a>
          <a title="Presentacion Paciente" class="btn radius-1 btn-sm btn-brc-tp btn-outline-default btn-h-outline-success btn-a-outline-success btn-text-success" href="javascript:void(0)" (click)="cambiarPresentacionPaciente()">
            <i class="fas fa-exchange-alt"></i>
          </a>
        </div>
        <div class="col-md-3 col-5" *ngIf="laboratorio.paciente.cliente.persona">
            <label for="form-field-select-1">Edad <span class="m-1 bgc-success-l3 border-1 brc-success-m3 text-dark-tp3 text-100 px-25 py-3px radius-round">{{laboratorio.paciente.cliente.persona.fecha_nacimiento?util.calcularEdad(laboratorio.paciente.cliente.persona.fecha_nacimiento):''}}</span></label>
        </div>
        <div class="col-sm-12 col-md-1 col-2">
            <label>Fecha</label>
        </div>
        <div class="col-sm-12 col-md-2 col-5">
            <div class="input-group">
                <input [disabled]="inhabilitar_fecha" required id="feini" name="birthday" class="form-control" placeholder="dd/mm/yyyy"
                        name="dp" [(ngModel)]="laboratorio.fecha_texto" ngbDatepicker #fi="ngbDatepicker">
                <div class="input-group-append">
                    <button [disabled]="inhabilitar_fecha" class="btn btn-outline-secondary calendar" (click)="fi.toggle()" type="button"></button>
                </div>
            </div>  
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="col-sm-12 col-md-1">
            <label>Observación</label>
        </div>
        <div class="col-sm-12 col-md-5">
            <textarea class="form-control" rows="2" cols="50" name="editordata" [(ngModel)]="laboratorio.observacion" placeholder="Observación"></textarea>
        </div>
        <div class="col-3 col-md-1">
            <label>Solicitud</label>
        </div>
        <div class="col-3 col-md-2">
            <ng-container *ngIf="laboratorio.solicitud">
                <a title="Solicitud" class="btn btn-md" [ngClass]="{'btn-purple':(laboratorio.solicitud.includes('.zip') || laboratorio.solicitud.includes('.rar')),'btn-danger':laboratorio.solicitud.includes('.pdf'),'btn-success':(laboratorio.solicitud.includes('.xls') || laboratorio.solicitud.includes('.xlsx')),'btn-primary':(laboratorio.solicitud.includes('.doc') || laboratorio.solicitud.includes('.docx') || laboratorio.solicitud.includes('.jpg') || laboratorio.solicitud.includes('.png') || laboratorio.solicitud.includes('.gif') || laboratorio.solicitud.includes('.jpeg') || laboratorio.solicitud.includes('.mp3') || laboratorio.solicitud.includes('.mpeg') || laboratorio.solicitud.includes('.mp4'))}" target="_blank" href="{{rest_server+laboratorio.solicitud}}">
                    <i class="fas" [ngClass]="{'fa-file-archive':(laboratorio.solicitud.includes('.zip') || laboratorio.solicitud.includes('.rar')),'fa-file-pdf':laboratorio.solicitud.includes('.pdf'),'fa-file-excel':(laboratorio.solicitud.includes('.xls') || laboratorio.solicitud.includes('.xlsx')),'fa-file-word':(laboratorio.solicitud.includes('.doc') || laboratorio.solicitud.includes('.docx')),'fa-file-image':(laboratorio.solicitud.includes('.jpg') || laboratorio.solicitud.includes('.png') || laboratorio.solicitud.includes('.gif') || laboratorio.solicitud.includes('.jpeg')),'fa-file-audio':(laboratorio.solicitud.includes('.mp3') || laboratorio.solicitud.includes('.mpeg')),'fa-file-video':(laboratorio.solicitud.includes('.mp4'))}"></i>
                </a>
            </ng-container>
            <ng-container *ngIf="!laboratorio.solicitud || laboratorio.solicitud.length>300">
                <form id="form-documentos-laboratorio" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                    <div class="fallback d-none">
                        <input name="file" type="file" multiple (change)="subirArchivo($event)"/>
                    </div>
    
                    <div class="dz-default dz-message" style="margin: 0px !important;">
                        <span class="text-150  text-grey-d2">
                        <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                        </span>
                    </div>
                </form>
    
                    <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                <div id="template-documentos-laboratorio" class="d-none">
                    <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                        <div class="dz-image">
                        <img alt="Archivo" data-dz-thumbnail="" />
                        </div>
    
                        <div class="dz-details">
                        <div class="dz-size">
                            <span data-dz-size=""></span>
                        </div>
    
                        <div class="dz-filename">
                            <span data-dz-name=""></span>
                        </div>
                        </div>
    
                        <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                        </div>
    
                        <div class="dz-error-message">
                        <span data-dz-errormessage=""></span>
                        </div>
    
                        <div class="dz-success-mark">
                        <span class="fa-stack fa-lg text-150">
                            <i class="fa fa-circle fa-stack-2x text-white"></i>
                            <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                        </span>
                        </div>
    
                        <div class="dz-error-mark">
                        <span class="fa-stack fa-lg text-150">
                            <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                            <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                        </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <div class="col-3 col-md-1">
            <label>Resultado</label>
        </div>
        <div class="col-3 col-md-2">
            <ng-container *ngIf="laboratorio.resultado">
                <a title="Resultado" class="btn btn-md" [ngClass]="{'btn-purple':(laboratorio.resultado.includes('.zip') || laboratorio.resultado.includes('.rar')),'btn-danger':laboratorio.resultado.includes('.pdf'),'btn-success':(laboratorio.resultado.includes('.xls') || laboratorio.resultado.includes('.xlsx')),'btn-primary':(laboratorio.resultado.includes('.doc') || laboratorio.resultado.includes('.docx') || laboratorio.resultado.includes('.jpg') || laboratorio.resultado.includes('.png') || laboratorio.resultado.includes('.gif') || laboratorio.resultado.includes('.jpeg') || laboratorio.resultado.includes('.mp3') || laboratorio.resultado.includes('.mpeg') || laboratorio.resultado.includes('.mp4'))}" target="_blank" href="{{rest_server+laboratorio.resultado}}">
                    <i class="fas" [ngClass]="{'fa-file-archive':(laboratorio.resultado.includes('.zip') || laboratorio.resultado.includes('.rar')),'fa-file-pdf':laboratorio.resultado.includes('.pdf'),'fa-file-excel':(laboratorio.resultado.includes('.xls') || laboratorio.resultado.includes('.xlsx')),'fa-file-word':(laboratorio.resultado.includes('.doc') || laboratorio.resultado.includes('.docx')),'fa-file-image':(laboratorio.resultado.includes('.jpg') || laboratorio.resultado.includes('.png') || laboratorio.resultado.includes('.gif') || laboratorio.resultado.includes('.jpeg')),'fa-file-audio':(laboratorio.resultado.includes('.mp3') || laboratorio.resultado.includes('.mpeg')),'fa-file-video':(laboratorio.resultado.includes('.mp4'))}"></i>
                </a>
            </ng-container>
            <ng-container *ngIf="!laboratorio.resultado || laboratorio.resultado.length>300">
                <form id="form-resultado-laboratorio" action="/upload" style="min-height: 0px !important; padding: 0px !important;" class="dropzone bgc-white brc-dark-l3 brc-h-primary-m4 bgc-h-primary-l4 radius-1">
                    <div class="fallback d-none">
                        <input name="file" type="file" multiple (change)="subirArchivoResultado($event)"/>
                    </div>
    
                    <div class="dz-default dz-message" style="margin: 0px !important;">
                        <span class="text-150  text-grey-d2">
                        <i class="upload-icon fas fa-cloud-upload-alt text-blue-m1 fa-1x mt-4"></i>
                        </span>
                    </div>
                </form>
    
                    <!-- the `html` of this element will be used by dropzone instance for previewing files/images -->
                <div id="template-resultado-laboratorio" class="d-none">
                    <div class="dz-preview dz-file-preview" style="margin: 0px !important; min-height: 50px !important;">
                        <div class="dz-image">
                        <img alt="Archivo" data-dz-thumbnail="" />
                        </div>
    
                        <div class="dz-details">
                        <div class="dz-size">
                            <span data-dz-size=""></span>
                        </div>
    
                        <div class="dz-filename">
                            <span data-dz-name=""></span>
                        </div>
                        </div>
    
                        <div class="dz-progress progress border-1 brc-yellow-tp2" style="height: 0.75rem;">
                        <div class="progress-bar bgc-success dz-upload " role="progressbar" data-dz-uploadprogress=""></div>
                        </div>
    
                        <div class="dz-error-message">
                        <span data-dz-errormessage=""></span>
                        </div>
    
                        <div class="dz-success-mark">
                        <span class="fa-stack fa-lg text-150">
                            <i class="fa fa-circle fa-stack-2x text-white"></i>
                            <i class="fa fa-check fa-stack-1x fa-inverse text-success-m1"></i>
                        </span>
                        </div>
    
                        <div class="dz-error-mark">
                        <span class="fa-stack fa-lg text-150">
                            <i class="fa fa-circle fa-stack-2x text-danger-m3"></i>
                            <i class="fas fa-exclamation fa-stack-1x fa-inverse text-white"></i>
                        </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
    <hr>
    <div class="row" *ngIf="!inhabilitar_busqueda_item">
        <div class="col-sm-12 col-md-2 col-12">
            <label>Laboratorio</label>
        </div>
        <div class="col-sm-12 col-md-7 col-12">
            <input id="id_producto" autocomplete="off"
            [disabled]="laboratorio.producto && laboratorio.producto.id" [(ngModel)]="producto_busqueda"
            [ngbTypeahead]="buscarProducto" placeholder="Buscar prueba" [resultTemplate]="rtp" name="producto_busqueda"
            [inputFormatter]="formatterProducto"  (selectItem)="establecerProducto($event)"
            type="text" class="form-control"/>
            <ng-template #rtp let-r="result" let-t="term">
                <img [src]="rest_server+r.imagen" class="mr-1" style="width: 16px">
                <ngb-highlight [result]="r.codigo+'-'+r.nombre+'-'+(r.grupo?r.grupo.nombre:'')+'-'+(r.subgrupo?r.subgrupo.nombre:'')" [term]="t"></ngb-highlight>
            </ng-template>
        </div>
        <div class="col-sm-12 col-md-1 col-6">
            <a href="javascript:void(0)" *ngIf="laboratorio.producto && laboratorio.producto.id" (click)="iniciarAdhseionLaboratorio()" class="card-toolbar-btn text-danger-m1">
                <i class="ace-icon fa fa-trash bigger-130"></i>
            </a>
        </div>
        <div class="col-sm-12 col-md-1 col-6">
            <button type="button" *ngIf="laboratorio.producto && laboratorio.producto.id" (click)="agregarPrueba()" class="btn btn-success btn-sm">
                <i class="ace-icon fa fa-plus bigger-110"></i>
            </button>
        </div>
        <div class="col-sm-12 col-md-1 col-6">
            <button type="button" class="btn btn-info btn-sm" (click)="obtenerPruebasLaboratorio()">
                <i class="ace-icon fa fa-search-plus bigger-110"></i>
            </button>
        </div>
    </div>
    <hr>
    <div class="row">
        <div class="card-body bg-white p-0" *ngIf="laboratorio.pruebas.length>0">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <tr>
                        <th>
                            <label>Nº</label>
                        </th>
                        <th>
                            <label>Prueba</label>
                        </th>
                        <th>
                            <label>Grupo</label>
                        </th>
                        <th>
                            <label>Subgrupo</label>
                        </th>
                        <th>
                            <label>Total (Bs)</label>
                        </th>
                        <th style="text-align: center;" data-field="tools">
                            <div class="th-inner "><i class="fa fa-cog text-secondary-d1 text-130"></i></div>
                            <div class="fht-cell"></div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let prueba of laboratorio.pruebas; let i=index;">
                        <tr  *ngIf="!prueba.eliminado">
                            <td>
                                {{i+1}}
                            </td>
                            <td>
                                {{prueba.prueba.nombre}}
                            </td>
                            <td>
                                {{prueba.prueba.grupo.nombre}}
                            </td>
                            <td>
                                {{prueba.prueba.subgrupo.nombre}}
                            </td>
                            <td>
                                {{prueba.prueba.precio_unitario.toFixed(2)}}
                            </td>
                            <td>
                                <a (click)="abrirModalRegistroResultados(prueba)" class="card-toolbar-btn text-success-m1" href="javascript:void(0)">
                                    <i class="fas fa-poll"></i>
                                </a>
                                <a (click)="generarPdfResultados(prueba)" class="card-toolbar-btn text-danger-m1" href="javascript:void(0)">
                                    <i class="fas fa-file-pdf"></i>
                                </a>
                                <a *ngIf="!inhabilitar_eliminacion_detalle" (click)="eliminarPrueba(prueba)" class="card-toolbar-btn text-danger-m1" href="javascript:void(0)">
                                    <i class="fa fa-trash bigger-130"></i>
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            
                        </td>
                        <td>
                            <b>Total (Bs)</b>
                        </td>
                        <td class="text-nowrap">
                            <span class="m-1 bgc-danger-l3 border-1 brc-danger-m3 text-dark-tp3 text-180 px-25 py-3px radius-round">
                                {{sumarPrecios()}}
                            </span>
                        </td>
                        <td>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<ng-template #modal_seleccion_pruebas let-modal>
    <div class="modal-header card-header">
        <div class="col-md-6 col-xs-6">
            <span class="text-blue text-125">Hoja de Pruebas</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-3" *ngFor="let sub_grupo of sub_grupos">
                <fieldset class="text-110 bgc-success-l3 text-success-d4 px-3 py-15 radius-1">
                    <legend >{{sub_grupo.nombre}}</legend>
                    <div class="row">
                        <div class="col-12" *ngFor="let prueba of sub_grupo.productosSubGrupo">
                            <label>
                                <input name="switch-field-1" (change)="agregarPruebaFormulario(prueba)" class="ace-switch input-lg ace-switch-sino bgc-blue-d1" type="checkbox" [(ngModel)]="prueba.seleccionada" />
                                {{prueba.nombre}}
                            </label>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>    
</ng-template>

<ng-template #modal_registro_resultados let-modal>
    <div class="modal-header card-header">
        <div class="col-md-6 col-xs-6">
            <span class="text-blue text-125">Registro de Resultados: {{prueba.prueba.nombre}}</span>
        </div>
        <div class="col-md-6 col-xs-6">
            <button class="btn btn-primary" (click)="guardarResultadosPrueba()" >
                <i class="fa fa-save text-110 mr-1"></i>
            </button>&nbsp;
            <button class="btn btn-danger" type="button" (click)="modal.dismiss('Cross click')">
            <i class="fa fa-times red2 text-110 mr-1"></i>
            </button>
        </div>
    </div>
    <div class="modal-body card-body">
        <div class="row">
            <div class="col-12">
                <table class="table table-striped table-bordered table-hover" *ngIf="prueba.resultado.length>0">
                    <thead>
                        <tr>
                            <th>
                                <label>Nº</label>
                            </th>
                            <th>
                                <label>Clasificacion</label>
                            </th>
                            <th>
                                <label>Etiqueta Resultado</label>
                            </th>
                            <th>
                                <label>Dentro rango</label>
                            </th>
                            <th>
                                <label>Fuera Rango</label>
                            </th>
                            <th>
                                <label>Rango Referencia</label>
                            </th>
                            <th>
                                <label>Orden</label>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let resultado_prueba of prueba.resultado | orderBy:'orden'; let i=index;">
                            <tr *ngIf="!resultado_prueba.eliminado">
                                <td>
                                    {{i+1}}
                                </td>
                                <td>
                                    {{resultado_prueba.clasificacion?resultado_prueba.clasificacion.nombre:''}}
                                </td>
                                <td>
                                    {{resultado_prueba.resultado_etiqueta}}
                                </td>
                                <td>
                                    <input type="text" name="resultado" [(ngModel)]="resultado_prueba.dentro_rango" placeholder="Resultado" class="form-control form-control-lg col-12"  />
                                </td>
                                <td>
                                    <input type="text" name="resultado" [(ngModel)]="resultado_prueba.fuera_rango" placeholder="Resultado" class="form-control form-control-lg col-12"  />
                                </td>
                                <td>
                                    {{resultado_prueba.rango_referencia}}
                                </td>
                                <td>
                                    {{resultado_prueba.orden}}
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    </div>    
</ng-template>