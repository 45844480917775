import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { ClientesService } from '../../../snapquick/services/clientes/clientes.service';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';

@Component({
  selector: 'app-registro-cliente',
  templateUrl: './registro-cliente.component.html',
  styleUrls: ['./registro-cliente.component.css']
})
export class RegistroClienteComponent extends BaseComponent implements OnInit {

  @Output() alTerminar:EventEmitter<any>  = new EventEmitter<any>();

  cliente:any;
  usuario:any;
  sucursal:any;
  sucursales:any[];
  global_variable:any=GlobalVariable;
  sin_documentos_identidad:any[]=[];
  @BlockUI() blockUI: NgBlockUI;
  tipo_categoria:any={};

  constructor(public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public clienteService:ClientesService,
    public toastr: ToastrService,
    public modalService: NgbModal,
    public usuarioService:UsuarioService) {
      super(persistenciaService,modalService,generalService,usuarioService);
     }

  async ngOnInit(): Promise<void> {
    this.filter={categorias:[]};
    this.cliente.id_empresa=this.usuario.id_empresa;
    this.cliente.id_usuario=this.usuario.id;
    this.cliente.sucursal=this.sucursal;
    if(this.cliente.id){
      await this.obtenerCodigoAutomatico(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
      if(!this.cliente.codigo){
        let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
        this.cliente.codigo = codigo_nuevo;
      }
    }else{
      let codigo_nuevo=await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
      this.cliente.codigo = codigo_nuevo;
      this.cliente.nit="0";
      this.cliente.id_empresa=this.usuario.id_empresa;
      this.cliente.latitud=-17.39380008501286700000;
      this.cliente.longitud=-66.15696068334569000000;
      this.cliente.crear_cuenta_contabilidad=this.usuario.empresa.usar_contabilidad;
    }

    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      this.obtenerTiposDocumentosIdentidad();
    }
    this.obtenerCategoriasClientesEmpresa();
  }

  ngAfterViewInit(){
    this.obtenerTextosEmpresa();
  }

  obtenerTiposDocumentosIdentidad(){
    this.generalService.obtenerClases(GlobalVariable.Dictionary.SIN.DOCUMENTOS_IDENTIDAD).subscribe((res:any)=>{
      this.sin_documentos_identidad=res?res.clases:[];
    });
  }

  async generarCodigoCliente(){
    let dato:any=await this.clienteService.verSiguienteCodigo(this.usuario.id_empresa).toPromise();
    return "CLI" + ((dato.ultimo_codigo ? dato.ultimo_codigo : 0) + 1);
  }

  cerrarComponente(){
    this.alTerminar.emit();
  }

  establecerRazonSocial(){
    if(!this.cliente.razon_social){
      this.cliente.razon_social=this.cliente.identificacion;
    }
  }

  validarCliente(){
    let res=true;
    if(!this.cliente.identificacion){
      res=res && false;
      this.toastr.error("Debe especificar la identificacion del cliente!");
    }
    if(this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      if(!this.cliente.tipo_documento){
        res=res && false;
        this.toastr.error("Debe especificar el tipo de documento del cliente!");
      }
    }
    if(this.usuario.empresa.usar_facturacion || this.usuario.empresa.usar_facturacion_computarizada_en_linea){
      if(!this.cliente.razon_social){
        res=res && false;
        this.toastr.error("Debe especificar la razon social del cliente!");
      }
      if(!this.cliente.nit){
        res=res && false;
        this.toastr.error("Debe especificar el nit/ci del cliente!");
      }
    }
    return res;
  }

  async guardarRegistroCliente(){
    if(this.validarCliente()){
      this.blockUI.start();
      if (this.cliente.id) {
        this.clienteService.actualizarCliente(this.cliente).subscribe((res:any) => {
          this.blockUI.stop();
          res.cliente=this.cliente;
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      } else {
        this.cliente.codigo=this.codigo_automatico?await this.obtenerUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE):this.cliente.codigo;
        this.clienteService.guardarCliente(this.cliente).subscribe((res:any) => {
          this.actualizarUltimoCodigo(GlobalVariable.Dictionary.TIPO_CORRELATIVO_CODIGO_CLIENTE);
          this.blockUI.stop();
          this.toastr.success(res.mensaje);
          this.alTerminar.emit(res);
        });
      }
    }
  }

  obtenerCategoriasClientesEmpresa(){
    this.blockUI.start();
    this.generalService.obtenerClasesEmpresa(this.usuario.id_empresa,"CATEGORIAS CLIENTES").subscribe((tipoCategoriaClientes:any)=>{
      this.tipo_categoria=tipoCategoriaClientes;
      this.filter.categorias=tipoCategoriaClientes.clases;
      this.blockUI.stop();
    });
  }

}
