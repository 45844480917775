import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { GlobalVariable } from 'src/app/global';
import { BaseComponent } from 'src/app/models/base-component';
import { PageConfiguration } from 'src/app/models/page-configuration';
import { GeneralService } from '../../../base/services/general/general.service';
import { PersistenciaService } from '../../../base/services/persistencia/persistencia.service';
import { UsuarioService } from '../../../base/services/usuario/usuario.service';
import { MovimientoInventarioService } from '../../services/movimiento-inventario/movimiento-inventario.service';
import { Util } from 'src/app/utils/utils';
import { VentasService } from '../../../base/services/ventas/ventas.service';
import { PdfService } from '../../../base/services/pdf/pdf.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { RegistroMovimientoInventarioComponent } from '../registro-movimiento-inventario/registro-movimiento-inventario.component';
import { ConfiguracionAplicacionMovimientoInventarioComponent } from 'src/app/base/components/configuracion-aplicacion-movimiento-inventario/configuracion-aplicacion-movimiento-inventario.component';
import { PopupConfirmacionComponent } from 'src/app/base/components/popup-confirmacion/popup-confirmacion.component';
import { InventarioConversionComponent } from '../inventario-conversion/inventario-conversion.component';

declare const PDFDocument:any;
declare const blobStream:any;

@Component({
  selector: 'app-movimiento-inventario',
  templateUrl: './movimiento-inventario.component.html',
  styleUrls: ['./movimiento-inventario.component.css']
})
export class MovimientoInventarioComponent extends BaseComponent implements OnInit {

  con_detalle:boolean=true;
  movimientos_inventario:any[]=[];
  @BlockUI() blockUI: NgBlockUI;
  movimiento_inventario:any;

  configuracion_aplicacion_modal:NgbModalRef;
  @ViewChild('configuracion_aplicacion_modal')
  private configuracion_aplicacion_ref: TemplateRef<any>;

  vista_detalle_modal:NgbModalRef;
  @ViewChild('vista_detalle_modal')
  private vista_detalle_ref: TemplateRef<any>;

  popup_registro_movimiento:NgbModalRef;
  conversion_inventario:NgbModalRef;

  constructor(private router: Router,
    public generalService:GeneralService,
    public persistenciaService:PersistenciaService,
    public usuarioService:UsuarioService,
    public modalService: NgbModal,
  private toastr: ToastrService,
  private ventasService:VentasService,
  private pdfService:PdfService,
  private movimientoInventarioService: MovimientoInventarioService) {
    super(persistenciaService,modalService,generalService,usuarioService);
  }

  ngOnInit(): void {
    this.init();
    this.buscarSubAplicacion(this.router.url.substring(1));

    let fecha_actual=new Date();
    this.filter={
      id_empresa:this.usuario.id_empresa,
      ids_sucursales_usuario:Util.obtenerIdsSucursales(this.usuario),
      sucursales:[{nombre:"TODOS",id:0}].concat(Util.obtenerSucursalesUsuario(this.usuario)),
      sucursal: {nombre:"TODOS",id:0},
      usuarios:[{nombre_usuario:"TODOS",id:0}],
      usuario: {nombre_usuario:"TODOS",id:0},
      tipos_pago:[{nombre:"TODOS",id:0}],
      tipo_pago: {nombre:"TODOS",id:0},
      transacciones:[{nombre:"TODOS",id:0}],
      transaccion: {nombre:"TODOS",id:0},
      canales:[{nombre:"TODOS",id:0}],
      canal: {nombre:"TODOS",id:0},
      fecha_inicio:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      fecha_fin:{
        year: fecha_actual.getFullYear(), 
        month: (fecha_actual.getMonth()+1),
        day:fecha_actual.getDate()
      },
      cliente:{}
    }
	this.obtenerConfiguracionPagina(GlobalVariable.Dictionary.CODIGO_APP_MOVIMIENTOS_INVENTARIO);

    let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
		if(this.usuario.empresa.usar_control_por_usuarios){
			if(es_usuario_administrador){	
				this.obtenerUsuarios();
			}else{
				this.filter.usuarios=[this.usuario]
				this.filter.usuario=this.usuario;
			}
		}else{
			this.obtenerUsuarios();
    }

    this.obtenerTransacciones();
    
    this.column = "fecha";
		this.direction = "desc";
		this.itemsPerPage=0;
  }

  obtenerUsuarios(){
    this.usuarioService.obtenerUsuariosEmpresa(this.usuario.id_empresa).subscribe((usuarios_empresa:any[]) => {
      this.filter.usuarios=this.filter.usuarios.concat(usuarios_empresa);
    });
  }

  obtenerTransacciones(){
    this.blockUI.start();
    this.generalService.obtenerClases("MOVEGR").subscribe((entidad:any)=>{
      this.generalService.obtenerClases("MOVING").subscribe((entidadIng:any)=>{
        this.filter.transacciones=this.filter.transacciones.concat(entidadIng.clases.filter(e => (e.nombre_corto == GlobalVariable.Dictionary.ING_INV_INICIAL || e.nombre_corto == GlobalVariable.Dictionary.ING_AJUSTE || e.nombre_corto == GlobalVariable.Dictionary.ING_COMPRA || e.nombre_corto == GlobalVariable.Dictionary.ING_PRODUCCION )));
		this.filter.transacciones=this.filter.transacciones.concat(
			entidad.clases.filter(e => 
				(e.nombre_corto == GlobalVariable.Dictionary.EGRE_PRODUCCION  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_AJUSTE  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_BAJA  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_CONSUMO  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_PUNTO_VENTA  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_VENTA_PERSONAL  || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_MERMA || 
				 e.nombre_corto == GlobalVariable.Dictionary.EGRE_FALTA_INVENTARIO || 
				 (e.nombre_corto == GlobalVariable.Dictionary.EGRE_TRASPASO && this.usuario.empresa.usar_sucursales))
			));

		let transacciones=this.filter.transacciones;
		if(this.usuario.empresa.usar_permisos_avanzados_usuarios){
			transacciones=[];
			transacciones=this.configuracion_pagina.object.datos.opciones_permisos.transacciones_asignadas.length==0?transacciones:[];
			for(let i=0;i<this.configuracion_pagina.object.datos.opciones_permisos.transacciones_asignadas.length;i++){
				transacciones.push(this.filter.transacciones.filter(cnf => cnf.id==this.configuracion_pagina.object.datos.opciones_permisos.transacciones_asignadas[i])[0]);
			}
			let es_usuario_administrador=Util.esUsuarioAdministrador(this.usuario);
			this.filter.transacciones=es_usuario_administrador?this.filter.transacciones.concat(transacciones):transacciones;
			this.filter.transaccion=this.filter.transacciones[0];
		}
		this.blockUI.stop();
      });
    });
  }
  
  getItems(){
		this.blockUI.start('Recuperando... espere por favor!');
		this.movimientoInventarioService.obtenerListaMovimientosInventario(this).subscribe((entidades:any[]) => {
			this.setPages(1);
			this.movimientos_inventario=entidades;
			this.blockUI.stop();
		});
  }

  imprimirMovimientoInventario(movimiento_inventario){
    this.blockUI.start()
    this.movimientoInventarioService.obtenerMovimientoInventario(movimiento_inventario.id).subscribe((datos:any)=>{
      var movimientoInventarioConsultado=datos.movimiento_inventario;
			movimientoInventarioConsultado.sucursal=datos.sucursal;
			movimientoInventarioConsultado.numero_literal=datos.numero_literal;
			movimientoInventarioConsultado.pieFactura=datos.pieFactura;
			movimientoInventarioConsultado.sucursalDestino=datos.sucursalDestino;
			var fecha=new Date(movimientoInventarioConsultado.fecha);
      movimientoInventarioConsultado.fechaTexto=fecha.getDate()+"/"+(fecha.getMonth()+1)+"/"+fecha.getFullYear();
	  this.pdfService.imprimirMovimientoInventario(movimientoInventarioConsultado,this.usuario);
	  this.blockUI.stop()
    });
  }
  
  imprimirFiltroMovimientos(){
    this.blockUI.start();
    let fechaInicio=this.filter.fecha_inicio.day+"/"+(this.filter.fecha_inicio.month-1)+"/"+this.filter.fecha_inicio.year;
    let fechaFin=this.filter.fecha_fin.day+"/"+(this.filter.fecha_fin.month-1)+"/"+this.filter.fecha_fin.year;
    let movimientos_inventario=this.movimientos_inventario;
		var doc = new PDFDocument({size:[612,792],margin:0});
		var stream = doc.pipe(blobStream());
		var itemsPorPagina=20;
		if(this.con_detalle){
			var c=movimientos_inventario.length*2;
			for(var i=0; i<movimientos_inventario.length;i++){
				c=c+movimientos_inventario[i].detallesMovimientoInventario.length;						
			}
			var totalPaginas=Math.ceil(c/(itemsPorPagina));
		}else{
			var itemsPorPagina=20;
			var totalPaginas=Math.ceil(movimientos_inventario.length/itemsPorPagina);	
		}
		var y=100,items=0,pagina=1;
		this.imprimirCabeceraFiltroMovimientos(doc,1,totalPaginas,movimientos_inventario,fechaInicio,fechaFin);

		doc.font('Helvetica',7);
		
		for(var i=0;i<movimientos_inventario.length;i++){
			doc.font('Helvetica',7);
			doc.rect(50,y+9,520,0).stroke();
			
			doc.text(i+1,55,y+20);
			doc.font('Helvetica',6);
			doc.text(movimientos_inventario[i].tipo.nombre,70,y+15,{width:50});
			doc.text(movimientos_inventario[i].clase.nombre,120,y+15,{width:50});
			doc.text(movimientos_inventario[i].almacen.sucursal.nombre,170,y+15,{width:75});	
			doc.text(movimientos_inventario[i].almacenTraspaso?movimientos_inventario[i].almacenTraspaso.sucursal.nombre:"",250,y+20,{width:75});
			movimientos_inventario[i].fecha=new Date(movimientos_inventario[i].fecha);
			doc.text(movimientos_inventario[i].fecha.getDate()+"/"+(movimientos_inventario[i].fecha.getMonth()+1)+"/"+movimientos_inventario[i].fecha.getFullYear(),345,y+20);
			doc.text(movimientos_inventario[i].fecha.getHours()+":"+movimientos_inventario[i].fecha.getMinutes()+" - ",385,y+13,{width:28});					
			doc.text(movimientos_inventario[i].fecha.getDate()+"/"+(movimientos_inventario[i].fecha.getMonth()+1)+"/"+movimientos_inventario[i].fecha.getFullYear(),385,y+21,{width:32});					
			doc.text(movimientos_inventario[i].total,425,y+20);
			doc.text(movimientos_inventario[i].observacion?movimientos_inventario[i].observacion:"",455,y+15,{width:100});
			
			doc.text(movimientos_inventario[i].activa?"V":"A",560,y+20);
			
			if(this.con_detalle){
				doc.rect(50,y+34,520,0).stroke();
				doc.font('Helvetica',7);
				y=y+50;
				
				doc.text("N°",105,y);
				doc.text("Nombre",115,y);
				doc.text("Codigo Item",270,y);
				doc.text("Unidad de Med",330,y);
				doc.text("Cantidad", 395,y);
				doc.text("Importe", 455,y);
				items++;
				for(var j=0; j<movimientos_inventario[i].detallesMovimientoInventario.length;j++){
					doc.font('Helvetica',7);
					
					doc.text(j+1,105,y+20);
					doc.text(movimientos_inventario[i].detallesMovimientoInventario[j].producto.nombre,115,y+20,{width:140});
					doc.text(movimientos_inventario[i].detallesMovimientoInventario[j].producto.codigo,270,y+20);
					doc.text(movimientos_inventario[i].detallesMovimientoInventario[j].producto.unidad_medida,330,y+20);
					doc.text(movimientos_inventario[i].detallesMovimientoInventario[j].cantidad,395,y+20);
					doc.text(movimientos_inventario[i].detallesMovimientoInventario[j].total,455,y+20);
					
					y=y+24
					items++;
					if(items+1>itemsPorPagina-1){
						y=y+10;
						
						doc.text(pagina+" de "+totalPaginas,520,715);
						var currentDate=new Date();
						doc.text("FECHA : "+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+"   "+"Hr:"+currentDate.getHours()+":"+currentDate.getMinutes(),55,715);
						doc.text("USUARIO: "+this.usuario.nombre_usuario,150,715);
						
						doc.addPage({size:[612,792],margin:10});
						y=100;
						items=0;
						pagina=pagina+1;
						this.imprimirCabeceraFiltroMovimientos(doc,1,totalPaginas,movimientos_inventario,fechaInicio,fechaFin);
					}
				}
				
				doc.font('Helvetica',7);
				y=y+4;
				items++;
			
				if(items>itemsPorPagina){
					y=y+10;
					doc.text(pagina+" de "+totalPaginas,520,705);
					var currentDate=new Date();
					//doc.rect(50,y+6,520,0).stroke();
					doc.text("FECHA : "+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+"   "+"Hr:"+currentDate.getHours()+":"+currentDate.getMinutes(),55,705);
					doc.text("USUARIO: "+this.usuario.nombre_usuario,150,705);
					
					doc.addPage({size:[612,792],margin:10});
					y=100;
					items=0;
					pagina=pagina+1;
					this.imprimirCabeceraFiltroMovimientos(doc,1,totalPaginas,movimientos_inventario,fechaInicio,fechaFin);
				}
			}else{
			
				doc.font('Helvetica',7);
				y=y+30;
				items++;
			
				if(items==itemsPorPagina){
					doc.text(pagina+" de "+totalPaginas,520,715);
					var currentDate=new Date();
					doc.text("FECHA : "+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+"   "+"Hr:"+currentDate.getHours()+":"+currentDate.getMinutes()+" ",55,715);
					doc.text("USUARIO: "+this.usuario.nombre_usuario,155,715);
					
					doc.addPage({size:[612,792],margin:10});
					y=100;
					items=0;
					pagina=pagina+1;
					this.imprimirCabeceraFiltroMovimientos(doc,1,totalPaginas,movimientos_inventario,fechaInicio,fechaFin);
				}

			}
		}
		doc.font('Helvetica',7);
		doc.text(pagina+" de "+totalPaginas,520,715);
		var currentDate=new Date();
		doc.text("FECHA : "+currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+"   "+"Hr:"+currentDate.getHours()+":"+currentDate.getMinutes()+" ",55,715);
		doc.text("USUARIO: "+this.usuario.nombre_usuario,150,715);
    doc.end();
    let me=this;
		stream.on('finish', function() {
      me.blockUI.stop();
			var fileURL = stream.toBlobURL('application/pdf');
			window.open(fileURL,'_blank','location=no');
		});
  }
  
  imprimirCabeceraFiltroMovimientos=function(doc,pagina,totalPaginas,ventas,fechaInicio,fechaFin){
		
		doc.font('Helvetica-Bold',16);
		doc.text('REPORTE MOVIMIENTO DE INVENTARIO',0,40,{align:'center'});
		doc.font('Helvetica-Bold',8);
		doc.rect(50,80,520,630).stroke();
		
		doc.text("Desde: "+fechaInicio+"   Hasta: "+fechaFin,0,55,{align:'center'});
		
		var filtrod =[];// [this.razon_sociald,this.nitd,this.montod,this.usuariod,this.transacciond,this.sucursald,this.tipoPagod];
		var x=85,c=0,cadena="";
		doc.text("Filtro: ",55,65);
		for(var i=0;i<filtrod.length;i++){
			if (filtrod[i]!=0&&filtrod[i]!=undefined){
				//doc.text(filtrod[i]+", ",x,65);
				cadena=cadena+filtrod[i]+", ";
				x=x+65;
				
			}else{
				c=c+1;
				if(c==7)doc.text("GENERAL",85,65);
			}			
		}
		
		doc.text(cadena,85,65);
		doc.font('Helvetica-Bold',7);
		//doc.rect(50,80,520,25).stroke();
				

		doc.font('Helvetica-Bold',8);
		
		doc.text("N°",55,90);
		doc.text("Movimiento",65,90);
		doc.text("Transaccion",118,90);
		doc.text("Sucursal Origen",170,90);
		doc.text("Sucursal Destino", 250,90);
		doc.text("Fecha-Mov.", 345,90-4,{width:43});
		doc.text("Hora-Fecha", 385,90-4,{width:43});
		doc.text("Total", 420,90);
		doc.text("Observación",455,90);
		doc.text("Est.",550,90);
  }
  
  imprimirFiltroExcelMovimientos(){
    let movimientos_inventario=this.movimientos_inventario;
		this.blockUI.start();
      var suma_importe=0;
      let cabecera=["N°","Movimiento","Sucursal Origen","Sucursal Destino","Sucursal Destino","Fecha Mov.","Hora-Fecha","Total","Onservación","Estado"];
			var data = [];
			/*var sumaImporte=0,sumaImporteNo=0,sumaTotal=0,sumaDescuentos=0,sumaImporteBase=0,sumaCredito=0;*/
			for(var i=0;i<movimientos_inventario.length;i++){
				var columns=[];
				movimientos_inventario[i].fecha=new Date(movimientos_inventario[i].fecha);
				columns.push(i+1);
				columns.push(movimientos_inventario[i].tipo.nombre);
				columns.push(movimientos_inventario[i].clase.nombre);
				columns.push(movimientos_inventario[i].almacen.sucursal.nombre);
				columns.push(movimientos_inventario[i].almacenTraspaso?movimientos_inventario[i].almacenTraspaso.sucursal.nombre:"");
				columns.push(movimientos_inventario[i].fecha.getDate()+"/"+(movimientos_inventario[i].fecha.getMonth()+1)+"/"+movimientos_inventario[i].fecha.getFullYear());
				columns.push(movimientos_inventario[i].fecha.getDate()+"/"+(movimientos_inventario[i].fecha.getMonth()+1)+"/"+movimientos_inventario[i].fecha.getFullYear());
				columns.push(movimientos_inventario[i].total);
				columns.push(movimientos_inventario[i].observacion);
				columns.push(movimientos_inventario[i].activa?"V":"A");	

				data.push(columns);
				if(this.con_detalle){
					data.push(["","","","","N°","Nombre","Codigo Item","Unidad de Med","Cantidad","Importe"]);
					for(var j=0; j<movimientos_inventario[i].detallesMovimientoInventario.length;j++){
						columns=[];
						columns.push("");
						columns.push("");
						columns.push("");
						columns.push("");
						columns.push(j+1);
						columns.push(movimientos_inventario[i].detallesMovimientoInventario[j].producto?movimientos_inventario[i].detallesMovimientoInventario[j].producto.nombre:movimientos_inventario[i].detallesMovimientoInventario[j].observacion);
						columns.push(movimientos_inventario[i].detallesMovimientoInventario[j].producto?movimientos_inventario[i].detallesMovimientoInventario[j].producto.codigo:"");
						columns.push(movimientos_inventario[i].detallesMovimientoInventario[j].producto?movimientos_inventario[i].detallesMovimientoInventario[j].producto.unidad_medida:"UND");
						columns.push(movimientos_inventario[i].detallesMovimientoInventario[j].cantidad);
						columns.push(movimientos_inventario[i].detallesMovimientoInventario[j].total);
						
						data.push(columns);
						}
				}
      }
      
      let workbook = new Workbook();
          let worksheet = workbook.addWorksheet("Reporte Movimiento Inventario");

          let headerRow = worksheet.addRow(cabecera);
          headerRow.eachCell((cell, number) => {
            cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '4167B8' },
              bgColor: { argb: '' }
            }
            cell.font = {
              bold: true,
              color: { argb: 'FFFFFF' },
              size: 12
            }
          })

          data.forEach(d => {
            let row = worksheet.addRow(d);
          });

          const dobCol3 = worksheet.getColumn(3);
          dobCol3.width = 40;

          workbook.xlsx.writeBuffer().then((data) => {
            let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			fs.saveAs(blob, "Reporte Movimiento Inventario.xlsx");
			  this.blockUI.stop();
		  });
  }

  abrirConfiguracionAplicacion(){
	this.configuracion_aplicacion_modal=this.modalService.open(ConfiguracionAplicacionMovimientoInventarioComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',scrollable:true});
	this.configuracion_aplicacion_modal.componentInstance.configuracion_pagina=this.configuracion_pagina;
	this.configuracion_aplicacion_modal.componentInstance.usuario=this.usuario;
	this.configuracion_aplicacion_modal.componentInstance.alTerminar.subscribe((res) => {
	  this.configuracion_aplicacion_modal.close();
	});
  }
  
  verMovimientoInventario(movimiento_inventario){
    this.movimiento_inventario=movimiento_inventario;
    this.vista_detalle_modal=this.modalService.open(this.vista_detalle_ref, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',windowClass:'vista-movimiento'});
		this.vista_detalle_modal.result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});    
  }

  crearNuevoMovimiento(){
	this.popup_registro_movimiento=this.modalService.open(RegistroMovimientoInventarioComponent, {ariaLabelledBy: 'modal-basic-title', backdrop: 'static',size:'lg'});
	this.popup_registro_movimiento.componentInstance.usuario = this.usuario;
	this.popup_registro_movimiento.componentInstance.configuracion_pagina = this.configuracion_pagina.object;
	this.popup_registro_movimiento.componentInstance.movimiento_inventario.sucursal = this.configuracion_pagina.getOrden().sucursal_defecto?this.configuracion_pagina.getOrden().sucursal_defecto:null;
	this.popup_registro_movimiento.componentInstance.busqueda_codigo_barra = this.configuracion_pagina.getOrden().busqueda_codigo_barra;
	this.popup_registro_movimiento.componentInstance.alTerminar.subscribe((res) => {
		if(res){
			this.imprimirMovimientoInventario(res.movimiento);
		}
		this.popup_registro_movimiento.close();
		
	});
	this.popup_registro_movimiento.result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
	}, (reason) => {
		this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	});    
  }

  abrirPopupConfirmacionEliminacion(movimiento_inventario){
	this.popupConfirmacion = this.modalService.open(PopupConfirmacionComponent);
	this.popupConfirmacion.componentInstance.message = "¿Esta seguro(a) de anular el movimiento?";
	this.popupConfirmacion.componentInstance.data = movimiento_inventario;		
	
	this.popupConfirmacion.componentInstance.onConfirm.subscribe(($e) => {
		if($e.confirm){
			this.eliminarMovimiento($e.data);
		}
		this.popupConfirmacion.close();
	});
  }
  
	eliminarMovimiento(movimiento_inventario){
		this.blockUI.start();
		this.movimientoInventarioService.anularMovimiento(movimiento_inventario).subscribe((res:any) => {
			if(res.tiene_error){
				this.toastr.error(res.mensaje);
			}else{
				this.toastr.success(res.mensaje);
				this.getItems();
			}
			this.blockUI.stop();
		});
	}

	crearNuevaConversionMovimiento(){
		this.blockUI.start();
		this.conversion_inventario=this.modalService.open(InventarioConversionComponent, {windowClass:'conversion-inventario',ariaLabelledBy: 'modal-basic-title',size:'md', backdrop: 'static',scrollable:true});
		this.conversion_inventario.componentInstance.sucursal_fuente=this.filter.sucursal;
		this.conversion_inventario.componentInstance.almacen_fuente=this.filter.almacen;
		this.conversion_inventario.componentInstance.usuario=this.usuario;
		this.conversion_inventario.componentInstance.alTerminar.subscribe((res) => {
			if(res){
				if(res.tiene_error){
					this.toastr.error(res.mensaje);
					this.toastr.error(res.stack.substring(0,500));
				}else{
					this.imprimirMovimientoInventario(res.movimiento);
					if(res.movimiento_destino){
						this.imprimirMovimientoInventario(res.movimiento_destino);
					}
					this.toastr.success('Conversion de inventario registrado exitosamente!');
					this.getItems();
					this.conversion_inventario.close();
				}
			}else{
				this.conversion_inventario.close();
			}
		});
		this.blockUI.stop();
	}

}
